import React from 'react';
import LinkedinLogo from "../Assets/img/aboutUs/linkedin-logo.png"
const OurTeam = ({ teamMembers }) => {
    return (
        <section className="text-gray-600 body-font">
            <div className="container px-5 py-24 mx-auto">
                <div className="flex flex-col text-center w-full mb-20">
                    <h1 className="text-2xl font-medium title-font mb-4 text-gray-900 tracking-widest">Governing Council</h1>
                </div>
                <div className="flex flex-nowrap flex-col md:flex-row md:flex-wrap md:justify-center -m-4 ">
                    {teamMembers?.map(member => (
                        <div key={member.name} className="p-4 lg:w-1/2">
                            <div className="h-full flex text-left flex-col items-center justify-start md:justify-center md:text-center">
                                <img alt="team" className="flex-shrink-0 rounded-lg w-48 h-48 object-cover object-top sm:mb-0 mb-4" src={member.image}/>
                                <div className="flex-grow pl-8 md:pl-0">
                                    <h2 className="title-font font-medium text-lg text-gray-900">{member.name}</h2>
                                    <h3 className="text-gray-500 mb-3">{member.role}</h3>
                                    <p className="mb-4">{member.description}</p>
                                    {member.linkedIn && <span className="inline-flex">
                                                  <span className="inline-flex">
                                                    <a href={member.linkedIn} className="text-gray-500">
                                                        <img src={LinkedinLogo} alt="linkedin" className="w-8 h-8"/>
                                                    </a>
                                                </span>
                                    </span>}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default OurTeam;