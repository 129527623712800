export const ComingSoon = () => {
  return (
    <div className="flex flex-col justify-center items-center">
      <img
        src="https://www.svgrepo.com/show/426192/cogs-settings.svg"
        alt="Logo"
        className="mb-8 h-40"
      />
      <h1 className="text-2xl md:text-3xl lg:text-3xl font-bold text-center text-gray-700 mb-4">
        Member features will be accessible commencing January 31, 2024.
      </h1>
      <p className="text-center text-gray-500 text-lg md:text-xl lg:text-2xl mb-8">
        We're working hard to improve out platform. Stay tuned!
      </p>
    </div>
  );
};
