import GroupImg from '../Assets/img/group.webp'
import ProfessionalImg from '../Assets/img/professional.jpg'
import AcademyImg from '../Assets/img/academy.webp'
export const ShowCase = () => {
  return (
    <div className="container my-8 px-8 mx-auto md:px-6 lg:px-0">
      <section className=" text-center lg:text-left">
        <h2 className="mb-12 text-center text-3xl font-bold">
          Explore Our Offer
        </h2>

        <div className="grid gap-x-6 lg:grid-cols-3">
          <div className="mb-12 lg:mb-0 flex flex-col">
            <div
              className="relative mb-6 overflow-hidden rounded-lg bg-cover bg-no-repeat shadow-lg dark:shadow-black/20 bg-[50%]"
              data-te-ripple-init=""
              data-te-ripple-color="light"
            >
              <img
                src={GroupImg}
                alt="group-image"
                className="w-full"
              />
              <a href="/become-a-member">
                <div className="mask absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100 bg-[hsla(0,0%,98.4%,0.2)]" />
              </a>
            </div>
            <h5 className="mb-4 text-lg font-bold">Become a member</h5>

            <p className="text-neutral-500 dark:text-neutral-300 mb-4">
              Unlock a world of exclusive benefits and networking opportunities.
              Join our elite community to connect with like-minded
              professionals, access premium resources, and stay at the forefront
              of the surveillance and close protection industry.
            </p>
            <div className="flex flex-col items-start space-y-3 sm:space-x-4 sm:space-y-0 sm:items-center sm:flex-row mt-auto">
              <a
                href="/become-a-member"
                rel="noopener"
                className="p-4 text-lg font-medium text-center text-white bg-gray-800 hover:bg-gray-500 rounded-md flex flex-row gap-5 justify-center items-center mx-auto w-full "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 122.88 117.9"
                  className="h-6 sm:h-8"
                >
                  <path
                    fill="white"
                    fillRule="evenodd"
                    d="M43.05,76.84c6.53,13.62,26.34,14.1,32.59.08a36.17,36.17,0,0,1-4.23-5.42l-.62-.9a17.71,17.71,0,0,1-11.46,3.91,17.45,17.45,0,0,1-12.2-4.89l-.56-.53c-.46,1.26-1,2.78-1.65,4.19a23.67,23.67,0,0,1-1.87,3.56Zm58.67-1.26A21.16,21.16,0,1,1,80.56,96.74a21.16,21.16,0,0,1,21.16-21.16Zm-3.12,9h6.25a.74.74,0,0,1,.74.74v7.57h7.58a.74.74,0,0,1,.74.74v6.26a.74.74,0,0,1-.74.74h-7.58v7.57a.75.75,0,0,1-.74.75H98.6a.76.76,0,0,1-.75-.75v-7.57H90.28a.74.74,0,0,1-.74-.74V93.61a.74.74,0,0,1,.74-.74h7.57V85.3a.75.75,0,0,1,.75-.74ZM77.48,75.41a20.19,20.19,0,0,0,2.15,1.31,29.8,29.8,0,0,0-4.19,34.11H6C-4.91,110,1.79,94.29,5.06,90c7.81-10.3,26-7.46,36.07-14.53a21.72,21.72,0,0,0,1.6-3.12c.81-1.83,1.54-3.84,2-5.21a65.56,65.56,0,0,1-5.25-7.47L34.18,51.2c-1.94-2.9-3-5.55-3-7.72a6,6,0,0,1,.52-2.76,5.17,5.17,0,0,1,1.82-2.13,6.23,6.23,0,0,1,1.29-.65,131.68,131.68,0,0,1-.25-15.18,19.84,19.84,0,0,1,.65-3.44,20.37,20.37,0,0,1,9-11.44A28.92,28.92,0,0,1,51.7,4.53c1.68-.47-.25-4.27,1.49-4.45C61.61-.78,74.06,5.32,80,11.69c3,3.19,4.8,7.42,5.2,13l-.33,13.78h0a3.82,3.82,0,0,1,2.8,2.89c.42,1.67,0,4-1.46,7.26h0l-.09.17-6,10C77.8,62.44,75.54,66.11,72.59,69l.8,1.16a34.59,34.59,0,0,0,4,5.09.67.67,0,0,1,.14.15Zm-39.63-35a5.32,5.32,0,0,0-2.68.69,2.15,2.15,0,0,0-.76.89,3.14,3.14,0,0,0-.25,1.42c.05,1.61.89,3.73,2.53,6.16l0,0h0L42,58c2.12,3.39,4.36,6.84,7.14,9.38a14.59,14.59,0,0,0,10.17,4.1,15,15,0,0,0,10.78-4.27c2.86-2.68,5.12-6.36,7.34-10l6-9.85c1.11-2.54,1.52-4.25,1.26-5.24-.15-.6-.81-.89-1.92-1h-.72l-.84,0a1.83,1.83,0,0,1-.47,0,8.33,8.33,0,0,1-1.64-.09L81.15,32c-15.2,2.39-26.57-8.9-42.64-2.26l1.16,10.69a9,9,0,0,1-1.82-.08Z"
                  />
                </svg>
                Become a member
              </a>
            </div>
          </div>

          <div className="mb-12 lg:mb-0 flex flex-col">
            <div
              className="relative mb-6 overflow-hidden rounded-lg bg-cover bg-no-repeat shadow-lg dark:shadow-black/20 bg-[50%]"
              data-te-ripple-init=""
              data-te-ripple-color="light"
            >
              <img
                src={ProfessionalImg}
                alt="professional-image"
                className="w-full"
              />
              <a href="/referral">
                <div className="mask absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100 bg-[hsla(0,0%,98.4%,0.2)]" />
              </a>
            </div>

            <h5 className="mb-4 text-lg font-bold">Find a professional</h5>

            <p className="text-neutral-500 dark:text-neutral-300 mb-4">
              Find the right expertise for your needs. Our comprehensive
              directory connects you with vetted and trained professionals in
              surveillance and close protection. Whether you're seeking
              consultation or collaboration, discover specialists who match your
              requirements.
            </p>
            <div className="flex flex-col items-start space-y-3 sm:space-x-4 sm:space-y-0 sm:items-center sm:flex-row mt-auto">
              <a
                href="/referral"
                rel="noopener"
                className="p-4 text-lg font-medium text-center text-white bg-gray-800 hover:bg-gray-500 rounded-md flex flex-row gap-5 justify-center items-center mx-auto w-full"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 50 50 "
                  className="h-6 sm:h-8"
                >
                  <path
                    fill="white"
                    d="M 21 3 C 11.601563 3 4 10.601563 4 20 C 4 29.398438 11.601563 37 21 37 C 24.355469 37 27.460938 36.015625 30.09375 34.34375 L 42.375 46.625 L 46.625 42.375 L 34.5 30.28125 C 36.679688 27.421875 38 23.878906 38 20 C 38 10.601563 30.398438 3 21 3 Z M 21 7 C 28.199219 7 34 12.800781 34 20 C 34 27.199219 28.199219 33 21 33 C 13.800781 33 8 27.199219 8 20 C 8 12.800781 13.800781 7 21 7 Z"
                  />
                </svg>
                Find a professional
              </a>
            </div>
          </div>

          <div className="mb-0 flex flex-col">
            <div
              className="relative mb-6 overflow-hidden rounded-lg bg-cover bg-no-repeat shadow-lg dark:shadow-black/20 bg-[50%]"
              data-te-ripple-init=""
              data-te-ripple-color="light"
            >
              <img
                src={AcademyImg}
                alt="academy-image"
                className="w-full"
              />
              <a href="/academy">
                <div className="mask absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100 bg-[hsla(0,0%,98.4%,0.2)]" />
              </a>
            </div>

            <h5 className="mb-4 text-lg font-bold">Join our academy</h5>

            <p className="text-neutral-500 dark:text-neutral-300 mb-4">
              Elevate your skills and knowledge with our specialized academy.
              From advanced training programs to expert-led courses, empower
              yourself with the latest insights and techniques in surveillance
              and close protection. Join the academy and take your professional
              development to new heights.
            </p>
            <div className="flex flex-col items-start space-y-3 sm:space-x-4 sm:space-y-0 sm:items-center sm:flex-row mt-auto">
              <a
                href="/academy"
                rel="noopener"
                className="p-4 text-lg font-medium text-center text-white bg-gray-800 hover:bg-gray-500 rounded-md flex flex-row gap-5 justify-center items-center mx-auto w-full"
              >
                <svg
                  className="h-6 sm:h-8"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  version="1.1"
                >
                  <path
                    fill="white"
                    d="M15.090 12.79c0.235-0.185 0.385-0.469 0.385-0.789 0-0.358-0.188-0.672-0.471-0.849l-0.004-5.822-1 0.67v5.15c-0.283 0.18-0.468 0.492-0.468 0.847 0 0.316 0.147 0.598 0.376 0.782l-0.378 0.502c-0.323 0.41-0.521 0.931-0.53 1.498l-0 1.222h0.81c0.002 0 0.004 0 0.005 0 0.411 0 0.757-0.282 0.853-0.664l0.331-1.336v2h1v-1.21c-0.009-0.569-0.207-1.090-0.534-1.505z"
                  />
                  <path fill="white" d="M8 0l-8 4 8 5 8-5-8-4z" />
                  <path
                    fill="white"
                    d="M8 10l-5-3.33v1.71c0 0.91 2.94 3.62 5 3.62s5-2.71 5-3.62v-1.71z"
                  />
                </svg>
                Enroll now
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
