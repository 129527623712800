import React from "react";
import Image1 from "../Assets/img/members-img1.jpg";
import LogoWOSCP from "../Assets/img/woscp.webp";

const BecomeMember = () => {
  function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }
  return (
    <div>
      <div className="p-8 md:p-16 lg:p-24">
        <div className="container mx-auto">
          <div className="mx-auto bg-white rounded-lg">
            <h1 className="text-3xl lg:text-4xl font-bold leading-9 text-black pb-4">
              WOSCP MEMBERSHIP AND BENEFITS
            </h1>
            <h2 className="font-semibold text-lg mb-6">
              SELECTING PROFESSIONALS
            </h2>
            <p className="mb-2 indent-10">
              In carrying out activities in the operational areas of
              investigations, close-protection, security, covert surveillance,
              you have a very important role in society through the importance
              of the work you do, with a reward in the form of a fee or salary.
              Few people know that you are constantly exposed to danger and may
              not receive extraordinary benefits..
            </p>
            <p className="mb-2 indent-10">
              The profession of covert surveillance, covert close-protection,
              covert investigations can bring you many other benefits in your
              work: safety, precaution, anticipation of results by applying
              covert working methods.
            </p>
            <p className="mb-2 indent-10">
              As a professional worker in these fields, your work is of vital
              importance for maintaining a climate of peace, security and the
              detection of fraud and law-breaking, activities that are much
              easier to carry out through better training and cooperation
              between us.
            </p>
            <div
              className={
                "flex flex-col-reverse lg:flex-row gap-4 justify-between"
              }
            >
              <div className="space-y-6 border-l-2 border-dashed h-fit">
                <div className="relative w-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="absolute -top-0.5 z-10 -ml-3.5 h-7 w-7 rounded-full text-black"
                  >
                    <path
                      fillRule="evenodd"
                      d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <div className="ml-6">
                    <h4 className="font-bold text-gray-700">
                      WORLD ORGANIZATION OF SURVEILLANCE AND CLOSE-PROTECTION
                      PROFESSIONALS (WOSCP), the world's first trade association
                      for undercover investigators, is the most comprehensive
                      organisation to provide training and support in the areas
                      of:
                    </h4>
                    <ul className="list-disc list-inside">
                      <li>Investigations</li>
                      <li>Covert Surveillance</li>
                      <li>Close-protection</li>
                      <li>Forensics</li>
                      <li>Crime and fraud prevention</li>
                      <li>Information analysis</li>
                    </ul>
                  </div>
                </div>
              </div>
              <img
                className={"w-full lg:w-1/4 object-cover"}
                src={Image1}
                alt={"become-a-member-image-1"}
              />
            </div>
          </div>
          <div className="mx-auto bg-white rounded-lg">
            <h1 className="font-semibold text-lg my-6">MEMBERSHIP BENEFITS</h1>
            <p className="my-2">
              The internationally recognized WOSCP World Association is directly
              involved in upholding the image of the profession, assuring
              clients that they have chosen the safest option as well as
              complying with legal methods according to law, procedures and
              standards in our ongoing activities.
            </p>
            <div
              className={
                "flex flex-col-reverse lg:flex-row gap-4 justify-between"
              }
            >
              <div className="space-y-6 border-l-2 border-dashed">
                <div className="relative w-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="absolute -top-0.5 z-10 -ml-3.5 h-7 w-7 rounded-full text-black"
                  >
                    <path
                      fillRule="evenodd"
                      d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <div className="ml-6">
                    <h4 className="font-bold text-gray-700">
                      WOSCP is not just a theoretical organization that offers
                      subscriptions to companies' records, but it is a lucrative
                      organization that offers you:
                    </h4>
                    <ul className="list-disc list-inside">
                      <li>Training in all specialisations</li>
                      <li>Best books, publications in the field</li>
                      <li>
                        Support in actions through our free help in drawing up
                        the activity plan
                      </li>
                      <li>
                        Support in the field through advice/coordination at your
                        request
                      </li>
                      <li>
                        Identification of our colleagues worldwide for support
                        and cooperation
                      </li>
                      <li>Seminars to present issues specific to our work</li>
                      <li>
                        Guarantee of support in the relationship with
                        beneficiaries
                      </li>
                      <li>
                        Guarantee to clients that they are using a company that
                        belongs to a global trade organisation with professional
                        ethics
                      </li>
                      <li>Certifies competence and quality</li>
                      <li>
                        Right of members to use the logo, photographs and badges
                        granted by the organisation.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="relative w-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="absolute -top-0.5 z-10 -ml-3.5 h-7 w-7 rounded-full text-black"
                  >
                    <path
                      fillRule="evenodd"
                      d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <div className="ml-6">
                    <h4 className="font-bold text-gray-700">
                      Thus, the opportunity to be a member of WOSCP is
                      undeniable, it is the guarantee for all members and
                      companies to conduct business in the activities of:
                    </h4>
                    <ul className="list-disc list-inside">
                      <li>investigations</li>
                      <li>close protection</li>
                      <li>covert surveillance</li>
                      <li>security - transfer of securities</li>
                      <li>special operations</li>
                      <li>complex extraction operations</li>
                      <li>
                        Media promotion of our organisation and our members and
                        their results.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <img
                className={"w-1/2 mx-auto lg:w-1/3 object-contain"}
                src={LogoWOSCP}
                alt={"become-a-member-image-2"}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray-100 p-8 md:p-16 lg:p-24">
        <div className="container mx-auto">
          <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold text-black mb-4">
            Become a Member
          </h2>
          <p className="text-black mb-8">
            The main reason why WOSCP unites us is the profession of
            surveillance investigator with all the derived professions mentioned
            above. Who knows covert surveillance then will know any operational
            activity to which this specialization applies. Nowadays everything
            revolves around COVERT SURVEILLANCE, which is the basis of all
            activities. For this, please join the organisation of corporate
            covert workers.
          </p>
          <p className="text-black mb-8">
            <b>Gold member </b>
            receive a <i>15% discount</i> on training. Additionally, they enjoy
            a <i>40% discount</i> on books, publications, online video courses,
            participation in leading complex actions with association members,
            online consultations with the president for help in certain actions,
            online consultations for analysis and preparation of high-risk
            actions and other activities carried out to raise the level of
            professionalism.
          </p>
          <p className="text-black mb-8">
            <b>Silver member </b>receive a <i>10% discount</i> on training.
            Moreover, they benefit from a <i>30% discount</i> on books,
            publications, online video courses, participation in leading complex
            actions with association members, online consultations with the
            president for help in certain actions, online consultations for
            analysis and preparation of high-risk actions and other activities
            carried out to raise the level of professionalism
          </p>
          <p className="text-black mb-8">
            <b>Bronze member </b>receive a <i>5% discount</i> on training.
            Furthermore, they receive a <i>20% discount</i> on books,
            publications, online video courses, participation in leading complex
            actions with association members, online consultations with the
            president for help in certain actions, online consultations for
            analysis and preparation of high-risk actions and other activities
            carried out to raise the level of professionalism
          </p>
          <div className="flex flex-col gap-8 items-center max-w-full mx-auto">
            <h3 className="text-xl font-semibold w-full">
              One-off application fee - £50 (Non-refundable){" "}
            </h3>
            <div className="bg-white p-6 rounded shadow-md w-full">
              <h3 className="text-xl font-semibold mb-4">
                Individual membership{" "}
              </h3>
              <p className="text-black my-6">Open to those who:</p>
              <ul className="text-black list-disc list-inside">
                <li className="mb-2">
                  Hold a formal qualification in a sector or industry using
                  investigative, close-protection or security methods through
                  the application of covert surveillance
                </li>
                <li className="mb-2">
                  {" "}
                  Have at least 1 year's professional practice experience
                </li>
                <li className="mb-2">
                  Hold a covert
                  surveillance/investigator/close-protection/security licence in
                  their home country/state
                </li>
              </ul>
              <div
                className={"flex flex-row flex-wrap justify-center gap-6 pt-2"}
              >
                <div className="w-full p-4 bg-white border border-[#DAA520] rounded-lg shadow sm:p-8 max-w-fit flex flex-col">
                  <h5 className="mb-4 text-xl font-medium text-[#DAA520]">
                    GOLD plan
                  </h5>
                  <div className="flex items-baseline text-gray-900 ">
                    <span className="text-3xl font-semibold">£</span>
                    <span className="text-5xl font-extrabold tracking-tight">
                      175
                    </span>
                    <span className="ms-1 text-xl font-normal text-gray-500 ">
                      /year
                    </span>
                  </div>
                  <ul role="list" className="space-y-5 my-7">
                    <li className="flex items-center">
                      <svg
                        className="flex-shrink-0 w-4 h-4 text-[#002067]"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                      </svg>
                      <span className="text-base font-normal leading-tight text-gray-500 ms-3  w-48 ml-5">
                        40% discount on all resources mentioned above
                      </span>
                    </li>
                    <li className="flex items-center">
                      <svg
                        className="flex-shrink-0 w-4 h-4 text-[#002067]"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                      </svg>
                      <span className="text-base font-normal leading-tight text-gray-500 w-48 ml-5">
                        Participation in leading complex actions with
                        association members{" "}
                      </span>
                    </li>
                    <li className="flex items-center">
                      <svg
                        className="flex-shrink-0 w-4 h-4 text-[#002067]"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                      </svg>
                      <span className="text-base font-normal leading-tight text-gray-500 w-48 ml-5">
                        Online consultations with the president for help in
                        certain actions
                      </span>
                    </li>{" "}
                    <li className="flex items-center">
                      <svg
                        className="flex-shrink-0 w-4 h-4 text-[#002067]"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                      </svg>
                      <span className="text-base font-normal leading-tight text-gray-500 w-48 ml-5">
                        Online consultations for analysis and preparation of
                        high-risk actions and other activities carried out to
                        raise the level of professionalism{" "}
                      </span>
                    </li>
                  </ul>
                  <button
                    type="button"
                    onClick={() => {
                      setCookie(
                        "selected_membership",
                        "plan-NKCQJDNUII9ZI",
                        0.5
                      );
                      window.location.href =
                        "/become-a-member/individual-enroll";
                    }}
                    className="text-white bg-[#002067] min-w-[200px] hover:bg-[#354a85] focus:ring-4 focus:outline-none focus:ring-blue-200 font-medium rounded-lg mt-auto text-sm px-5 py-2.5 inline-flex justify-center w-full text-center"
                  >
                    Choose plan
                  </button>
                </div>

                <div className="w-full p-4 bg-white border border-gray-700 rounded-lg shadow sm:p-8 max-w-fit flex flex-col">
                  <h5 className="mb-4 text-xl font-medium text-gray-500 ">
                    SILVER plan
                  </h5>
                  <div className="flex items-baseline text-gray-900">
                    <span className="text-3xl font-semibold">£</span>
                    <span className="text-5xl font-extrabold tracking-tight">
                      150
                    </span>
                    <span className="ms-1 text-xl font-normal text-gray-500">
                      /year
                    </span>
                  </div>
                  <ul role="list" className="space-y-5 my-7">
                    <li className="flex items-center">
                      <svg
                        className="flex-shrink-0 w-4 h-4 text-[#002067]"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                      </svg>
                      <span className="text-base font-normal leading-tight text-gray-500 ms-3  w-48 ml-5">
                        30% discount on all resources mentioned above
                      </span>
                    </li>
                    <li className="flex items-center">
                      <svg
                        className="flex-shrink-0 w-4 h-4 text-[#002067]"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                      </svg>
                      <span className="text-base font-normal leading-tight text-gray-500 w-48 ml-5">
                        Participation in leading complex actions with
                        association members{" "}
                      </span>
                    </li>
                    <li className="flex items-center">
                      <svg
                        className="flex-shrink-0 w-4 h-4 text-[#002067]"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                      </svg>
                      <span className="text-base font-normal leading-tight text-gray-500 w-48 ml-5">
                        Online consultations with the president for help in
                        certain actions
                      </span>
                    </li>{" "}
                    <li className="flex items-center">
                      <svg
                        className="flex-shrink-0 w-4 h-4 text-[#002067]"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                      </svg>
                      <span className="text-base font-normal leading-tight text-gray-500 w-48 ml-5">
                        Online consultations for analysis and preparation of
                        high-risk actions and other activities carried out to
                        raise the level of professionalism{" "}
                      </span>
                    </li>
                  </ul>
                  <button
                    type="button"
                    onClick={() => {
                      setCookie(
                        "selected_membership",
                        "plan-Y6G3F68QIGCPN",
                        0.5
                      );
                      window.location.href =
                        "/become-a-member/individual-enroll";
                    }}
                    className="text-white bg-[#002067] min-w-[200px] hover:bg-[#354a85] focus:ring-4 focus:outline-none focus:ring-blue-200 font-medium rounded-lg mt-auto text-sm px-5 py-2.5 inline-flex justify-center w-full text-center"
                  >
                    Choose plan
                  </button>
                </div>

                <div className="w-full p-4 bg-white border border-[#A0522D] rounded-lg shadow sm:p-8 max-w-fit flex flex-col">
                  <h5 className="mb-4 text-xl font-medium text-[#A0522D]">
                    BRONZE plan
                  </h5>
                  <div className="flex items-baseline text-gray-900">
                    <span className="text-3xl font-semibold">£</span>
                    <span className="text-5xl font-extrabold tracking-tight">
                      125
                    </span>
                    <span className="ms-1 text-xl font-normal text-gray-500 ">
                      /year
                    </span>
                  </div>
                  <ul role="list" className="space-y-5 my-7">
                    <li className="flex items-center">
                      <svg
                        className="flex-shrink-0 w-4 h-4 text-[#002067]"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                      </svg>
                      <span className="text-base font-normal leading-tight text-gray-500 ms-3  w-48 ml-5">
                        20% discount on all resources mentioned above
                      </span>
                    </li>
                    <li className="flex items-center">
                      <svg
                        className="flex-shrink-0 w-4 h-4 text-[#002067]"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                      </svg>
                      <span className="text-base font-normal leading-tight text-gray-500 w-48 ml-5">
                        Participation in leading complex actions with
                        association members{" "}
                      </span>
                    </li>
                    <li className="flex items-center">
                      <svg
                        className="flex-shrink-0 w-4 h-4 text-[#002067]"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                      </svg>
                      <span className="text-base font-normal leading-tight text-gray-500 w-48 ml-5">
                        Online consultations with the president for help in
                        certain actions
                      </span>
                    </li>{" "}
                    <li className="flex items-center">
                      <svg
                        className="flex-shrink-0 w-4 h-4 text-[#002067]"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                      </svg>
                      <span className="text-base font-normal leading-tight text-gray-500 w-48 ml-5">
                        Online consultations for analysis and preparation of
                        high-risk actions and other activities carried out to
                        raise the level of professionalism{" "}
                      </span>
                    </li>
                  </ul>
                  <button
                    type="button"
                    onClick={() => {
                      setCookie(
                        "selected_membership",
                        "plan-3IZ308ALZ07EA",
                        0.5
                      );
                      window.location.href =
                        "/become-a-member/individual-enroll";
                    }}
                    className="text-white bg-[#002067] min-w-[200px] hover:bg-[#354a85] focus:ring-4 focus:outline-none focus:ring-blue-200 font-medium rounded-lg mt-auto text-sm px-5 py-2.5 inline-flex justify-center w-full text-center"
                  >
                    Choose plan
                  </button>
                </div>
                <div className="w-full p-4 bg-white border border-black rounded-lg shadow sm:p-8 max-w-fit flex flex-col">
                  <h5 className="mb-4 text-xl font-medium text-black">
                    BASE plan
                  </h5>
                  <div className="flex items-baseline text-gray-900">
                    <span className="text-3xl font-semibold">£</span>
                    <span className="text-5xl font-extrabold tracking-tight">
                      100
                    </span>
                    <span className="ms-1 text-xl font-normal text-gray-500 ">
                      /year
                    </span>
                  </div>
                  <ul role="list" className="space-y-5 my-7">
                    <li className="flex items-center">
                      <svg
                        className="flex-shrink-0 w-4 h-4 text-[#002067]"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                      </svg>
                      <span className="text-base font-normal leading-tight text-gray-500 w-48 ml-5">
                        Participation in leading complex actions with
                        association members{" "}
                      </span>
                    </li>
                    <li className="flex items-center">
                      <svg
                        className="flex-shrink-0 w-4 h-4 text-[#002067]"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                      </svg>
                      <span className="text-base font-normal leading-tight text-gray-500 w-48 ml-5">
                        Online consultations with the president for help in
                        certain actions
                      </span>
                    </li>{" "}
                    <li className="flex items-center">
                      <svg
                        className="flex-shrink-0 w-4 h-4 text-[#002067]"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                      </svg>
                      <span className="text-base font-normal leading-tight text-gray-500 w-48 ml-5">
                        Online consultations for analysis and preparation of
                        high-risk actions and other activities carried out to
                        raise the level of professionalism{" "}
                      </span>
                    </li>
                  </ul>
                  <button
                    type="button"
                    onClick={() => {
                      setCookie(
                        "selected_membership",
                        "plan-V5IWA6YKRRQTJ",
                        0.5
                      );
                      window.location.href =
                        "/become-a-member/individual-enroll";
                    }}
                    className="text-white bg-[#002067] min-w-[200px] hover:bg-[#354a85] focus:ring-4 focus:outline-none focus:ring-blue-200 font-medium rounded-lg mt-auto text-sm px-5 py-2.5 inline-flex justify-center w-full text-center"
                  >
                    Choose plan
                  </button>
                </div>
              </div>
            </div>

            <div className="bg-white p-6 rounded shadow-md w-full">
              <h3 className="text-xl font-semibold mb-4">
                Corporate Subscription
              </h3>
              <ul className="text-black list-disc list-inside">
                <li className="mb-2">
                  For limited liability companies who provide evidence that a
                  director has completed and acquired an officially recognised
                  qualification in the fields of covert
                  surveillance/investigation/close-protection or security.
                </li>
                <li className="mb-2">
                  Has at least 2 years' experience in professional practice, or
                </li>
                <li className="mb-2">
                  Holds an investigator's licence in their home State
                </li>
                <li className="mb-2">
                  Has been nominated and elected by the WOSCP Association’s
                  Board of Directors or has been recommended by a director who
                  is a full member of the WOSCP.
                </li>
              </ul>
              <div
                className={"flex flex-row flex-wrap justify-center gap-6 pt-2"}
              >
                <div className="w-full p-4 bg-white border border-[#DAA520] rounded-lg shadow sm:p-8 max-w-fit flex flex-col">
                  <h5 className="mb-4 text-xl font-medium text-[#DAA520]">
                    GOLD plan
                  </h5>
                  <div className="flex items-baseline text-gray-900 ">
                    <span className="text-3xl font-semibold">£</span>
                    <span className="text-5xl font-extrabold tracking-tight">
                      250
                    </span>
                    <span className="ms-1 text-xl font-normal text-gray-500 ">
                      /year
                    </span>
                  </div>
                  <ul role="list" className="space-y-5 my-7">
                    <li className="flex items-center">
                      <svg
                        className="flex-shrink-0 w-4 h-4 text-[#002067]"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                      </svg>
                      <span className="text-base font-normal leading-tight text-gray-500 ms-3  w-48 ml-5">
                        40% discount on all resources mentioned above
                      </span>
                    </li>
                    <li className="flex items-center">
                      <svg
                        className="flex-shrink-0 w-4 h-4 text-[#002067]"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                      </svg>
                      <span className="text-base font-normal leading-tight text-gray-500 w-48 ml-5">
                        Participation in leading complex actions with
                        association members{" "}
                      </span>
                    </li>
                    <li className="flex items-center">
                      <svg
                        className="flex-shrink-0 w-4 h-4 text-[#002067]"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                      </svg>
                      <span className="text-base font-normal leading-tight text-gray-500 w-48 ml-5">
                        Online consultations with the president for help in
                        certain actions
                      </span>
                    </li>{" "}
                    <li className="flex items-center">
                      <svg
                        className="flex-shrink-0 w-4 h-4 text-[#002067]"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                      </svg>
                      <span className="text-base font-normal leading-tight text-gray-500 w-48 ml-5">
                        Online consultations for analysis and preparation of
                        high-risk actions and other activities carried out to
                        raise the level of professionalism{" "}
                      </span>
                    </li>
                  </ul>
                  <button
                    type="button"
                    onClick={() => {
                      setCookie(
                        "selected_membership",
                        "plan-EJ4DDOQSM4E9Y",
                        0.5
                      );
                      window.location.href = "/become-a-member/firm-enroll";
                    }}
                    className="text-white bg-[#002067] min-w-[200px] hover:bg-[#354a85] focus:ring-4 focus:outline-none focus:ring-blue-200  font-medium rounded-lg mt-auto text-sm px-5 py-2.5 inline-flex justify-center w-full text-center"
                  >
                    Choose plan
                  </button>
                </div>

                <div className="w-full p-4 bg-white border border-gray-700 rounded-lg shadow sm:p-8 max-w-fit flex flex-col">
                  <h5 className="mb-4 text-xl font-medium text-gray-500 ">
                    SILVER plan
                  </h5>
                  <div className="flex items-baseline text-gray-900">
                    <span className="text-3xl font-semibold">£</span>
                    <span className="text-5xl font-extrabold tracking-tight">
                      225
                    </span>
                    <span className="ms-1 text-xl font-normal text-gray-500">
                      /year
                    </span>
                  </div>{" "}
                  <ul role="list" className="space-y-5 my-7">
                    <li className="flex items-center">
                      <svg
                        className="flex-shrink-0 w-4 h-4 text-[#002067]"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                      </svg>
                      <span className="text-base font-normal leading-tight text-gray-500 ms-3  w-48 ml-5">
                        30% discount on all resources mentioned above
                      </span>
                    </li>
                    <li className="flex items-center">
                      <svg
                        className="flex-shrink-0 w-4 h-4 text-[#002067]"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                      </svg>
                      <span className="text-base font-normal leading-tight text-gray-500 w-48 ml-5">
                        Participation in leading complex actions with
                        association members{" "}
                      </span>
                    </li>
                    <li className="flex items-center">
                      <svg
                        className="flex-shrink-0 w-4 h-4 text-[#002067]"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                      </svg>
                      <span className="text-base font-normal leading-tight text-gray-500 w-48 ml-5">
                        Online consultations with the president for help in
                        certain actions
                      </span>
                    </li>{" "}
                    <li className="flex items-center">
                      <svg
                        className="flex-shrink-0 w-4 h-4 text-[#002067]"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                      </svg>
                      <span className="text-base font-normal leading-tight text-gray-500 w-48 ml-5">
                        Online consultations for analysis and preparation of
                        high-risk actions and other activities carried out to
                        raise the level of professionalism{" "}
                      </span>
                    </li>
                  </ul>
                  <button
                    type="button"
                    onClick={() => {
                      setCookie(
                        "selected_membership",
                        "plan-MN1LOY5KTKBT9",
                        0.5
                      );
                      window.location.href = "/become-a-member/firm-enroll";
                    }}
                    className="text-white bg-[#002067] min-w-[200px] hover:bg-[#354a85] focus:ring-4 focus:outline-none focus:ring-blue-200  font-medium rounded-lg mt-auto text-sm px-5 py-2.5 inline-flex justify-center w-full text-center"
                  >
                    Choose plan
                  </button>
                </div>

                <div className="w-full p-4 bg-white border border-[#A0522D] rounded-lg shadow sm:p-8 max-w-fit flex flex-col">
                  <h5 className="mb-4 text-xl font-medium text-[#A0522D]">
                    BRONZE plan
                  </h5>
                  <div className="flex items-baseline text-gray-900">
                    <span className="text-3xl font-semibold">£</span>
                    <span className="text-5xl font-extrabold tracking-tight">
                      200
                    </span>
                    <span className="ms-1 text-xl font-normal text-gray-500 ">
                      /year
                    </span>
                  </div>{" "}
                  <ul role="list" className="space-y-5 my-7">
                    <li className="flex items-center">
                      <svg
                        className="flex-shrink-0 w-4 h-4 text-[#002067]"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                      </svg>
                      <span className="text-base font-normal leading-tight text-gray-500 ms-3  w-48 ml-5">
                        20% discount on all resources mentioned above
                      </span>
                    </li>
                    <li className="flex items-center">
                      <svg
                        className="flex-shrink-0 w-4 h-4 text-[#002067]"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                      </svg>
                      <span className="text-base font-normal leading-tight text-gray-500 w-48 ml-5">
                        Participation in leading complex actions with
                        association members{" "}
                      </span>
                    </li>
                    <li className="flex items-center">
                      <svg
                        className="flex-shrink-0 w-4 h-4 text-[#002067]"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                      </svg>
                      <span className="text-base font-normal leading-tight text-gray-500 w-48 ml-5">
                        Online consultations with the president for help in
                        certain actions
                      </span>
                    </li>{" "}
                    <li className="flex items-center">
                      <svg
                        className="flex-shrink-0 w-4 h-4 text-[#002067]"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                      </svg>
                      <span className="text-base font-normal leading-tight text-gray-500 w-48 ml-5">
                        Online consultations for analysis and preparation of
                        high-risk actions and other activities carried out to
                        raise the level of professionalism{" "}
                      </span>
                    </li>
                  </ul>
                  <button
                    type="button"
                    onClick={() => {
                      setCookie(
                        "selected_membership",
                        "plan-8SFRHK2KSO0YE",
                        0.5
                      );
                      window.location.href = "/become-a-member/firm-enroll";
                    }}
                    className="text-white bg-[#002067] min-w-[200px] hover:bg-[#354a85] focus:ring-4 focus:outline-none focus:ring-blue-200  font-medium rounded-lg mt-auto text-sm px-5 py-2.5 inline-flex justify-center w-full text-center"
                  >
                    Choose plan
                  </button>
                </div>

                <div className="w-full p-4 bg-white border border-black rounded-lg shadow sm:p-8 max-w-fit flex flex-col">
                  <h5 className="mb-4 text-xl font-medium text-black">
                    BASE plan
                  </h5>
                  <div className="flex items-baseline text-gray-900">
                    <span className="text-3xl font-semibold">£</span>
                    <span className="text-5xl font-extrabold tracking-tight">
                      175
                    </span>
                    <span className="ms-1 text-xl font-normal text-gray-500 ">
                      /year
                    </span>
                  </div>{" "}
                  <ul role="list" className="space-y-5 my-7">
                    <li className="flex items-center">
                      <svg
                        className="flex-shrink-0 w-4 h-4 text-[#002067]"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                      </svg>
                      <span className="text-base font-normal leading-tight text-gray-500 w-48 ml-5">
                        Participation in leading complex actions with
                        association members{" "}
                      </span>
                    </li>
                    <li className="flex items-center">
                      <svg
                        className="flex-shrink-0 w-4 h-4 text-[#002067]"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                      </svg>
                      <span className="text-base font-normal leading-tight text-gray-500 w-48 ml-5">
                        Online consultations with the president for help in
                        certain actions
                      </span>
                    </li>{" "}
                    <li className="flex items-center">
                      <svg
                        className="flex-shrink-0 w-4 h-4 text-[#002067]"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                      </svg>
                      <span className="text-base font-normal leading-tight text-gray-500 w-48 ml-5">
                        Online consultations for analysis and preparation of
                        high-risk actions and other activities carried out to
                        raise the level of professionalism{" "}
                      </span>
                    </li>
                  </ul>
                  <button
                    type="button"
                    onClick={() => {
                      setCookie(
                        "selected_membership",
                        "plan-TA52AMWB29PM6",
                        0.5
                      );
                      window.location.href = "/become-a-member/firm-enroll";
                    }}
                    className="text-white bg-[#002067] min-w-[200px] hover:bg-[#354a85] focus:ring-4 focus:outline-none focus:ring-blue-200 font-medium rounded-lg mt-auto text-sm px-5 py-2.5 inline-flex justify-center w-full text-center"
                  >
                    Choose plan
                  </button>
                </div>
              </div>
            </div>
            <div className="bg-white p-6 rounded shadow-md w-full">
              <h3 className="text-xl font-semibold mb-4">
                Fellow of the Association
              </h3>
              <p className="text-black my-6">Open to those who:</p>
              <ul className="text-black list-disc list-inside">
                <li className="mb-2">
                  Have gained a formally recognised qualification in the fields
                  of covert surveillance/investigation/close-protection/security
                  or have gained a relevant professional qualification from a
                  higher-level industry{" "}
                </li>
                <li className="mb-2">
                  Have at least 5 years' experience in professional practice
                </li>
                <li className="mb-2">
                  Those who have made an outstanding contribution to the
                  profession and/or to the Association as voted by the
                  Association's Board
                </li>
              </ul>

              <div
                className={"flex flex-row flex-wrap justify-center gap-6 pt-2"}
              >
                <div className="w-full p-4 bg-white border border-black rounded-lg shadow sm:p-8 max-w-fit flex flex-col">
                  <h5 className="mb-4 text-xl font-medium text-black">
                    BASE plan
                  </h5>
                  <div className="flex items-baseline text-gray-900 ">
                    <span className="text-3xl font-semibold">£</span>
                    <span className="text-5xl font-extrabold tracking-tight">
                      125
                    </span>
                    <span className="ms-1 text-xl font-normal text-gray-500 ">
                      /year
                    </span>
                  </div>
                  <ul role="list" className="space-y-5 my-7">
                    <li className="flex items-center">
                      <svg
                        className="flex-shrink-0 w-4 h-4 text-[#002067]"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                      </svg>
                      <span className="text-base font-normal leading-tight text-gray-500 w-48 ml-5">
                        Participation in leading complex actions with
                        association members{" "}
                      </span>
                    </li>
                    <li className="flex items-center">
                      <svg
                        className="flex-shrink-0 w-4 h-4 text-[#002067]"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                      </svg>
                      <span className="text-base font-normal leading-tight text-gray-500 w-48 ml-5">
                        Online consultations with the president for help in
                        certain actions
                      </span>
                    </li>{" "}
                    <li className="flex items-center">
                      <svg
                        className="flex-shrink-0 w-4 h-4 text-[#002067]"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                      </svg>
                      <span className="text-base font-normal leading-tight text-gray-500 w-48 ml-5">
                        Online consultations for analysis and preparation of
                        high-risk actions and other activities carried out to
                        raise the level of professionalism{" "}
                      </span>
                    </li>
                  </ul>
                  <button
                    type="button"
                    onClick={() => {
                      setCookie(
                        "selected_membership",
                        "plan-V5IWA6YKRRXSA",
                        0.5
                      );
                      window.location.href =
                        "/become-a-member/individual-enroll";
                    }}
                    className="text-white bg-[#002067] min-w-[200px] hover:bg-[#354a85] focus:ring-4 focus:outline-none focus:ring-blue-200  font-medium rounded-lg mt-auto text-sm px-5 py-2.5 inline-flex justify-center w-full text-center"
                  >
                    Choose plan
                  </button>
                </div>
              </div>
            </div>
            <div className="bg-white p-6 rounded shadow-md w-full">
              <h3 className="text-xl font-semibold mb-4">Students </h3>{" "}
              <p className="text-black my-6">Open to those who:</p>
              <ul className="text-black list-disc list-inside">
                <li className="mb-2">
                  Hold a formal qualification in a sector or industry using
                  investigative, close-protection or security methods through
                  the application of covert surveillance
                </li>
                <li className="mb-2">
                  {" "}
                  Have at least 1 year's professional practice experience
                </li>
                <li className="mb-2">
                  Hold a covert
                  surveillance/investigator/close-protection/security licence in
                  their home country/state
                </li>
              </ul>
              <div
                className={"flex flex-row flex-wrap justify-center gap-6 pt-2"}
              >
                <div className="w-full p-4 bg-white border border-[#DAA520] rounded-lg shadow sm:p-8 max-w-fit flex flex-col">
                  <h5 className="mb-4 text-xl font-medium text-[#DAA520]">
                    GOLD plan
                  </h5>
                  <div className="flex items-baseline text-gray-900 ">
                    <span className="text-3xl font-semibold">£</span>
                    <span className="text-5xl font-extrabold tracking-tight">
                      85
                    </span>
                    <span className="ms-1 text-xl font-normal text-gray-500 ">
                      /year
                    </span>
                  </div>
                  <ul role="list" className="space-y-5 my-7">
                    <li className="flex items-center">
                      <svg
                        className="flex-shrink-0 w-4 h-4 text-[#002067]"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                      </svg>
                      <span className="text-base font-normal leading-tight text-gray-500 w-48 ml-5">
                        40% discount on all resources mentioned above
                      </span>
                    </li>{" "}
                    <li className="flex items-center">
                      <svg
                        className="flex-shrink-0 w-4 h-4 text-[#002067]"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                      </svg>
                      <span className="text-base font-normal leading-tight text-gray-500 w-48 ml-5">
                        Participation in leading complex actions with
                        association members{" "}
                      </span>
                    </li>
                    <li className="flex items-center">
                      <svg
                        className="flex-shrink-0 w-4 h-4 text-[#002067]"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                      </svg>
                      <span className="text-base font-normal leading-tight text-gray-500 w-48 ml-5">
                        Online consultations with the president for help in
                        certain actions
                      </span>
                    </li>{" "}
                    <li className="flex items-center">
                      <svg
                        className="flex-shrink-0 w-4 h-4 text-[#002067]"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                      </svg>
                      <span className="text-base font-normal leading-tight text-gray-500 w-48 ml-5">
                        Online consultations for analysis and preparation of
                        high-risk actions and other activities carried out to
                        raise the level of professionalism{" "}
                      </span>
                    </li>
                  </ul>
                  <button
                    type="button"
                    onClick={() => {
                      setCookie(
                        "selected_membership",
                        "plan-7U8CHA34215LP",
                        0.5
                      );
                      window.location.href =
                        "/become-a-member/individual-enroll";
                    }}
                    className="text-white bg-[#002067] min-w-[200px] hover:bg-[#354a85] focus:ring-4 focus:outline-none focus:ring-blue-200  font-medium rounded-lg mt-auto text-sm px-5 py-2.5 inline-flex justify-center w-full text-center"
                  >
                    Choose plan
                  </button>
                </div>

                <div className="w-full p-4 bg-white border border-gray-700 rounded-lg shadow sm:p-8 max-w-fit flex flex-col">
                  <h5 className="mb-4 text-xl font-medium text-gray-500 ">
                    SILVER plan
                  </h5>
                  <div className="flex items-baseline text-gray-900">
                    <span className="text-3xl font-semibold">£</span>
                    <span className="text-5xl font-extrabold tracking-tight">
                      80
                    </span>
                    <span className="ms-1 text-xl font-normal text-gray-500">
                      /year
                    </span>
                  </div>
                  <ul role="list" className="space-y-5 my-7">
                    <li className="flex items-center">
                      <svg
                        className="flex-shrink-0 w-4 h-4 text-[#002067]"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                      </svg>
                      <span className="text-base font-normal leading-tight text-gray-500 w-48 ml-5">
                        30% discount on all resources mentioned above
                      </span>
                    </li>{" "}
                    <li className="flex items-center">
                      <svg
                        className="flex-shrink-0 w-4 h-4 text-[#002067]"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                      </svg>
                      <span className="text-base font-normal leading-tight text-gray-500 w-48 ml-5">
                        Participation in leading complex actions with
                        association members{" "}
                      </span>
                    </li>
                    <li className="flex items-center">
                      <svg
                        className="flex-shrink-0 w-4 h-4 text-[#002067]"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                      </svg>
                      <span className="text-base font-normal leading-tight text-gray-500 w-48 ml-5">
                        Online consultations with the president for help in
                        certain actions
                      </span>
                    </li>{" "}
                    <li className="flex items-center">
                      <svg
                        className="flex-shrink-0 w-4 h-4 text-[#002067]"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                      </svg>
                      <span className="text-base font-normal leading-tight text-gray-500 w-48 ml-5">
                        Online consultations for analysis and preparation of
                        high-risk actions and other activities carried out to
                        raise the level of professionalism{" "}
                      </span>
                    </li>
                  </ul>
                  <button
                    type="button"
                    onClick={() => {
                      setCookie(
                        "selected_membership",
                        "plan-7U8CHA44215LP",
                        0.5
                      );
                      window.location.href =
                        "/become-a-member/individual-enroll";
                    }}
                    className="text-white bg-[#002067] min-w-[200px] hover:bg-[#354a85] focus:ring-4 focus:outline-none focus:ring-blue-200  font-medium rounded-lg mt-auto text-sm px-5 py-2.5 inline-flex justify-center w-full text-center"
                  >
                    Choose plan
                  </button>
                </div>

                <div className="w-full p-4 bg-white border border-[#A0522D] rounded-lg shadow sm:p-8 max-w-fit flex flex-col">
                  <h5 className="mb-4 text-xl font-medium text-[#A0522D]">
                    BRONZE plan
                  </h5>
                  <div className="flex items-baseline text-gray-900">
                    <span className="text-3xl font-semibold">£</span>
                    <span className="text-5xl font-extrabold tracking-tight">
                      75
                    </span>
                    <span className="ms-1 text-xl font-normal text-gray-500 ">
                      /year
                    </span>
                  </div>
                  <ul role="list" className="space-y-5 my-7">
                    <li className="flex items-center">
                      <svg
                        className="flex-shrink-0 w-4 h-4 text-[#002067]"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                      </svg>
                      <span className="text-base font-normal leading-tight text-gray-500 w-48 ml-5">
                        20% discount on all resources mentioned above
                      </span>
                    </li>{" "}
                    <li className="flex items-center">
                      <svg
                        className="flex-shrink-0 w-4 h-4 text-[#002067]"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                      </svg>
                      <span className="text-base font-normal leading-tight text-gray-500 w-48 ml-5">
                        Participation in leading complex actions with
                        association members{" "}
                      </span>
                    </li>
                    <li className="flex items-center">
                      <svg
                        className="flex-shrink-0 w-4 h-4 text-[#002067]"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                      </svg>
                      <span className="text-base font-normal leading-tight text-gray-500 w-48 ml-5">
                        Online consultations with the president for help in
                        certain actions
                      </span>
                    </li>{" "}
                    <li className="flex items-center">
                      <svg
                        className="flex-shrink-0 w-4 h-4 text-[#002067]"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                      </svg>
                      <span className="text-base font-normal leading-tight text-gray-500 w-48 ml-5">
                        Online consultations for analysis and preparation of
                        high-risk actions and other activities carried out to
                        raise the level of professionalism{" "}
                      </span>
                    </li>
                  </ul>
                  <button
                    type="button"
                    onClick={() => {
                      setCookie(
                        "selected_membership",
                        "plan-7U8CHAW5515LP",
                        0.5
                      );
                      window.location.href =
                        "/become-a-member/individual-enroll";
                    }}
                    className="text-white bg-[#002067] min-w-[200px] hover:bg-[#354a85] focus:ring-4 focus:outline-none focus:ring-blue-200  font-medium rounded-lg mt-auto text-sm px-5 py-2.5 inline-flex justify-center w-full text-center"
                  >
                    Choose plan
                  </button>
                </div>

                <div className="w-full p-4 bg-white border border-black rounded-lg shadow sm:p-8 max-w-fit flex flex-col">
                  <h5 className="mb-4 text-xl font-medium text-black">
                    Base plan
                  </h5>
                  <div className="flex items-baseline text-gray-900 ">
                    <span className="text-3xl font-semibold">£</span>
                    <span className="text-5xl font-extrabold tracking-tight">
                      70
                    </span>
                    <span className="ms-1 text-xl font-normal text-gray-500 ">
                      /year
                    </span>
                  </div>
                  <ul role="list" className="space-y-5 my-7">
                    <li className="flex items-center">
                      <svg
                        className="flex-shrink-0 w-4 h-4 text-[#002067]"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                      </svg>
                      <span className="text-base font-normal leading-tight text-gray-500 w-48 ml-5">
                        Participation in leading complex actions with
                        association members{" "}
                      </span>
                    </li>
                    <li className="flex items-center">
                      <svg
                        className="flex-shrink-0 w-4 h-4 text-[#002067]"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                      </svg>
                      <span className="text-base font-normal leading-tight text-gray-500 w-48 ml-5">
                        Online consultations with the president for help in
                        certain actions
                      </span>
                    </li>{" "}
                    <li className="flex items-center">
                      <svg
                        className="flex-shrink-0 w-4 h-4 text-[#002067]"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                      </svg>
                      <span className="text-base font-normal leading-tight text-gray-500 w-48 ml-5">
                        Online consultations for analysis and preparation of
                        high-risk actions and other activities carried out to
                        raise the level of professionalism{" "}
                      </span>
                    </li>
                  </ul>
                  <button
                    type="button"
                    onClick={() => {
                      setCookie(
                        "selected_membership",
                        "plan-7U8CHAW2215LP",
                        0.5
                      );
                      window.location.href =
                        "/become-a-member/individual-enroll";
                    }}
                    className="text-white bg-[#002067] min-w-[200px] hover:bg-[#354a85] focus:ring-4 focus:outline-none focus:ring-blue-200  font-medium rounded-lg mt-auto text-sm px-5 py-2.5 inline-flex justify-center w-full text-center"
                  >
                    Choose plan
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BecomeMember;
