import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import Axios from "axios";
import { toast } from "react-toastify";

import {
  emailValidation,
  passwordValidation,
} from "../../Components/utils/inputValidations";

export const AdminLogin = () => {
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
  });

  const [errorState, setErrorState] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [invalidCredentials, setInvalidCredentials] = useState(false);

  useEffect(() => {
    if (emailError === "" && passwordError === "") {
      setErrorState(false);
    } else {
      setErrorState(true);
    }
  }, [emailError, passwordError]);

  useEffect(() => {
    if (Cookies.get("woscp_admin")) {
      window.location.href = "/admin/dashboard";
    }
  }, []);

  useEffect(() => {
    const existingMetaTag = document.querySelector('meta[name="robots"]');

    if (!existingMetaTag) {
      const metaTag = document.createElement("meta");
      metaTag.name = "robots";
      metaTag.content = "noindex,nofollow";
      document.head.appendChild(metaTag);
    } else {
      existingMetaTag.content = "noindex,nofollow";
    }
  }, []);

  const onChangeHandle = (e) => {
    const { id, value } = e.target;
    setCredentials((prevCredentials) => ({
      ...prevCredentials,
      [id]: value,
    }));

    if (id === "email") {
      setEmailError(emailValidation(value));
    }

    if (id === "password") {
      setPasswordError(passwordValidation(value));
    }

    setInvalidCredentials(false);
  };

  const handleLogin = async () => {
    try {
      const response = await Axios.post(
        "https://server.woscp.org/api/admin/login",
        {
          email: credentials.email,
          password: credentials.password,
        },
      );

      const { token } = response.data;

      Cookies.set("woscp_admin", token, { expires: 0.3 });
      window.location.href = "/admin/dashboard";
    } catch (error) {
      setInvalidCredentials(true);
      toast("We could not log you in.", { type: "error" });
    }
  };

  return (
    <section>
      <div className="w-full  fixed right-0 overflow-auto">
        <section>
          <div className="container mx-auto py-8 ">
            <div className="flex flex-col lg:flex-row justify-center gap-6 items-center h-full lg:mt-[100px]">
              <div className="w-full xl:w-2/3 px-6 lg:px-0   mx-auto">
                <h1 className="text-xl lg:text-3xl font-bold text-center mb-2">
                  Login to W.O.S.C.P. Admin Dashboard
                </h1>
                <div>
                  <label className="block text-primary" htmlFor="form3Example3">
                    Email address
                  </label>
                  <div className="mb-6 relative">
                    <input
                      type="email"
                      id="email"
                      name="email"
                      className="w-full px-4 py-2 text-lg text-primary border rounded-md focus:outline-none focus:ring focus:border-primary"
                      placeholder="Enter a valid email address"
                      onChange={onChangeHandle}
                      onBlur={onChangeHandle}
                    />
                    {emailError && (
                      <div className="absolute bg-[#bd1d1d82] pt-2 text-white px-2 bottom-[-23px] z-[-1] w-full transition-all duration-400 animate-errorAnimation rounded">
                        {emailError}
                      </div>
                    )}
                  </div>
                  <div className="mb-6 relative">
                    <label
                      className="block text-primary"
                      htmlFor="form3Example4"
                    >
                      Password
                    </label>
                    <input
                      type="password"
                      id="password"
                      name="password"
                      className="w-full px-4 py-2 text-lg text-primary border rounded-md focus:outline-none focus:ring focus:border-primary"
                      placeholder="Enter password"
                      onChange={onChangeHandle}
                      onBlur={onChangeHandle}
                    />
                    {passwordError && (
                      <div className="absolute bg-[#bd1d1d82] pt-2 text-white px-2 bottom-[-23px] z-[-1] w-full transition-all duration-400 animate-errorAnimation rounded">
                        {passwordError}
                      </div>
                    )}
                  </div>
                  <div className="text-center lg:text-lg mt-4 mt-[30px] pt-2 relative">
                    {invalidCredentials && (
                      <div className="absolute bg-[#bd1d1d82] text-white px-12 py-2 rounded-md top-[-20px] z-[-1] w-full transition-all duration-400 opacity-100 animate-errorAccountAnimation">
                        Invalid credentials !!!
                      </div>
                    )}
                    <button
                      type="button"
                      className="bg-gray-800 hover:bg-gray-500 disabled:hover:bg-gray-300 disabled:bg-gray-300 text-white px-8 py-3 text-lg rounded my-5"
                      disabled={errorState}
                      style={{ paddingLeft: "2.5rem", paddingRight: "2.5rem" }}
                      onClick={handleLogin}
                    >
                      Login
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  );
};
