import React from "react";
import { Link } from "react-router-dom";
import { UseAuth } from "../Components/utils/useAuth";

const MembershipCards = () => {
  const { loggedIn } = UseAuth();
  if (!loggedIn) {
    return <></>;
  }
  return (
    <div>
      <div className="p-8 md:p-16 lg:p-24">
        <div className="container mx-auto">
          <div className="mx-auto bg-white rounded-lg">
            <h1 className="text-3xl lg:text-4xl font-bold leading-9 text-black pb-4">
              Membership Cards
            </h1>
            <h2 className="font-semibold text-lg mb-6">
              W.O.S.C.P. membership photo cards
            </h2>
            <div
              className={
                "flex flex-col-reverse lg:flex-row gap-4 justify-between"
              }
            >
              <div className="space-y-6 border-l-2 border-dashed h-fit">
                <div className="relative w-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="absolute -top-0.5 z-10 -ml-3.5 h-7 w-7 rounded-full text-black"
                  >
                    <path
                      fillRule="evenodd"
                      d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <div className="ml-6">
                    <h4 className="font-bold text-gray-700">
                      To obtain your membership card you are required to do the
                      following.
                    </h4>
                    <ul className="list-decimal list-inside">
                      <li className="my-4">
                        Ensure that your Subscription has been paid, for the
                        current year.
                      </li>
                      <li className="my-4">
                        Obtain a recent Passport type digital photograph saved
                        in jpg format.
                      </li>
                      <li className="my-4">
                        Send your photo as an attachment to{" "}
                        <a
                          className={"text-blue-500"}
                          href="mailto:office@woscp.org"
                        >
                          office@woscp.org
                        </a>{" "}
                        please ensure that you include your full name as you
                        wish it to appear on the Membership Card and the address
                        you would like the card sent to within the email.
                      </li>
                      <li className="my-4">
                        Sit back and wait to receive your professional
                        membership Card.
                      </li>
                      <li className="my-4">Crime and fraud prevention</li>
                      <li className="my-4">
                        Please note that as a member of the W.O.S.C.P eGroup it
                        does not make you a member of W.O.S.C.P. If you want to
                        join,
                        <Link
                          to={"/become-a-member"}
                          className={"text-blue-500"}
                        >
                          click here
                        </Link>{" "}
                        and you will receive your membership card once your
                        application has been approved.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <p className="my-2">
              Please note the new membership card is not a right of entry, but
              is of such a high quality as to be perfectly adequate to be used
              as a means of confirmation of an individual’s identity, as being a
              member of a Professional Association. The membership card is to be
              used in line with the Associations{" "}
              <Link to={"/ethics"} className={"text-blue-500"}>
                Code of Ethics
              </Link>{" "}
              .
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MembershipCards;
