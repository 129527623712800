import React from "react";
import OurTeam from "../Components/OurTeam";
import { teamMembers } from "../Components/data";

const About = () => {
  return (
    <div className="2xl:container 2xl:mx-auto lg:py-16 lg:px-20 md:py-12 md:px-6 py-9 px-8">
      <div className="flex flex-col lg:flex-row justify-between gap-8">
        <div className="w-full  flex flex-col justify-center">
          <h1 className="text-3xl lg:text-4xl font-bold leading-9 text-black pb-4">
            ABOUT W.O.S.C.P. – ABOUT US
          </h1>
          <h2 className="text-2xl lg:text-2xl font-bold leading-9 text-black pb-4">
            WORLD ORGANIZATION OF SURVEILLANCE AND CLOSE-PROTECTION
            PROFESSIONALS (W.O.S.C.P.)
          </h2>
          <p className="font-normal text-base leading-6 text-black ">
            The World Organization of Surveillance and Close-protection
            Professionals (W.O.S.C.P.) is a professional organization
            established by and for companies engaged in various aspects of
            security and investigative services. W.O.S.C.P. fulfills the global
            demand for a central hub that connects security and investigation
            companies worldwide. W.O.S.C.P. actively advances and supports the
            security and investigation industry on an international scale,
            facilitating networking opportunities that enable member companies
            to share insights, expertise, methodologies, counsel, educational
            resources, and collaborative business endeavors. Our member
            companies strictly adhere to a stringent Code of Ethics, upholding
            the highest standards of integrity and professionalism in the field.
          </p>
          <p>
            <span className="text-1xl lg:text-1xl font-bold leading-9 text-black pb-4">
              W.O.S.C.P.{" "}
            </span>
            promotes the aforementioned professions that have a common
            attribute: working in silence and total conspiracy.
          </p>
          <p>
            <span className="text-1xl lg:text-1xl font-bold leading-9 text-black pb-4">
              W.O.S.C.P.{" "}
            </span>
            members' activities provide a networking system through which
            members can exchange ideas, collaborate and participate in trainings
            organized by W.O.S.C.P. based on a Code of Ethics appropriate to our
            work. Members can also ask the association's management for advice
            in case of difficult actions or even carry out actions under the
            leadership of the association that will go to the field with the
            members involved in the contract.
          </p>
          <p>
            <span className="text-1xl lg:text-1xl font-bold leading-9 text-black pb-4">
              W.O.S.C.P.{" "}
            </span>
            - Main objectives:
          </p>
          <ul className="pl-5 space-y-3 text-black list-decimal marker:text-black">
            <li>
              Promotion on a worldwide scale of operative specialisations using
              covert surveillance procedures and methods
            </li>
            <li>
              Providing legal documentation possibilities in the mixed field{" "}
            </li>
            <ul className="pl-5 space-y-3 text-black list-disc marker:text-black">
              <li>investigation-covert surveillance</li>
              <li>close-protection-covert surveillance</li>
              <li>special operations-covert surveillance</li>
              <li>
                security actions relating to the transport of valuables - covert
                surveillance
              </li>
              <li>preventive security actions - covert surveillance</li>
              <li>integrated security management - covert surveillance</li>
            </ul>
            <li>Activities unfold in accordance with </li>
            <ul className="pl-5 space-y-3 text-black list-disc marker:text-black">
              <li>The principle of legality, </li>
              <li>The principle of covertness</li>
              <li>Strict code of ethics.</li>
            </ul>
            <li>
              The existence of a single global voice of operational specialists
              specialising in resolving client requests while avoiding violence,
              being caution, covert and silent
            </li>
          </ul>
        </div>
      </div>
      <OurTeam teamMembers={teamMembers} />
    </div>
  );
};

export default About;
