import React from "react";
import { Link, useLocation } from "react-router-dom";

const LinkNavBar = ({ link, text }) => {
  const location = useLocation();

  return (
    <li
      className={`px-2  lg:border-black flex items-center ${
        text !== "Home" && "lg:border-l-[1px]"
      }`}
    >
      <Link
        to={link}
        className={`block py-2 pr-4 pl-3 w-fit text-[15px] min-w-max mx-auto text-black lg:w-100 text-nowrap lg:hover:bg-transparent lg:border-0 lg:hover:text-white-700 lg:p-0 ${location.pathname === link?"font-extrabold rounded border-selected":"border-animation"}`}
      >
        {text}
      </Link>
    </li>
  );
};

export default LinkNavBar;
