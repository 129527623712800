export const FirmInformationCard = (props) => {
  return (
    <div className="relative flex flex-col items-center w-full max-w-[900px] mx-auto bg-clip-border border-b-solid border-b-gray-300 border-b-2 shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:text-white dark:!shadow-none p-3">
      <div className="mt-2 mb-2 w-full">
        <h4 className="px-2 text-xl font-bold text-navy-700 dark:text-white">
          {props.first_name + " " + props.last_name}
        </h4>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 px-2 flex-wrap w-full">
        <div className="flex flex-col items-start justify-start rounded-2xl bg-white bg-clip-border px-1 py-1 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="text-sm text-gray-600">Contact</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            {props.email}
          </p>
        </div>
        <div className="flex flex-col justify-start rounded-2xl bg-white bg-clip-border px-1 py-1 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="text-sm text-gray-600">Address</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            {props.address}
            <br />
            {props.city + ", " + props.country}
            <br />
            Postal code: {props.area_code}
          </p>
        </div>
        <div className="flex flex-col items-start justify-start rounded-2xl bg-white bg-clip-border px-1 py-1 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
          <p className="text-sm text-gray-600">Expertise</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            {props.expertise}
          </p>
        </div>

        {props.website && (
          <div className="flex flex-col justify-start rounded-2xl bg-white bg-clip-border px-1 py-1 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
            <p className="text-sm text-gray-600">Website</p>
            <p className="text-base font-medium text-navy-700 dark:text-white">
              {props.website}
            </p>
          </div>
        )}

        {props.firm_name && (
          <div className="flex flex-col items-start justify-start rounded-2xl bg-white bg-clip-border px-1 py-1 shadow-3xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
            <p className="text-sm text-gray-600">Organization</p>
            <p className="text-base font-medium text-navy-700 dark:text-white">
              {props.firm_name}
              <br />
              Director: {props.director_name}
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
