import React from "react";
import Logo from "../Assets/img/woscp.webp";
import { useFieldArray } from "react-hook-form";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";

import { Controller } from "react-hook-form";
const CustomEditForm = ({ dataForm, form, onSubmit, defaultValues }) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { isValid },
  } = form;
  const ElementSwitch = (element) => {
    const { fields, append, remove } = useFieldArray({
      control,
      name: element.type === "dynamic" ? element.name : "",
    });
    switch (element.type) {
      case "textarea": {
        return (
          <textarea
            type="text"
            defaultValue={defaultValues.title}
            {...register(element.name)}
            className="h-28 border mt-1 rounded px-4 w-full bg-gray-50 max-h-40"
          />
        );
      }
      case "image": {
        return <input type="file" {...register(element.name)} />;
      }
      case "richtext": {
        return (
          <Controller
            name="description"
            defaultValue={defaultValues.description}
            control={control}
            render={({ field }) => (
              <ReactQuill
                {...field}
                placeholder={"Write Description"}
                onChange={(text) => {
                  field.onChange(text);
                }}
              />
            )}
          />
        );
      }
      case "dropdown": {
        return (
          <select
            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
            {...register(element.name, {
              required: {
                value: true,
                message: "required",
              },
            })}
          >
            {element.options.map((option) => (
              <option
                className="h-10 border mt-1 rounded px-4 w-full bg-gray-50 text-wrap"
                key={option}
                value={option}
              >
                {option}
              </option>
            ))}
          </select>
        );
      }
      case "checkbox": {
        return (
          <span>
            {element.options.map((option, index) => (
              <div className="flex items-center">
                <input
                  key={`option-${index}`}
                  type="checkbox"
                  defaultChecked={defaultValues.tags.includes(
                    option.toLowerCase()
                  )}
                  id={`option-${index}`}
                  name={option}
                  value={option.toLowerCase()}
                  {...register(element.name)}
                />
                <label className="px-2">{option}</label>
              </div>
            ))}
          </span>
        );
      }
      case "dynamic": {
        return (
          <div className="flex flex-col ">
            {fields.map((field, index) => (
              <div className="flex gap-5" key={index}>
                <input
                  required={true}
                  type="text"
                  {...register(`${element.name}.${index}.value`)}
                  className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
                />
                {fields.length > 1 && (
                  <button
                    className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100"
                    type="button"
                    onClick={() => remove(index)}
                  >
                    -
                  </button>
                )}
              </div>
            ))}
            <button
              className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100"
              type="button"
              onClick={() => {
                append({ value: "" });
              }}
            >
              +
            </button>
          </div>
        );
      }
      case "text": {
        return (
          <input
            type={"text"}
            defaultValue={defaultValues.title}
            placeholder={defaultValues.title}
            {...register(element.name)}
            className="h-10 border mt-1 rounded px-4 w-full bg-gray-50"
          />
        );
      }
    }
  };
  return (
    <div className="p-6 bg-gray-100 flex items-center justify-center min-h-0 md:min-h-[70vh]">
      <div className="container max-w-screen-lg mx-auto lg:mt-6">
        <div>
          <div className="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
            <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
              <div className="text-black ">
                <img
                  src={`data:image/jpeg;base64,${btoa(
                    new Uint8Array(defaultValues.image.data).reduce(
                      (data, byte) => data + String.fromCharCode(byte),
                      ""
                    )
                  )}`}
                  draggable={false}
                  className="h-32 mx-auto lg:h-auto"
                  alt="logo"
                />
              </div>
              <div className="lg:col-span-2">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5">
                    {dataForm.form.map((element, index) => {
                      return (
                        <div className="md:col-span-5" key={index}>
                          <label form={element.name} className="font-bold">
                            <h3>{element.label}</h3>
                            <p className="text-gray-400">{element.subLabel}</p>
                          </label>

                          {ElementSwitch(element)}
                        </div>
                      );
                    })}
                    <div className="md:col-span-5 text-right">
                      <div className="inline-flex items-end">
                        <button
                          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded disabled:bg-gray-300"
                          type="submit"
                          value="submit"
                          disabled={!isValid}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomEditForm;
