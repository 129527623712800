import React from "react";

const ArticleSection = ({ title, content }) => (
  <div className="mb-4">
    <h2 className="text-lg font-semibold mb-2">{title}</h2>
    <div className=" indent-10" dangerouslySetInnerHTML={{ __html: content }} />
  </div>
);
export const Bylaws = () => {
  return (
    <div className="container mx-auto my-8 p-8 bg-white shadow-md rounded-md">
      <h1 className="text-3xl font-semibold mb-6">BYLAWS</h1>
      <p className="mb-4">
        W.O.S.C.P. is a professional association founded by established covert
        surveillance, close-protection professionals in the forensic, corporate,
        and criminal justice fields, who have served undercover units in the
        organized crime line, the United Nations, and forensic investigators who
        later became private investigators. It has been proven that all
        corporate activities of close-protection, investigations, security,
        counter-terrorism, espionage are based on covert surveillance and the
        development of these industries cannot be achieved without the
        application of surveillance knowledge. Personal sources are not
        prolific, no one gives us information as in the past and investigations
        cannot be done only on the basis of public, open sources. This is why
        this association was founded, to open new horizons for the mentioned
        industries whose methods have faded, giving way to intelligent ones
        covert surveillance, intelligence analysis, forensics and others, to lay
        the cornerstone for the new requirements of the investigation,
        close-protection and security professions. The essence of this
        association is competence, without which its establishment would be
        meaningless. Since the quality of its members is paramount and the
        constitution contains specific rules and regulations. Members must learn
        from us and we do not allow any interference in the association other
        than that based on experience.
      </p>
      <ArticleSection
        title="Art.1 - Code of Ethics and Professional Standards"
        content="This statute represents the legal framework within which this organization conducts its business taking into account the professional standards and the rigor imposed by its management."
      />
      <ArticleSection
        title="Art.2 – Member’s consent"
        content="The member's consent shall be made in writing by application for membership, accompanied by the proof of business license, resumé and annual payment of the subscription the candidate opts for, all issued to the email address of the organization office@woscp.org
Any member may withdraw his/her consent to membership by notifying the W.O.S.C.P. office in writing with details of the personal data that may be processed.
"
      />
      <ArticleSection
        title="Art.3 - Selection of membership"
        content="The W.O.S.C.P. Chairman will review, verify and propose to the GC members the acceptance, postponement or rejection of applications for WOSCP membership.
Membership in W.O.S.C.P. is done by submitting the application form together with the supporting documents to the organisation's office.
"
      />
      <ArticleSection
        title="Art.4 - Members Selection Committee "
        content="The committee will be chaired by the chairperson who will review all applications and decide on the applicant's admission to the category for which they have applied. 
In case of rejection of the application, the respondent has the possibility to re-examine the application, which must be submitted to the W.O.S.C.P. office within 15 days from the communication. The Committee will re-examine the application taking into account the knowledge and skills of the applicant, will check whether they meet the requirements of W.O.S.C.P, the new documents submitted and the reasons given.
The decision will be communicated within 10 working days and will be final.
The Chairman of W.O.S.C.P. may unilaterally reject any application for admission or when the committee members do not agree to admit the applicant even if he/she meets the conditions. The Chairman may also admit any application through the application review committee even if the applicant has not met all the specified conditions.
Applications for membership submitted must be accompanied by proof of full payment of the subscription for the first year, which is considered a probationary year for the applicant. Membership renewals after one year of probation will be reduced when the probationary member becomes a full member.
"
      />
      <ArticleSection
        title="Art.5 - Registration fees"
        content="GC will review the subscription rates and the one-off application fee annually. The annual subscription will be paid for the duration of membership and ceases when membership is lost by application or for other reasons.
Failure to pay the subscription within 30 days of invoicing will incur interest charges under the Late Payment of Commercial Debts Act 1998. WOSCP members on reaching the age of 70 years are entitled to a 50% discount on the subscription, subject to continued membership for the last 10 years.
"
      />{" "}
      <ArticleSection
        title="Art.6 - Corporate membership"
        content="At the corporate level, any company, regardless of the form of organization, if the company is headed by at least 1 investigator or has at least 1 investigator employed, can apply for corporate membership through the appropriate form.
The company representative is responsible for the accuracy of the data entered on the form. Detection of false data automatically leads to cancellation of the application or loss of membership, depending on when this is acknowledged.
"
      />{" "}
      <ArticleSection
        title="Art.7 - W.O.S.C.P employed member "
        content="Members employed by a company (who are not business owners, owners or directors of a company) will not be allowed to use the logo, association name on stationery, advertising materials, letterheads or other such items. Only the management of the company that is a W.O.S.C.P. member has this right, not its employees."
      />
      <ArticleSection
        title="Art.8 - Making press, radio and television statements"
        content="Going out in public and giving interviews, participating in broadcasts by members of the association is allowed only with the approval of the organization’s chairman. After obtaining approval, the full member may go out to the media for statements or interviews.
Subjects concerning the activity of W.O.S.C.P., internal problems, aspects of the internal documents of the association or the communication of verbal information or copies of documents to a third party may not be released to the press.
The acts mentioned above shall be considered as offences and shall be punishable under these Bylaws by loss of membership.
"
      />
      <ArticleSection
        title="Art.9 - Effects of loss of membership"
        content="
Members suspended or removed from the organisation are required to remove any details that may refer to W.O.S.C.P. within 30 days. Further use in violation of these provisions may result in legal action as well as fees.
Any member of the W.O.S.C.P. is obliged to notify the office in writing if he/she is accused of committing an offence with intent. Depending on the factual data, the GC may take action if it deems it necessary.
 Full members may not employ/cooperate/associate/employ former W.O.S.C.P members who have violated the rules, have been expelled for disciplinary reasons or have resigned during disciplinary proceedings.
No applicant wishing to join the association will be admitted if they have committed crimes and their conviction has become final. The application will be rejected without further checks. Moreover, any member who has committed a criminal offence for which he has been convicted will be expelled from the membership.
Members of W.O.S.C.P. cannot carry out activities outside the legal framework. If they are subject to any complaints, sanctions or criminal convictions for this reason, they will lose their membership and if they apply for membership, their application will be rejected.
 	W.O.S.C.P. members who mislead the management of the organisation by filling in forms, reports or information with false data and information may lose their full membership.
 
"
      />
      <ArticleSection
        title="Art.10 - Governing Council (GC)"
        content="Any full member with a minimum of 3 years in W.O.S.C.P. is considered eligible and may apply for a position in the GC. No member may nominate more than 2 candidates for GC. Votes will be submitted by e-mail, including by proxy.
The Chairman shall appoint at his/her discretion an election oversight committee consisting of two persons, one Coordinating Officer who must be a full member with at least three consecutive years of membership in the Organisation and one Assistant Coordinating Officer who must be a full member with at least one year of membership in W.O.S.C.P. Both officers must not be members of the GC.
 The attendance of any person who is not a member of the Association at any meeting of W.O.S.C.P. is subject to the approval of the Chairman.
The GC organisation chart is as follows:
                 <br/><br/>
CHAIRMAN <br/>
Covert Surveillance and Close-protection Specialisation <br/>
                      Discipline, International Academy of Surveillance <br/> <br/>
                                          
GENERAL SECRETARY <br/>
                                              office, Finance, Media <br/> <br/>

MANAGING DIRECTOR		          <br/> Covert surveillance Specialisation  <br/><br/> DIRECTOR<br/>	Close-protection Specialisation 	 <br/>	      <br/>    DIRECTOR <br/>   Organisation planning<br/>

             
"
      />
      <ArticleSection
        title="Art. 11 - Election of the GC"
        content="The GC will be composed of 5 members of Director rank. Of these 2 are appointed:
The Chairman of the organisation and the Secretary General who will carry out the day-to-day activities and will be remunerated and the other 3 are elected from full members and will be remunerated separately by sums, commissions fixed after the first elections. The Chairperson of the GC is the Chairman or another founding person may be appointed for an indefinite period. He appoints the Secretary General who is also the Vice Chairman of the organisation.
The elected directors will be selected first on the basis of the above criteria and will serve for a period of 3 years after which elections will be held.
They must meet the conditions:<br/>
- have a minimum of 3 consecutive years' seniority in W.O.S.C.P.<br/>
- have a certified qualification for the specific position for which they are applying<br/>
- have at least 5 years' experience in the field of the specific qualification of the post<br/>
- know written and spoken English<br/>
- specialist judicial experience may be an advantage<br/>
The GC will decide on the number of vacancies to be filled, taking into account the number of vacancies that become vacant through resignation, illness or other causes of termination. The Chairman may also participate in the debates at the meetings and give his opinion on the draft decisions.

"
      />
      <ArticleSection
        title="Art. 12 - Appointments of new GC members"
        content="The newly elected members will be sworn in at the first ordinary meeting after the General Meeting (GM).
Expenses allowed to GC members will be reasonable, generally for single accommodation and related transport. In exceptional cases, a private vehicle will be used after notification to the Chairman and receipt of approval,
Specific conditions for participation in GC elections:
<br/>
 -have at least 5 years of experience in the profession of surveillance investigator/close-protection/security, proven by documents of qualification in the field<br/>
- be physically fit<br/>
- have computer skills certified by a qualification provider<br/>
- have a minimum of 2 consecutive years' seniority in W.O.S.C.P.<br/>
- have computer skills certified by an accredited qualification provider<br/>
- be licensed as a private investigator or security licensed private investigator<br/>
- have written and spoken English<br/>
"
      />
      <ArticleSection
        title="Art.13. - GC tasks"
        content="The GC will consist of 7 members, and will manage administrative, executive work-related issues and will propose to the Chairman the opinion for various measures:
1.Request training on covert surveillance, close-protection and security for members in the area of competence<br/>
2. Organisation of seminars and business meetings<br/>
3. Participation of GC management and the Chairman in high-risk actions to operationally assist our members<br/>
4. Registration of new members by sending them documents by email.<br/>
The main task of the branch chairman will be to recruit new members, especially world-renowned companies, and to continuously raise the level of training of branch members.<br/>
5. Proposal of projects, decisions and resolutions GC for approval and implementation with the positive endorsement of the Chairman<br/>
The GC will allocate an annual amount deemed necessary for the proper functioning of the activity in that branch upon written request of the applicants.<br/>
"
      />
      <ArticleSection
        title="Art.14 - Disciplinary Committee"
        content="The Disciplinary Committee consists of:
Disciplinary Chairperson appointed by the Chairman without voting rights within the committee.<br/>
The Committee - The Committee will consist of full members and will have the following constitution:<br/>
1. Disciplinary Chairperson<br/>
2. Secretary<br/>
3. Three members of the committee from the chairpersons of the branches except the one from the area where the person under investigation is active.<br/>
Disciplinary Secretary<br/>
The secretary of the committee will be appointed by the chairman of the organisation from among the members who are not members of the GC without voting rights and will carry out only administrative activities.
"
      />
      <ArticleSection
        title="Art.17 - Disciplinary procedure"
        content="The Disciplinary Committee shall perform its duties in accordance with this Constitution.
If the person under investigation challenges a member of the disciplinary committee, the chairman shall appoint another member of the association to take over the role and duties of the changed member.

"
      />
      <ArticleSection
        title="Art.18 - Disciplinary Sanctions"
        content="1. Warning<br/>
2. Written reprimand<br/>
3. Suspension for a period of 3 months or 6 months depending on severity<br/>
4. Exclusion from W.O.S.C.P.<br/>
"
      />
      <ArticleSection
        title="Art.17- Disciplinary investigation. Procedural measures"
        content="Complaints of disciplinary offences against a member shall be made in writing to the W.O.S.C.P. email address. It shall be accompanied by all supporting evidence: documents, photographs, reports and any other evidence useful to the committee. The application and the materials received will be certified by the applicant: True to the original. All documents will be accompanied by the special form set up by the association.
Upon receipt, the Association Secretary will forward the form with documentation in an envelope file to the Disciplinary Secretary.
The Secretary of the Disciplinary Committee shall forward all material received by official notification by email to the member under investigation.
Given the specificity of our association which has members all over the world, correspondence will be conducted by email.
The Secretary of the Disciplinary Board will make a summary of the documents and set the date for the start of the disciplinary investigation procedure.
The member who is the subject of the investigation shall send to the Secretary of the committee a statement of his views and of the evidence he intends to use in his defence.
The complaint must be submitted within 15 days. After receipt, the Registrar shall communicate it to the applicant. Failure to reply will result in the proceedings continuing in the absence of evidence and the claimant will not be entitled to submit any evidence during the investigation period.
Acknowledgement of the complaint by the accused member and taking responsibility for remedying all aspects of the complaint will lead to the closure of the work. Non-acceptance or partial acceptance of the allegation will lead to continuation of the proceedings.
The complainant will respond to the accused member within 30 calendar days of receipt of the complaint. Failure to reply within the said period will result in the complaint being closed. The reply received from the complainant will be forwarded to the member under disciplinary investigation.
The Disciplinary Committee will review all the material in the file and gather the evidence necessary to establish the truth for both the complainant and the accused member. Once the evidence admission stage is completed, the hearing of the complainant and the accused member will take place online and the hearing will proceed. After mentioning the arguments of the parties, the committee’s decision is pending.
The decision will be made by the majority within 7 days and, if the committee considers that further evidence is required, it will extend the time limit for the decision until the issues to be clarified have been clarified. During this time the file will be kept by the secretary of the commission. The decision given may be appealed against within 10 days of receipt by email.
"
      />
      <ArticleSection
        title="Art.17. Appeal against the decision issued by the Disciplinary Committee"
        content="Once the decision is drafted, which cannot exceed 30 days, it will be submitted to the commission and to the parties. From the moment of transmission by email, they have 10 days to submit their appeal and the evidence they rely on but which has not been previously submitted in the first instance.
GC will be able on appeal to:<br/>
- uphold the decision of the first instance committee,<br/>
- to annul the initial decision, to change the decision given in whole or in part<br/>
- to order the establishment of a new commission to rehear the case. The members of the second panel will be from among those who were not on the first panel.<br/>
Upon receipt of the request for appeal, the secretary of the disciplinary committee will set a 30-day time limit for the appeal, specifying in the summons the date and time of the hearing.
The persons participating in the appeal will be:<br/>
1. The newly elected Chairman<br/>
2. The newly elected Secretary<br/>
3. Voting members in the number of 3 of the chairpersons of branches minus the one in the area where the person under investigation is active
The decision made on appeal is final. It will be attached to the member's file and the file will remain on file at the association's office.
The decision will be sent to the parties as soon as it is typed and signed.

"
      />
      <ArticleSection
        title="Art.18 - W.O.S.C.P partnership with other organizations and companies"
        content="W.O.S.C.P. may enter into written partnerships with various organizations or companies for various services necessary for the smooth running of the association's activities."
      />
      <ArticleSection
        title="Art.19. Surveillance Academy  "
        content="W.O.S.C.P Academy is represented by INTERNATIONAL SURVEILLANCE ACADEMY (I.S.A) - SURVEILLANCE TRAINING LTD, the most titled training organization on the covert surveillance line which will be conducting in-depth training for the members of the association in several competencies, by region, upon request of its members. Participation fees will be discounted for members depending on the category of membership for which they pay the subscription."
      />
      <ArticleSection
        title="Art. 20. Types of subscriptions existing within W.O.S.C.P:"
        content="
The members of W.O.S.C.P. are:<br/>
1. Individual members<br/>
2. Corporate members<br/>
3. Affiliate members - £150.00 per year<br/>
<br/>
For those who work in a profession directly related to covert surveillance:<br/>
-	Forensics<br/>
-	Experts in operational fields and professions<br/>
-	Intelligence analysis<br/>
-	Law enforcement specialists<br/>
<br/>
4. Fellow of the Association - £125.00 per year<br/>
5. Students<br/>
Both individual and corporate members can opt for more benefits for the following options:<br/>
<br/>
One-off application fee 		£50 (Non-refundable)<br/>
Full membership    		£100<br/>
Bronze member      		£125<br/>
Silver member      		£150<br/>
Gold member      			£175<br/>
<br/>
Full Corporate member		£175<br/>
Bronze member      		£200<br/>
Silver member      		£225<br/>
Gold member      			£250<br/>
<br/>
Full students' membership   	 £70<br/>
Bronze member      		 £75<br/>
Silver member      		 £80<br/>
Gold member      			 £85<br/>
<br/>
Gold members get 40% discount on books, publications, online video courses, participation in leading complex actions with association members, online consultations with the chairman for help in certain actions, online consultations for analysis and preparation of high-risk actions and other activities carried out to raise the level of professionalism.
<br/><br/>
Silver members get 30% discount on books, publications, online video courses, participation in leading complex actions with association members, online consultations with the chairman for help in certain actions, online consultations for analysis and preparation of high-risk actions and other activities carried out to raise the level of professionalism
<br/><br/>
Bronze members get 20% discount on books, publications, online video courses, participation in leading complex actions with association members, online consultations with the chairman for help in certain actions, online consultations for analysis and preparation of high-risk actions and other activities carried out to raise the level of professionalism


"
      />
    </div>
  );
};
