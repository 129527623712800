import CustomForm from "../../../Components/FormReferral";
import { dataAcademyForm } from "../../../Components/data";
import { useForm } from "react-hook-form";
import Axios from "axios";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";

import { SideMenu } from "../SideMenu";
import { UseAdminAuth } from "../../../Components/utils/useAdminAuth";

export const EditAcademyFormPage = () => {
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [content, setContent] = useState({ course: [], date: [] });
  const { loggedIn } = UseAdminAuth();

  const academyForm = useForm();
  const editAcademyForm = useForm({
    defaultValues: {
      course: [{ value: "" }],
      date: [{ value: "" }],
    },
  });

  useEffect(() => {
    editAcademyForm.reset({
      course: content.course.map((item) => ({ value: item })),
      date: content.date.map((item) => ({ value: item })),
    });
  }, [content, editAcademyForm]);

  useEffect(() => {
    const existingMetaTag = document.querySelector('meta[name="robots"]');

    if (!existingMetaTag) {
      const metaTag = document.createElement("meta");
      metaTag.name = "robots";
      metaTag.content = "noindex,nofollow";
      document.head.appendChild(metaTag);
    } else {
      existingMetaTag.content = "noindex,nofollow";
    }
  }, []);

  useEffect(() => {
    Axios.get(
      "https://server.woscp.org/api/content/engagement-academy-data",
    ).then((res) => {
      if (res.data.content[0].data !== "{}") {
        const rawData = JSON.parse(res.data.content[0].data);

        // Transform the data structure
        const transformedData = {};
        Object.keys(rawData).forEach((key) => {
          transformedData[key] = rawData[key].map((item) => item.value);
        });

        setContent(transformedData);
      }
    });
  }, [submitSuccess]);

  if (!loggedIn) {
    return <></>;
  }

  const dataEditAcademyForm = {
    title: "Change Academy Form available courses and dates",
    subtitle:
      "Use the form below to update the Academy form courses and dates. Changes will take effect immediately.",
    form: [
      {
        label: "Course",
        type: "dynamic",
        name: "course",
      },
      {
        label: "Date",
        type: "dynamic",
        name: "date",
      },
    ],
  };

  const onAcademyDataSubmit = (d) => {
    console.log("content", content);
    console.log("d", d);
    if (
      content.course.length !== d.course.length ||
      content.date.length !== d.date.length ||
      content.course.some((value, index) => value !== d.course[index].value) ||
      content.date.some((value, index) => value !== d.date[index].value)
    ) {
      Axios.post("https://server.woscp.org/api/admin/edit-content", {
        type: "academy",
        content: d,
        token: Cookies.get("woscp_admin"),
      })
        .then(() => {
          toast("Your form has been successfully submitted!", {
            type: "success",
          });
          editAcademyForm.reset();
          setSubmitSuccess((prevState) => !prevState);
        })
        .catch(() => {
          toast("Oops! Something went wrong.", { type: "error" });
          editAcademyForm.reset();
        });
    } else {
      toast("Update data before submitting changes.", { type: "error" });
    }
  };

  return (
    <div>
      <SideMenu />
      <div className="w-full md:w-[calc(100%-256px)] absolute right-0 overflow-auto">
        <div className="flex flex-col gap-12 ">
          <CustomForm
            dataForm={dataEditAcademyForm}
            form={editAcademyForm}
            onSubmit={onAcademyDataSubmit}
            dynamicFieldName={"course"}
          />
          <div key={submitSuccess}>
            <CustomForm
              dataForm={{
                ...dataAcademyForm,
                form: [
                  ...dataAcademyForm.form,
                  {
                    label: "Course",
                    type: "dropdown",
                    options: content.course,
                    name: "course",
                  },
                  {
                    label: "Date",
                    type: "dropdown",
                    options: content.date,
                    name: "date",
                  },
                ],
              }}
              form={academyForm}
              onSubmit={() => null}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
