import {
  FaceSmileIcon,
  ChartBarSquareIcon,
  CursorArrowRaysIcon,
  DevicePhoneMobileIcon,
  AdjustmentsHorizontalIcon,
  SunIcon,
} from "@heroicons/react/24/solid";
import BenefitOneImage from "../Assets/img/Investigation.webp";
import BenefitTwoImage from "../Assets/img/detective.jpg";
import ImgDoruGeru from "../Assets/img/aboutUs/Col. Doru Geru.jpg";
import ImgAlinaLuchian from "../Assets/img/aboutUs/Alina Luchian.jpeg";
import ImgViceChairman from "../Assets/img/aboutUs/Vice-Cheirman General Secretary.jpeg";
import ImgAvatar from "../Assets/img/aboutUs/avatar.png";

const benefitOne = {
  title: "W.O.S.C.P. - Your Trusted Investigator Organization",
  desc: "At W.O.S.C.P., we offer a range of valuable benefits that empower your organization and enhance your investigative capabilities. Let's explore how we can help you excel in the world of investigations:",
  image: BenefitOneImage,
  bullets: [
    {
      title: "Understand your customers",
      desc: "Gain profound insights into your clients' needs, preferences, and behaviors.",
      icon: <FaceSmileIcon />,
    },
    {
      title: "Improve acquisition",
      desc: "Enhance your investigative efficiency and resource utilization.",
      icon: <ChartBarSquareIcon />,
    },
    {
      title: "Drive customer retention",
      desc: "Build trust, offer peace of mind, and keep clients returning for your services.",
      icon: <CursorArrowRaysIcon />,
    },
  ],
};

const benefitTwo = {
  title: "We offer more benefits",
  desc: "Our team of specialist investigators brings a wealth of experience and knowledge to the table. With years of dedicated work in various investigative fields, we offer a level of expertise that is hard to match elsewhere.",
  image: BenefitTwoImage,
  bullets: [
    {
      title: "Deep Insight into Cases",
      desc: "Gain comprehensive insights into your investigations, helping you uncover vital information and make informed decisions.",
      icon: <DevicePhoneMobileIcon />,
    },
    {
      title: "Streamlined Resource Management:",
      desc: "Optimize your investigative processes, ensuring efficient allocation of resources and enhanced results.",
      icon: <AdjustmentsHorizontalIcon />,
    },
    {
      title: "Trust and Client Retention",
      desc: "Build long-lasting trust with your clients, ensuring their return for your private investigative services.",
      icon: <SunIcon />,
    },
  ],
};

const dataReferralForm = {
  title: "Referral to an Investigator",
  subtitle:
    "Gain access to the database of thoroughly vetted and trained professionals curated by the W.O.S.C.P. (World Organization of Surveillance and Close Protection). Alternatively, you can share your specific requirements using the form provided below. This ensures a confidential referral to a qualified professional accredited by W.O.S.C.P., the primary professional body for investigators globally. Rest assured that your needs will be handled with the utmost professionalism and expertise through this secure and reliable channel.",
  form: [
    {
      label: "Nature of Investigation:",
      subLabel:
        "Trace, Matrimonial/Domestic, Fraud, Debt, Process Serve, Probate, Employment, Contract, Due Diligence, Background, Financial Status, Other",
      type: "text",
      value: "",
      name: "nature_of_investigation",
    },
    {
      label: "Your full name:",
      type: "text",
      name: "full_name",
    },
    {
      label: "Contact number:",
      subLabel: "We may need to contact you to clarify your requirements",
      type: "number",
      name: "number",
    },
    {
      label: "Email",
      type: "email",
      name: "email",
    },
    {
      label: "Your locality/home town/city and postcode",
      subLabel: "This will assist us to verify your identity",
      type: "text",
      name: "address",
    },
    {
      label: "Locality of task, if known",
      type: "text",
      name: "addressTask",
    },
    {
      label: "Brief outline of case scenario include purpose",
      subLabel:
        "Please explain briefly something about your case and the reason why you require the investigation",
      type: "textarea",
      name: "scenario",
    },
    {
      label: "How did you hear about us?",
      subLabel:
        "Please explain briefly something about your case and the reason why you require the investigation",
      type: "text",
      name: "hear",
    },
  ],
};
const dataAccomplishmentForm = {
  title: "Share your accomplishments",
  subtitle: `At <strong>W.O.S.C.P.</strong>, we invite our surveillance and close protection professionals to showcase their achievements. Whether it's a successful surveillance mission or a standout protection assignment, your accomplishments deserve recognition. Share your success stories with us through our simple submission form.
   </br> </br>  Your achievements not only gain acknowledgment but also inspire others in our community. The "Share Your Accomplishments" section provides networking opportunities and a chance to be featured prominently on our site after careful review by our Governing Council. Join us in celebrating the excellence of our members and contributing to a stronger, connected professional network. Submit your accomplishments today and be part of a community that values and applauds your successes.`,
  form: [
    {
      label: "Accomplishment title",
      subLabel: "",
      type: "text",
      value: "",
      name: "accomplishment_title",
    },
    {
      label: "Accomplishment description",
      subLabel:
        "Feel free to provide pertinent links if this achievement has been previously documented",
      type: "textarea",
      value: "",
      name: "accomplishment_description",
    },
  ],
};
const dataContactForm = {
  title: "Contact Us",
  subtitle: "",
  form: [
    {
      label: "First Name:",
      subLabel: "",
      type: "text",
      value: "",
      name: "first_name",
    },
    {
      label: "Last name:",
      type: "text",
      name: "last_name",
    },
    {
      label: "Contact number:",
      type: "number",
      name: "number",
    },
    {
      label: "Email",
      type: "email",
      name: "email",
    },
    {
      label: "Message",
      type: "textarea",
      name: "message",
    },
  ],
};
const dataConsultantForm = {
  title: "Expert Consultation Hub",
  subtitle:
    "Welcome to our Expert Consultation Hub, where members can engage in confidential discussions with our seasoned specialists. Whether you need strategic advice, insights on complex cases, or guidance on surveillance and close protection matters, our experts are here to provide tailored solutions. Take advantage of this exclusive opportunity to enhance your skills and address challenges head-on by tapping into the wealth of knowledge within our community. Your success is our priority, and our specialists are ready to support you every step of the way.",
  form: [
    {
      label: "Nature of Consultation",
      subLabel: "Briefly describe the issue or topic you'd like to discuss",
      type: "text",
      value: "",
      name: "consultation",
    },
    {
      label: "Background Information",
      subLabel:
        "Include relevant details about your surveillance or close protection case",
      type: "textarea",
      name: "background_information",
    },
    {
      label: "Specific Questions or Concerns",
      subLabel:
        "List any specific questions or concerns you'd like to address during the consultation",
      type: "text",
      name: "specific_questions",
    },
  ],
};
const dataAcademyForm = {
  title: "Academy Enrollment",
  subtitle:
    "Embark on a transformative learning journey by enrolling in our academy. This streamlined form ensures a seamless and hassle-free registration process. Join a community of dedicated learners, enhance your skills, and unlock new opportunities in the dynamic fields of surveillance and close protection. Enroll today and take the first step towards mastering the art of professional excellence.",
  form: [
    {
      label: "First Name",
      subLabel: "",
      type: "text",
      value: "",
      name: "first_name",
    },
    {
      label: "Last name",
      type: "text",
      name: "last_name",
    },
    {
      label: "Contact number:",
      type: "number",
      name: "number",
    },
    {
      label: "Email",
      type: "email",
      name: "email",
    },

  ],
};

export const dataEditModalForm = {
  title: "Edit Engagement Modal",
  form: [
    { label: "Title", subLabel: "", type: "text", value: "", name: "title" },
    { label: "Line2", subLabel: "", type: "text", value: "", name: "line2" },
    { label: "Line3", subLabel: "", type: "text", value: "", name: "line3" },
    { label: "Line4", subLabel: "", type: "text", value: "", name: "line4" },
  ],
};
export const dataEditAcademyForm = {
  title: "Edit Academy courses and availability",
  form: [
    {
      label: "Course titles",
      subLabel: "",
      type: "text",
      value: "",
      name: "course",
    },
    { label: "Dates", subLabel: "", type: "text", value: "", name: "date" },
  ],
};

export const dataAddNewsArticle = {
  title: "Add a new article",
  form: [
    { label: "Title", subLabel: "", type: "text", value: "", name: "title" },
    {
      label: "Tags",
      subLabel: "",
      type: "dropdown",
      options: [
        "Surveillance",
        "Close Protection",
      ],
      name: "tags",
    },
    {
      label: "Image Link",
      subLabel: "",
      type: "text",
      value: "",
      name: "image",
    },
    {
      label: "Description",
      subLabel: "",
      type: "textarea",
      value: "",
      name: "description",
    },
  ],
};

const teamMembers = [
  {
    name: "Colonel.r Doru Geru",
    role: "Chairman",
    description:
      "With 33 years of experience, 22 as police officer, Head of police - surveillance unit for organized crime and 1400 successful covert surveillance operations for government. Security licensed specialist",
    image: ImgDoruGeru,
    linkedIn: "https://www.linkedin.com/in/colonel-r-doru-geru-93a59010a/",
  },
  {
    name: "General.r Virgil Zodie",
    role: "Honorary Chairman",
    description:
      "Former general director of police surveillance units, Ministry of Internal Affairs",
    image: ImgAvatar,
  },
  {
    name: "Claudiu Octavian Vicol",
    role: "Vice - Chairman General Secretary",
    description:
      "Translation expert in legal, law enforcement, covert surveillance, criminal investigations fields, sworn translator certified by the Romanian Ministry of Justice, working with all law enforcement authorities, courts of law,  fluent in several languages, proficient in communication and management in various fields",
    image: ImgViceChairman,
  },
  {
    name: "Alina Luchian",
    role: "Director Marketing and Development",
    description:
      "Level 5 in Business Management graduate. 15 years of experience as a police worker in the judicial police and operative actions. 3 years experience in the security industry as a CPO licensed specialist, surveillance, and covert surveillance. Vast experience of over 18 years in Customer Service",
    image: ImgAlinaLuchian,
  },
];

const dataEditEngagementModalForm = {
  title: "Change Engagement modal content",
  subtitle:
    "Use the form below to update the engagement modal. Changes will take effect immediately.",
  form: [
    {
      label: "Title",
      subLabel: "",
      type: "text",
      value: "",
      name: "title",
    },
    {
      label: "Line 2",
      subLabel: "",
      type: "text",
      value: "",
      name: "line2",
    },
    {
      label: "Line 3",
      subLabel: "",
      type: "text",
      value: "",
      name: "line3",
    },
    {
      label: "Line 4",
      subLabel: "",
      type: "text",
      value: "",
      name: "line4",
    },
  ],
};

const dataAddNewsForm = {
  title: "Add a new article",
  form: [
    {
      label: "Title",
      type: "text",
      name: "title",
    },
    {
      label: "Description",
      type: "richtext",
      name: "description",
    },
    {
      label: "Tags",
      type: "checkbox",
      options: ["Surveillance", "Close-Protection"],
      name: "tags",
    },
    {
      label: "Image",
      type: "image",
      name: "image",
    },
  ],
};
export {
  benefitOne,
  benefitTwo,
  dataReferralForm,
  dataAccomplishmentForm,
  dataConsultantForm,
  dataContactForm,
  dataAcademyForm,
  teamMembers,
  dataEditEngagementModalForm,
  dataAddNewsForm,
};
