const errors = {
  mininumLengthName: "Name is too short or empty!",
  containingNumbers: "Name must not contain a number!",
  incorrectEmail: "Invalid email!",
  invalidPhone: "Invalid phone number!",
  mininumLengthMessage: "Message is too short or empty!",
  mininumLengthPassword: "Password is too short or empty!",
  passwordUnmatch: "Passwords do not match",
  shortField: "This field is too short or empty!",
  containingNumbersField: "This field must not contain a number!",
};

const emailRegex = /\S+@\S+\.\S+/;
const phoneRegex =
  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

export const nameValidation = (name) => {
  if (name.length < 3) {
    return errors.mininumLengthName;
  }
  if (/\d/.test(name)) {
    return errors.containingNumbers;
  }
  return "";
};

export const addressValidation = (address) => {
  if (address.length < 3) {
    return errors.shortField;
  }
  return "";
};

export const cityOrCountryValidation = (string) => {
  if (string.length < 3) {
    return errors.shortField;
  }
  if (/\d/.test(string)) {
    return errors.containingNumbersField;
  }
  return "";
};

export const emailValidation = (email) => {
  if (!emailRegex.test(email)) {
    return errors.incorrectEmail;
  }
  return "";
};
export const phoneValidation = (phoneNumber) => {
  if (!phoneRegex.test(phoneNumber)) {
    return errors.invalidPhone;
  }
  return "";
};
export const messageValidation = (message) => {
  if (message.length < 3) {
    return errors.mininumLengthMessage;
  }
  return "";
};
export const passwordValidation = (password) => {
  if (password.length < 8) {
    return errors.mininumLengthPassword;
  }
  return "";
};

export const passwordConfirmationValidation = (message, password) => {
  if (message !== password) {
    return errors.passwordUnmatch;
  }
  return "";
};
