import Axios from "axios";
import {
  PayPalScriptProvider,
  PayPalButtons,
  FUNDING,
} from "@paypal/react-paypal-js";
import { useEffect, useState } from "react";
import {
  addressValidation,
  cityOrCountryValidation,
  emailValidation,
  nameValidation,
  passwordConfirmationValidation,
  passwordValidation,
} from "../utils/inputValidations";
import { toast } from "react-toastify";
import { UploadFile } from "../UploadFile";

const membershipPlans = [
  {
    id: "plan-EJ4DDOQSM4E9Y",
    name: "Corporate membership - GOLD Plan",
    quantity: 1,
    unit_amount: {
      currency_code: "GBP",
      value: 250,
    },
  },
  {
    id: "plan-MN1LOY5KTKBT9",
    name: "Corporate membership - SILVER Plan",
    quantity: 1,
    unit_amount: {
      currency_code: "GBP",
      value: 225,
    },
  },
  {
    id: "plan-8SFRHK2KSO0YE",
    name: "Corporate membership - BRONZE Plan",
    quantity: 1,
    unit_amount: {
      currency_code: "GBP",
      value: 200,
    },
  },
  {
    id: "plan-TA52AMWB29PM6",
    name: "Corporate membership - BASE Plan",
    quantity: 1,
    unit_amount: {
      currency_code: "GBP",
      value: 175,
    },
  },
];
function getCookie(cookieName) {
  const name = cookieName + "=";
  const cookieArray = document.cookie.split(";");
  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i];
    while (cookie.charAt(0) === " ") {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return "";
}
export const FirmSignUpDEV = () => {
  const [showPaymentOptions, setShowPaymentOptions] = useState(false);
  const [confirmedAccess, setConfirmedAccess] = useState(false);
  const [errorState, setErrorState] = useState(true);
  const [credentials, setCredentials] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    passwordconfirmation: "",
    firmname: "",
    directorname: "",
    expertise: "",
    country: "",
    city: "",
    address: "",
    areacode: "",
    nbofemployees: "",
    website: "",
    licensenumber: "",
    student: false,
    convicted: false,
    termsandconditions: false,
    cv: null,
    personalPhoto: null,
    license: null,
    passportOrID: null,
  });
  const [formErrors, setFormErrors] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    passwordconfirmation: "",
    firmname: "",
    directorname: "",
    expertise: "",
    country: "",
    city: "",
    address: "",
    areacode: "",
    licensenumber: "",
    convicted: "",
    termsandconditions: "",
    documents: "",
  });
  const selectedMembershipID = getCookie("selected_membership");
  const selectedMembership = membershipPlans.find(
    (plan) => plan.id === selectedMembershipID,
  );

  useEffect(() => {
    const existingMetaTag = document.querySelector('meta[name="robots"]');

    if (!existingMetaTag) {
      const metaTag = document.createElement("meta");
      metaTag.name = "robots";
      metaTag.content = "noindex,nofollow";
      document.head.appendChild(metaTag);
    } else {
      existingMetaTag.content = "noindex,nofollow";
    }
  }, []);

  useEffect(() => {
    if (
      !getCookie("selected_membership") ||
      !membershipPlans.find(
        (plan) => plan.id === getCookie("selected_membership"),
      )
    ) {
      window.location.href = "/become-a-member";
    }
  }, []);
  const FUNDING_SOURCES = [FUNDING.PAYPAL, FUNDING.CARD];

  const initialOptions = {
    "client-id":
      "AQCAkp50iqckhe4_uce55IZdS1g34lBz9LHufWre-3Ac1etxPTy1Eh4m-KY1G6w-7suIVDI-uz94PHsx",
    "enable-funding": "paylater,venmo",
    currency: "GBP",
  };
  const mandatoryFields = [
    "firstname",
    "lastname",
    "email",
    "password",
    "passwordconfirmation",
    "firmname",
    "directorname",
    "expertise",
    "country",
    "city",
    "address",
    "areacode",
    "licensenumber",
  ];
  const registerChecks = () => {
    let emailError = "";
    let expertiseError = "";
    let licensenumberError = "";
    let termsandconditionsError = "";
    let documentsError = "";
    let errors = {};
    Axios.get("https://server.woscp.org/api/users/get-user-by-email", {
      params: {
        email: credentials.email,
      },
    })
      .then((response) => {
        if (response.data === "There is an user with this email") {
          emailError = "This email is already registered!";
        } else {
          emailError = emailValidation(credentials.email);
        }
        if (
          credentials.cv === null ||
          credentials.license === null ||
          credentials.passportOrID === null ||
          credentials.personalPhoto === null
        ) {
          documentsError = "You must upload all necessary documents!";
        }
        if (
          credentials.expertise === "Select an option" ||
          credentials.expertise === ""
        ) {
          expertiseError = "You must select an option";
        }
        if (credentials.student === false && credentials.licensenumber === "") {
          licensenumberError =
            "This field is necessary or check if you're student";
        }
        if (credentials.termsandconditions === false) {
          termsandconditionsError = "This field is necessary";
        }
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          firstname: nameValidation(credentials.firstname),
          lastname: nameValidation(credentials.lastname),
          email: emailError,
          password: passwordValidation(credentials.password),
          passwordconfirmation: passwordConfirmationValidation(
            credentials.passwordconfirmation,
            credentials.password,
          ),
          firmname: nameValidation(credentials.firmname),
          directorname: nameValidation(credentials.directorname),
          country: cityOrCountryValidation(credentials.country),
          city: cityOrCountryValidation(credentials.city),
          address: addressValidation(credentials.address),
          areacode: addressValidation(credentials.areacode),
          expertise: expertiseError,
          licensenumber: licensenumberError,
          termsandconditions: termsandconditionsError,
          documents: documentsError,
        }));
        errors = {
          firstname: nameValidation(credentials.firstname),
          lastname: nameValidation(credentials.lastname),
          email: emailError,
          password: passwordValidation(credentials.password),
          passwordconfirmation: passwordConfirmationValidation(
            credentials.passwordconfirmation,
            credentials.password,
          ),
          firmname: nameValidation(credentials.firmname),
          directorname: nameValidation(credentials.directorname),
          country: cityOrCountryValidation(credentials.country),
          city: cityOrCountryValidation(credentials.city),
          address: addressValidation(credentials.address),
          areacode: addressValidation(credentials.areacode),
          expertise: expertiseError,
          licensenumber: licensenumberError,
          termsandconditions: termsandconditionsError,
          documents: documentsError,
        };
        if (
          Object.keys(errors).some((key) => errors[key] !== "") ||
          mandatoryFields.some((key) => credentials[key] === "")
        ) {
          setErrorState(true);
        } else {
          setErrorState(false);
        }
      })
      .then(() => handleMemberSignUp());
  };

  const onChangeHandle = (e) => {
    if (e.target.id === "firstname") {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        firstname: nameValidation(e.target.value),
      }));
      credentials.firstname = e.target.value;
    }
    if (e.target.id === "lastname") {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        lastname: nameValidation(e.target.value),
      }));
      credentials.lastname = e.target.value;
    }
    if (e.target.id === "email") {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        email: emailValidation(e.target.value),
      }));
      credentials.email = e.target.value;
    }
    if (e.target.id === "password") {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        password: passwordValidation(e.target.value),
      }));
      credentials.password = e.target.value;
    }
    if (e.target.id === "passwordconfirmation") {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        passwordconfirmation: passwordConfirmationValidation(
          e.target.value,
          credentials.password,
        ),
      }));
      credentials.passwordconfirmation = e.target.value;
    }
    if (e.target.id === "firmname") {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        firmname: nameValidation(e.target.value),
      }));
      credentials.firmname = e.target.value;
    }
    if (e.target.id === "directorname") {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        directorname: nameValidation(e.target.value),
      }));
      credentials.directorname = e.target.value;
    }
    if (e.target.id === "expertise") {
      if (e.target.value === "Select an option" || e.target.value === "") {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          expertise: "You must select an option",
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          expertise: "",
        }));
        credentials.expertise = e.target.value;
      }
    }

    if (e.target.id === "country") {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        country: cityOrCountryValidation(e.target.value),
      }));
      credentials.country = e.target.value;
    }
    if (e.target.id === "city") {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        city: cityOrCountryValidation(e.target.value),
      }));
      credentials.city = e.target.value;
    }
    if (e.target.id === "address") {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        address: addressValidation(e.target.value),
      }));
      credentials.address = e.target.value;
    }
    if (e.target.id === "areacode") {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        areacode: addressValidation(e.target.value),
      }));
      credentials.areacode = e.target.value;
    }
    if (e.target.id === "nbofemployees") {
      credentials.nbofemployees = e.target.value;
    }
    if (e.target.id === "licensenumber") {
      if (!e.target.value.length > 3) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          licensenumber: "This field is too short or empty!",
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          licensenumber: "",
        }));
      }
      credentials.licensenumber = e.target.value;
    }
    if (e.target.id === "website") {
      credentials.website = e.target.value;
    }

    if (e.target.id === "convicted") {
      credentials.convicted = e.target.value;
    }

    if (e.target.id === "termsandconditions") {
      if (!e.target.checked) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          termsandconditions: "This field is necessary",
        }));
      } else {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          termsandconditions: "",
        }));
      }
      credentials.termsandconditions = e.target.checked;
    }
  };
  const handleMemberSignUp = () => {
    if (!errorState) {
      Axios.post("https://server.woscp.org/api/users/enroll-firm", {
        account: {
          first_name: credentials.firstname,
          last_name: credentials.lastname,
          email: credentials.email,
          password: credentials.password,
          license_number: credentials.licensenumber,
          student: credentials.student,
          convicted: credentials.convicted,
        },
        firm: {
          firm_name: credentials.firmname,
          director_name: credentials.directorname,
          expertise: credentials.expertise,
          country: credentials.country,
          city: credentials.city,
          address: credentials.address,
          area_code: credentials.areacode,
          nb_of_employees: credentials.nbofemployees | 0,
          website: credentials.website,
        },
        documents: [
          credentials.cv,
          credentials.license,
          credentials.personalPhoto,
          credentials.passportOrID,
        ],
        membership_type: selectedMembership.id,
        membership_name: selectedMembership.name,
      })
        .then(() => {
          window.location.href = "/success-enrolling";
        })
        .catch(() => {
          toast("Oops! Something went wrong.", { type: "error" });
        });
    }
  };
  let totalSum = 50;
  const shippingCost = 0;

  if (!confirmedAccess) {
    return (
      <div className="mb-4 mx-auto my-auto">
        <meta name="robots" content="noindex,nofollow" />
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="website"
        >
          PASSWORD:
        </label>
        <input
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          type="text"
          onChange={(e) => {
            if (e.target.value === "8642097531") {
              setConfirmedAccess(true);
            }
          }}
          placeholder="DEV PASSWORD"
        />
      </div>
    );
  }
  return (
    <div className="my-5 mx-auto w-full md:w-[70%]">
      <meta name="robots" content="noindex,nofollow" />
      {!showPaymentOptions && (
        <div className="w-full">
          <h2 className="mb-4 text-2xl text-black font-bold text-center">
            Please Complete the Enrollment Form
          </h2>
          <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
            <div className="mb-4 w-full min-w-[250px]">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="membershiptype"
              >
                You are enrolling for membership
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="membershiptype"
                disabled
                type="text"
                value={selectedMembership?.name}
              />
            </div>
            <div className="flex flex-col gap-0 justify-between lg:flex-row lg:gap-4">
              <div className="mb-4 w-full min-w-[250px]">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="firstname"
                >
                  First name
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  onChange={onChangeHandle}
                  id="firstname"
                  type="text"
                  placeholder="First name"
                />
                {formErrors.firstname && (
                  <p className="text-red-500 text-xs italic pl-2">
                    {formErrors.firstname}
                  </p>
                )}
              </div>
              <div className="mb-4 w-full min-w-[250px]">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="lastname"
                >
                  Last name
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  onChange={onChangeHandle}
                  id="lastname"
                  type="text"
                  placeholder="Last name"
                />
                {formErrors.lastname && (
                  <p className="text-red-500 text-xs italic pl-2">
                    {formErrors.lastname}
                  </p>
                )}
              </div>
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="email"
              >
                Email
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                onChange={onChangeHandle}
                id="email"
                type="text"
                placeholder="Email"
              />
              {formErrors.email && (
                <p className="text-red-500 text-xs italic pl-2">
                  {formErrors.email}
                </p>
              )}
            </div>
            <div className="flex flex-col gap-0 justify-between lg:flex-row lg:gap-4">
              <div className="mb-4 w-full">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="password"
                >
                  Password
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  onChange={onChangeHandle}
                  id="password"
                  type="password"
                  placeholder="Password"
                />
                {formErrors.password && (
                  <p className="text-red-500 text-xs italic pl-2">
                    {formErrors.password}
                  </p>
                )}
              </div>
              <div className="mb-4 w-full">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="passwordconfirmation"
                >
                  Password Confirmation
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  onChange={onChangeHandle}
                  id="passwordconfirmation"
                  type="password"
                  placeholder="Password Confirmation"
                />
                {formErrors.passwordconfirmation && (
                  <p className="text-red-500 text-xs italic pl-2">
                    {formErrors.passwordconfirmation}
                  </p>
                )}
              </div>
            </div>
            <div className="flex flex-col gap-0 justify-between lg:flex-row lg:gap-4">
              <div className="mb-4 w-full min-w-[250px]">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="firmname"
                >
                  Firm Name
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  onChange={onChangeHandle}
                  id="firmname"
                  type="text"
                  placeholder="Firm Name"
                />
                {formErrors.firmname && (
                  <p className="text-red-500 text-xs italic pl-2">
                    {formErrors.firmname}
                  </p>
                )}
              </div>
              <div className="mb-4 w-full min-w-[250px]">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="directorname"
                >
                  Director Name
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  onChange={onChangeHandle}
                  id="directorname"
                  type="text"
                  placeholder="Director Name"
                />
                {formErrors.directorname && (
                  <p className="text-red-500 text-xs italic pl-2">
                    {formErrors.directorname}
                  </p>
                )}
              </div>
            </div>
            <div className="mb-4 relative">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="expertise"
              >
                Expertise
              </label>
              <select
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                onChange={onChangeHandle}
                id="expertise"
              >
                <option>Select an option</option>
                <option>Close protection & Security</option>
                <option>Surveillance Investigator</option>
              </select>
              <div
                className={`pointer-events-none absolute right-0 bottom-3 flex items-center px-2 text-gray-700 h-fit ${
                  formErrors.directorname ? "bottom-6" : "bottom-3"
                }`}
              >
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
              {formErrors.expertise && (
                <p className="text-red-500 text-xs italic pl-2">
                  {formErrors.expertise}
                </p>
              )}
            </div>
            <div className="flex flex-col gap-0 justify-between lg:flex-row lg:gap-4">
              <div className="mb-4 w-full min-w-[250px]">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="country"
                >
                  Country
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  onChange={onChangeHandle}
                  id="country"
                  type="text"
                  placeholder="Country"
                />
                {formErrors.country && (
                  <p className="text-red-500 text-xs italic pl-2">
                    {formErrors.country}
                  </p>
                )}
              </div>
              <div className="mb-4 w-full min-w-[250px]">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="city"
                >
                  City
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  onChange={onChangeHandle}
                  id="city"
                  type="text"
                  placeholder="City"
                />
                {formErrors.city && (
                  <p className="text-red-500 text-xs italic pl-2">
                    {formErrors.city}
                  </p>
                )}
              </div>
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="address"
              >
                Address
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                onChange={onChangeHandle}
                id="address"
                type="text"
                placeholder="Address"
              />
              {formErrors.address && (
                <p className="text-red-500 text-xs italic pl-2">
                  {formErrors.address}
                </p>
              )}
            </div>
            <div className="flex flex-col gap-0 justify-between lg:flex-row lg:gap-4">
              <div className="mb-4 w-full min-w-[250px]">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="areacode"
                >
                  Area Code
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  onChange={onChangeHandle}
                  id="areacode"
                  type="text"
                  placeholder="Area Code"
                />
                {formErrors.areacode && (
                  <p className="text-red-500 text-xs italic pl-2">
                    {formErrors.areacode}
                  </p>
                )}
              </div>
              <div className="mb-4 w-full min-w-[250px]">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="nbofemployees"
                >
                  Number of employees
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  onChange={onChangeHandle}
                  id="nbofemployees"
                  type="number"
                  placeholder="Number of employees"
                />
                {formErrors.nbofemployees && (
                  <p className="text-red-500 text-xs italic pl-2">
                    {formErrors.nbofemployees}
                  </p>
                )}
              </div>
            </div>
            <div className="mb-4">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="website"
              >
                Website
              </label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                onChange={onChangeHandle}
                id="website"
                type="text"
                placeholder="Website"
              />
            </div>
            <div className="flex flex-col gap-0 justify-between items-end lg:flex-row lg:gap-4">
              <div className="mb-4 w-full">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="licensenumber"
                >
                  License Number
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  onChange={onChangeHandle}
                  id="licensenumber"
                  type="text"
                  placeholder="License Number"
                />
                {formErrors.licensenumber && (
                  <p className="text-red-500 text-xs italic pl-2">
                    {formErrors.licensenumber}
                  </p>
                )}
              </div>
            </div>
            <div className="mb-4 w-full">
              <label className="text-gray-700 text-sm font-bold flex items-center h-[38px]">
                <input
                  className="mr-2 leading-tight w-5 h-5"
                  type="checkbox"
                  id="convicted"
                  onChange={onChangeHandle}
                />
                <span className="text-gray-700 text-sm font-bold">
                  I have been convicted in the past / I am currently being
                  convicted of a crime.
                </span>
              </label>
              {formErrors.convicted && (
                <p className="text-red-500 text-xs italic pl-2">
                  {formErrors.convicted}
                </p>
              )}
            </div>
            <div className="mb-4 w-full">
              <label className="text-gray-700 text-sm font-bold flex items-center h-[38px]">
                <input
                  className="mr-2 leading-tight w-5 h-5"
                  type="checkbox"
                  id="termsandconditions"
                  onChange={onChangeHandle}
                />
                <span className="text-gray-700 text-sm font-bold">
                  By checking this box, I acknowledge that I have read and agree
                  to the{" "}
                  <a
                    href="/terms-and-conditions"
                    className="font-bold"
                    target="_blank"
                  >
                    Terms and Conditions
                  </a>{" "}
                  and{" "}
                  <a href="/bylaws" className="font-bold" target="_blank">
                    Bylaws
                  </a>
                  .
                </span>
              </label>
              {formErrors.termsandconditions && (
                <p className="text-red-500 text-xs italic pl-2">
                  {formErrors.termsandconditions}
                </p>
              )}
            </div>
            <p className="mb-2">
              To complete your registration, we need the following documents :
            </p>
            <UploadFile
              label={
                "CV (Curriculum Vitae): Please provide your professional background."
              }
              file={credentials.cv}
              onChangeFile={(value) => {
                credentials.cv = value;
              }}
            />
            <UploadFile
              label={
                "Personal Photo: A recent picture of yourself for identification."
              }
              file={credentials.personalPhoto}
              onChangeFile={(value) => {
                credentials.personalPhoto = value;
              }}
            />
            <UploadFile
              label={
                "License (PDF): If applicable, attach a relevant license for our records."
              }
              file={credentials.license}
              onChangeFile={(value) => {
                credentials.license = value;
              }}
            />
            <UploadFile
              label={
                "Passport or ID Copy (PDF): A copy of your identification document."
              }
              file={credentials.passportOrID}
              onChangeFile={(value) => {
                credentials.passportOrID = value;
              }}
            />
            {formErrors.documents && (
              <p className="text-red-500 text-xs italic pl-2 -mt-2 mb-4">
                {formErrors.documents}
              </p>
            )}
            <div className="flex items-center justify-between">
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                onClick={() => {
                  registerChecks();
                }}
                type="button"
              >
                Continue to payment
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};
