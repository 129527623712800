import React from "react";
import Axios from "axios";
import Cookies from "js-cookie";

import { emailValidation } from "../utils/inputValidations";
import Logo from "../../Assets/img/woscp.webp";
import { toast } from "react-toastify";

export const ForgotPassword = () => {
  const [credentials, setCredentials] = React.useState({
    email: "",
  });
  React.useEffect(() => {
    if (Cookies.get("woscp_user")) {
      window.location.href = `/`;
    }
  }, []);
  const [errorState, setErrorState] = React.useState(true);
  const handleForgotPassword = () => {
    if (errorState) {
      return;
    }
    Axios.post("https://server.woscp.org/api/users/forgot-password", {
      email: credentials.email,
    })
      .then((response) => {
        window.location.href = `/login`;
      })
      .catch((error) => {
        toast("Oops! Something went wrong.", { type: "error" });
      });
  };
  const onChangeHandle = (e) => {
    if (e.target.id === "email") {
      setErrorState(emailValidation(e.target.value) !== "");
      credentials.email = e.target.value;
    }
  };

  return (
    <section>
      <div className="container mx-auto py-8 ">
        <div className="flex flex-col lg:flex-row justify-center gap-6 items-center h-full lg:mt-[100px]">
          <div className="md:w-96 lg:w-72 xl:w-80">
            <img
              src={Logo}
              className="object-cover w-48 lg:w-96 mx-auto"
              alt="Logo"
            />
          </div>
          <div className="w-full xl:w-2/3 px-6 lg:px-0   mx-auto">
            <h1 className="text-xl lg:text-3xl font-bold text-center mb-2">
              Change or reset your password
            </h1>
            <div>
              <label className="block text-primary" htmlFor="form3Example3">
                Email address
              </label>
              <div className="mb-6 relative">
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="w-full px-4 py-2 text-lg text-primary border rounded-md focus:outline-none focus:ring focus:border-primary"
                  placeholder="Enter a valid email address"
                  onChange={onChangeHandle}
                  onBlur={onChangeHandle}
                />
                {errorState && credentials.email !== "" && (
                  <div className="absolute bg-[#bd1d1d82] pt-2 text-white px-2 bottom-[-23px] z-[-1] w-full transition-all duration-400 animate-errorAnimation rounded">
                    Invalid email!
                  </div>
                )}
              </div>
              <div className="text-center lg:text-lg mt-4 lg:mt-0 pt-2 relative">
                <button
                  type="button"
                  className="bg-gray-800 hover:bg-gray-500 disabled:hover:bg-gray-300 disabled:bg-gray-300 text-white px-8 py-3 text-lg rounded my-5"
                  disabled={errorState}
                  style={{ paddingLeft: "2.5rem", paddingRight: "2.5rem" }}
                  onClick={handleForgotPassword}
                >
                  Reset Password
                </button>
                <p className="text-sm mt-2 pt-1 mb-0">
                  Don't have an account?{" "}
                  <a
                    href="/become-a-member"
                    className="link-danger font-bold underline"
                  >
                    Become a member
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row items-center md:items-start justify-between sticky top-100 py-4 px-4 md:px-5 bg-primary text-white">
        <div className="mb-3 md:mb-0">
          Copyright © 2023. All rights reserved.
        </div>
      </div>
    </section>
  );
};
