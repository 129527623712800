import React from "react";

const Ethics = () => {
  return (
    <div>
      <div className="py-8">
        <div className="container mx-auto lg:my-28">
          <div className="mx-auto bg-white p-8 rounded-lg">
            <h1 className="text-3xl font-semibold mb-6">CODE OF ETHICS</h1>

            <ul className="list-disc list-inside">
              <li>
                All the activities of the association and its members will be
                carried out according to the laws of the countries they belong
                to, the laws where they carry out their professional activities
                and the moral rules in good faith and responsibility.
              </li>
              <li>
                The professional activities carried out will be started only on
                the basis of written contracts with clients, based on the law
                under which the activity takes place,
              </li>
              <li>
                Information obtained from investigations, close-protection or
                security services will be used only within the legal framework,
                with express specification in the contract with the client,
              </li>
              <li>
                All operatives taking part in the activities will be instructed
                on the obligation to abide by the law and the Code of Ethics,
              </li>
              <li>
                Legal authorities have priority in law enforcement activities,
                our covert surveillance, close-protection and security
                activities are carried out with priority given to the first
                mentioned organizations, the overlapping of our activities with
                those of the state is prohibited. In case our activities overlap
                with those of the judiciary, we will immediately withdraw.
              </li>
              <li>
                Professional activities related to covert surveillance,
                close-protection and security will be carried out on the
                principle of qualification/competence in these fields,
              </li>
              <li>
                Members of the association will comply with the law in all their
                activities and will not abuse the law by performing illegal
                actions according to national and international criminal
                legislation.{" "}
              </li>
              <li>
                Members of the association will not discriminate during the
                performance of activities and in the client-provider
                relationship. They will not refuse applications on the grounds
                of race, colour, religion, gender, disability, sexual preference
                or national origin,
              </li>
              <li>
                All the activities carried out will comply with the GDPR -
                General Data Protection Regulation as well as with the relevant
                provisions in the area of competence,
              </li>
              <li>
                All disputes arising from commercial relations between members
                will be settled by the Arbitration Committee set up at the level
                of the association. The decision will be final, irrevocable and
                enforceable.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ethics;
