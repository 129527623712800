import { useEffect } from "react";

import { SideMenu } from "./SideMenu";
import { UseAdminAuth } from "../../Components/utils/useAdminAuth";
import logo from "../../Assets/img/woscp.webp";

export const Dashboard = () => {
  const { loggedIn } = UseAdminAuth();

  useEffect(() => {
    const existingMetaTag = document.querySelector('meta[name="robots"]');

    if (!existingMetaTag) {
      const metaTag = document.createElement("meta");
      metaTag.name = "robots";
      metaTag.content = "noindex,nofollow";
      document.head.appendChild(metaTag);
    } else {
      existingMetaTag.content = "noindex,nofollow";
    }
  }, []);

  if (!loggedIn) {
    return <></>;
  }
  return (
    <div>
      <SideMenu />
      <div className="w-full md:w-[calc(100%-256px)] fixed right-0 overflow-auto">
        <section>
          <div className="container mx-auto py-8 ">
            <div className="flex flex-col lg:flex-row justify-center gap-6 items-center h-full lg:mt-[100px]">
              <div className="w-full xl:w-2/3 px-6 lg:px-0   mx-auto">
                <section className="bg-white dark:bg-gray-900">
                  <div className="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
                    <div className="mr-auto place-self-center lg:col-span-7">
                      <h1 className="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl dark:text-white">
                        W.O.S.C.P. - Admin Panel
                      </h1>
                      <p className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
                        Welcome to the World Organization of Surveillance and
                        Close-Protection Professionals Admin Panel. Here, you
                        have full control to edit content on the WOSCP website
                        effortlessly.
                      </p>
                    </div>
                    <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
                      <img src={logo} alt="woscp logo" />
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
