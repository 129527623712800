import React from "react";
import { dataReferralForm } from "../Components/data";
import CustomForm from "../Components/FormReferral";
import Axios from "axios";

import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { FindAnInvestigator } from "../Components/FindAnInvestigator";

const Referral = () => {
  const referralForm = useForm();

  const onSubmit = (d) => {
    Axios.post("https://server.woscp.org/api/forms/referral-form-send", {
      mailData: d,
    })
      .then((response) => {
        toast("Your form has been successfully submitted!", {
          type: "success",
        });
        referralForm.reset();
      })
      .catch(() => {
        toast("Oops! Something went wrong.", { type: "error" });
        referralForm.reset();
      });
  };

  return (
    <>
      <CustomForm
        dataForm={dataReferralForm}
        form={referralForm}
        onSubmit={onSubmit}
      />
      {/*<FindAnInvestigator />*/}
    </>
  );
};

export default Referral;
