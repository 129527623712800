import BecomeMember from "../Pages/BecomeMember";
import Contact from "../Pages/Contact";
import About from "../Pages/About";
import Home from "../Pages/Home";
import MemberArea from "../Pages/MemberArea";
import MembershipCards from "../Pages/MembershipCards";
import ShareYourAccomplishments from "../Pages/ShareYourAccomplishments";
import Consultant from "../Pages/Consultant";
import { ComingSoon } from "../Components/ComingSoon";
import Referral from "../Pages/Referral";
import Services from "../Pages/Services";
import Academy from "../Pages/Academy";
import { NewsList } from "../Pages/NewsList";

export const navBarLinks = [
  { link: "/", text: "Home", component: <Home /> },
  { link: "/about", text: "About", component: <About /> },
  { link: "/academy", text: "Academy", component: <Academy /> },

  {
    text: "Our Offer",
    children: [
      { link: "/services", text: "Services", component: <Services /> },
      { link: "/referral", text: "Referral", component: <Referral /> },
      { link: "/news", text: "News", component: <NewsList /> },
      {
        link: "https://geruinvestigation.co.uk/isa-books/all-books",
        text: "Books",
        component: <ComingSoon />,
      },
    ],
  },
  {
    text: "Member Area",
    children: [
      {
        link: "/become-a-member",
        text: "Become a member",
        component: <BecomeMember />,
      },
      // { link: "/member-area", text: "Member Area", component: <MemberArea /> },
      {
        link: "/membership-card",
        text: "Membership Cards",
        component: <MembershipCards />,
      },
      {
        link: "/share-your-accomplishments",
        text: "Share your accomplishments",
        component: <ShareYourAccomplishments />,
      },
      {
        link: "/consultant",
        text: "Consult a specialist",
        component: <Consultant />,
      },
    ],
  },
  { link: "/contact", text: "Contact", component: <Contact /> },
];
