import { FirmInformationCard } from "./FirmInformationsCard";
import { useEffect, useState } from "react";
import Axios from "axios";
import { toast } from "react-toastify";

export const FindAnInvestigator = () => {
  const [mobileFilter, setMobileFilter] = useState(false);

  const [allInvestigators, setAllInvestigators] = useState([]);
  const [investigators, setInvestigators] = useState(allInvestigators);
  const [countriesSelected, setCountriesSelected] = useState([]);
  const [citiesSelected, setCitiesSelected] = useState([]);
  const citiesArray = allInvestigators.map((item) => item.city);
  const uniqueCities = citiesArray.filter((value, index, self) => {
    return self.indexOf(value) === index;
  });
  const countriesArray = allInvestigators.map((item) => item.country);
  const uniqueCountries = countriesArray.filter((value, index, self) => {
    return self.indexOf(value) === index;
  });
  useEffect(() => {
    Axios.get("https://server.woscp.org/api/membership/get-all-members")
      .then((response) => {
        setInvestigators(response.data.userData);
        setAllInvestigators(response.data.userData);
        // window.location.href = `/membership-confirmation/${response.data.token}`;
      })
      .catch(() => {
        toast("Oops! Something went wrong.", { type: "error" });
      });
  }, []);

  const handleSearch = (searchTerm) => {
    if (searchTerm === "") {
      setInvestigators(allInvestigators);
    }
    const searchLowerCase = searchTerm.toLowerCase();

    const searchResults = allInvestigators?.filter(
      (item) =>
        //crapa daca sunt si individuali
        item.firm_name.toLowerCase().includes(searchLowerCase) ||
        item.first_name.toLowerCase().includes(searchLowerCase) ||
        item.last_name.toLowerCase().includes(searchLowerCase) ||
        (item.first_name + item.last_name)
          .toLowerCase()
          .includes(searchLowerCase),
    );
    setInvestigators(searchResults);
  };
  const handleCheckbox = (searchTerm, checked) => {
    if (!checked) {
      setInvestigators(allInvestigators);
    } else {
      const searchLowerCase = searchTerm.toLowerCase();

      const searchResults = allInvestigators.filter((item) =>
        item.expertise.toLowerCase().includes(searchLowerCase),
      );
      setInvestigators(searchResults);
    }
  };

  const countryOrCitiesNo = (checked, type, value) => {
    if (!checked) {
      if (type === "country") {
        setCountriesSelected(
          countriesSelected.filter((item) => item !== value),
        );
      } else {
        setCitiesSelected(citiesSelected.filter((item) => item !== value));
      }
    } else {
      if (type === "country") {
        setCountriesSelected([...countriesSelected, value]);
      } else {
        setCitiesSelected([...citiesSelected, value]);
      }
    }
  };

  useEffect(() => {
    if (countriesSelected.length > 0) {
      if (citiesSelected.length > 0) {
        setInvestigators([
          ...allInvestigators.filter((obj) =>
            countriesSelected.includes(obj.country),
          ),
          ...allInvestigators
            .filter((obj) => citiesSelected.includes(obj.city))
            .filter((obj) => !countriesSelected.includes(obj.country)),
        ]);
      } else {
        setInvestigators(
          allInvestigators.filter((obj) =>
            countriesSelected.includes(obj.country),
          ),
        );
      }
    }

    if (countriesSelected.length === 0) {
      if (citiesSelected.length === 0) {
        setInvestigators(allInvestigators);
      } else {
        setInvestigators(
          allInvestigators.filter((obj) => citiesSelected.includes(obj.city)),
        );
      }
    }
  }, [countriesSelected]);

  useEffect(() => {
    if (citiesSelected.length > 0) {
      if (countriesSelected.length > 0) {
        setInvestigators([
          ...allInvestigators.filter((obj) =>
            citiesSelected.includes(obj.city),
          ),
          ...allInvestigators
            .filter((obj) => countriesSelected.includes(obj.country))
            .filter((obj) => !citiesSelected.includes(obj.city)),
        ]);
      } else {
        setInvestigators(
          allInvestigators.filter((obj) => citiesSelected.includes(obj.city)),
        );
      }
    }
    if (citiesSelected.length === 0) {
      if (countriesSelected.length === 0) {
        setInvestigators(allInvestigators);
      } else {
        setInvestigators(
          allInvestigators.filter((obj) =>
            countriesSelected.includes(obj.country),
          ),
        );
      }
    }
  }, [citiesSelected]);

  return (
    <div className="flex flex-col items-center justify-center p-5 lg:p-8 w-full max-w-[1440px] mx-auto">
      <h1 className="text-3xl lg:text-4xl font-bold leading-9 text-black pb-6">
        Find an Investigator
      </h1>
      <div className="flex flex-col lg:flex-row w-full max-w-[1100px]">
        <div className="hidden lg:flex flex-col gap-4 min-w-[300px] border-r-solid border-r-gray-300 border-r-[1px] mr-2 p-2">
          <div className="flex flex-col ">
            <span className="text-xl font-bold">Search:</span>
            <input
              type={"text"}
              onChange={(e) => handleSearch(e.target.value)}
              placeholder={"Search an Investigator"}
              className="rounded border-solid border-gray-300 border-[1px] p-1"
            />
          </div>
          <div className="flex flex-col ">
            <span className="text-xl font-bold">Service:</span>
            <div className="flex items-center me-4 pl-2">
              <input
                id="inline-checkbox"
                type="checkbox"
                value=""
                onChange={(e) =>
                  handleCheckbox("Surveillance", e.target.checked)
                }
                className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-2"
              />
              <label
                htmlFor="inline-checkbox"
                className="ms-2 text-lg font-medium text-gray-900"
              >
                Surveillance
              </label>
            </div>
            <div className="flex items-center me-4 pl-2">
              <input
                id="inline-checkbox1"
                type="checkbox"
                value=""
                onChange={(e) =>
                  handleCheckbox("Close Protection", e.target.checked)
                }
                className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-2"
              />
              <label
                htmlFor="inline-checkbox1"
                className="ms-2 text-lg font-medium text-gray-900"
              >
                Close Protection
              </label>
            </div>
          </div>
          <div className="flex flex-col ">
            <span className="text-xl font-bold">Location:</span>
            <span className="text-md font-bold ml-1.5">Country:</span>
            <div className="ml-1.5 p-0.5 max-h-[250px] overflow-auto scrollable-container">
              {uniqueCountries.map((country, index) => (
                <div className="flex items-center me-4">
                  <input
                    id={`inline-checkbox-${index}-${country}`}
                    type="checkbox"
                    value=""
                    onChange={(e) =>
                      countryOrCitiesNo(e.target.checked, "country", country)
                    }
                    className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-2"
                  />
                  <label
                    htmlFor={`inline-checkbox-${index}-${country}`}
                    className="ms-2 text-lg font-medium text-gray-900"
                  >
                    {country}
                  </label>
                </div>
              ))}
            </div>
            <span className="text-md font-bold ml-1.5 mt-2">City:</span>
            <div className="ml-1.5 p-0.5 max-h-[250px] overflow-auto scrollable-container">
              {uniqueCities.map((city, index) => (
                <div className="flex items-center me-4">
                  <input
                    id={`inline-checkbox-${index}-${city}`}
                    type="checkbox"
                    value=""
                    onChange={(e) =>
                      countryOrCitiesNo(e.target.checked, "city", city)
                    }
                    className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-2"
                  />
                  <label
                    htmlFor={`inline-checkbox-${index}-${city}`}
                    className="ms-2 text-lg font-medium text-gray-900"
                  >
                    {city}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="flex lg:hidden flex-col ">
          <span className="text-xl font-bold">Search:</span>
          <input
            type={"text"}
            onChange={(e) => handleSearch(e.target.value)}
            placeholder={"Search an Investigator"}
            className="rounded border-solid border-gray-300 border-[1px] p-1"
          />
        </div>
        <button
          className="flex lg:hidden flex-row w-full gap-2 justify-center items-center border-[1px] border-solid border-gray-300 rounded p-1 my-2"
          onClick={() => setMobileFilter(!mobileFilter)}
        >
          <span className="text-xl font-bold">FILTERS</span>
          <svg
            width="30px"
            height="30px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4 5H7M14 5L20 5M14 5C14 3.89543 13.1046 3 12 3C10.8954 3 10 3.89543 10 5C10 6.10457 10.8954 7 12 7C13.1046 7 14 6.10457 14 5ZM10 12H16M16 12C16 13.1046 16.8954 14 18 14C19.1046 14 20 13.1046 20 12C20 10.8954 19.1046 10 18 10C16.8954 10 16 10.8954 16 12ZM4 12H7M11 19H20M6 17C7.10457 17 8 17.8954 8 19C8 20.1046 7.10457 21 6 21C4.89543 21 4 20.1046 4 19C4 17.8954 4.89543 17 6 17Z"
              stroke="#000000"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
          </svg>
          <svg
            width="30px"
            height="30px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={`rotate-${mobileFilter ? "180" : "0"}`}
          >
            <path
              d="M12 7L12 17L16 13M10 15L8 13"
              stroke="#000000"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        {mobileFilter && (
          <div className="bg-white">
            <div className="flex flex-col ">
              <span className="text-xl font-bold">Service:</span>
              <div className="flex items-center me-4 pl-2">
                <input
                  id="inline-checkbox"
                  type="checkbox"
                  value=""
                  onChange={(e) =>
                    handleCheckbox("Surveillance", e.target.checked)
                  }
                  className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-2"
                />
                <label
                  htmlFor="inline-checkbox"
                  className="ms-2 text-lg font-medium text-gray-900"
                >
                  Surveillance
                </label>
              </div>
              <div className="flex items-center me-4 pl-2">
                <input
                  id="inline-checkbox1"
                  type="checkbox"
                  value=""
                  onChange={(e) =>
                    handleCheckbox("Close Protection", e.target.checked)
                  }
                  className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-2"
                />
                <label
                  htmlFor="inline-checkbox"
                  className="ms-2 text-lg font-medium text-gray-900"
                >
                  Close Protection
                </label>
              </div>
            </div>
            <div className="flex flex-col ">
              <span className="text-xl font-bold">Location:</span>
              <span className="text-md font-bold ml-1.5">Country:</span>
              <div className="ml-1.5 p-0.5 max-h-[250px] overflow-auto scrollable-container">
                {uniqueCountries.map((country, index) => (
                  <div className="flex items-center bg-gray-100">
                    <input
                      id={`inline-checkbox-${index}-${country}`}
                      type="checkbox"
                      value=""
                      onChange={(e) =>
                        countryOrCitiesNo(e.target.checked, "country", country)
                      }
                      className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-2"
                    />
                    <label
                      htmlFor={`inline-checkbox-${index}-${country}`}
                      className="ms-2 text-lg font-medium text-gray-900"
                    >
                      {country}
                    </label>
                  </div>
                ))}
              </div>
              <span className="text-md font-bold ml-1.5 mt-2">City:</span>
              <div className="ml-1.5 p-0.5 max-h-[250px] overflow-auto scrollable-container">
                {uniqueCities.map((city, index) => (
                  <div className="flex items-center bg-gray-100">
                    <input
                      id={`inline-checkbox-${index}-${city}`}
                      type="checkbox"
                      value=""
                      onChange={(e) =>
                        countryOrCitiesNo(e.target.checked, "city", city)
                      }
                      className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-2"
                    />
                    <label
                      htmlFor={`inline-checkbox-${index}-${city}`}
                      className="ms-2 text-lg font-medium text-gray-900"
                    >
                      {city}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        <div className="flex flex-col relative justify-start items-center pr-0 lg:pr-5 pt-2 h-fit w-full lg:max-h-[1100px] lg:overflow-auto lg:scrollable-container">
          <span className="text-2xl text-left font-bold sticky pt-2 -top-2 z-10 -mt-2 w-full border-b-[1px] border-solid border-gray-300 bg-white">
            Results:
          </span>
          {investigators.map((firm) => (
            <FirmInformationCard {...firm} />
          ))}
        </div>
      </div>
    </div>
  );
};
