import React from "react";
import CustomForm from "../Components/FormReferral";
import { dataConsultantForm } from "../Components/data";
import { useForm } from "react-hook-form";
import Axios from "axios";
import { toast } from "react-toastify";
import { UseAuth } from "../Components/utils/useAuth";
import Cookies from "js-cookie";

const Consultant = () => {
  const consultantForm = useForm();
  const { loggedIn } = UseAuth();
  if (!loggedIn) {
    return <></>;
  }
  const onSubmit = (d) => {
    Axios.post("https://server.woscp.org/api/forms/consultant-form-send", {
      mailData: d,
      token: Cookies.get("woscp_user"),
    })
      .then(() => {
        toast("Your form has been successfully submitted!", {
          type: "success",
        });
        consultantForm.reset();
      })
      .catch(() => {
        toast("Oops! Something went wrong.", { type: "error" });
        consultantForm.reset();
      });
  };

  return (
    <CustomForm
      dataForm={dataConsultantForm}
      form={consultantForm}
      onSubmit={onSubmit}
    />
  );
};

export default Consultant;
