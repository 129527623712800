import { Tags } from "./Tags";
import React from "react";

export const NewsCard = (props) => {
  const dateObject = new Date(props.published_date);
  const day = dateObject.getDate().toString().padStart(2, "0");
  const month = (dateObject.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
  const year = dateObject.getFullYear();

  const formattedDate = `${day}/${month}/${year}`;

  const imageSrc = props.image
    ? `data:image/jpeg;base64,${btoa(
        new Uint8Array(props.image.data).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          ""
        )
      )}`
    : "";

  return (
    <>
      {props.isEditMode && (
        <div>
          <button
            type="button"
            className="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
            onClick={() =>props.onEdit({id:props.id,title:props.title,description:props.description,image:props.image,tags:props.tags})}
          >
            Edit
          </button>
          <button
            type="button"
            className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
            onClick={() => props.onDelete(props.id)}
          >
            Delete
          </button>
        </div>
      )}
      <a
        href={`/${props.link}`}
        className="mb-6 flex flex-wrap cursor-pointer "
      >
        <div className="mb-6 ml-auto w-full shrink-0 grow-0 basis-auto px-3 md:mb-0 md:w-3/12">
          <div
            className="relative overflow-hidden rounded-lg bg-cover bg-no-repeat shadow-lg dark:shadow-black/20 h-[200px] max-h-full "
            data-te-ripple-init=""
            data-te-ripple-color="light"
          >
            <img
              src={imageSrc}
              className="w-full h-[200px] max-h-full object-cover"
              alt={props.title}
            />
            <div className="absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100 bg-[hsla(0,0%,98.4%,.15)]" />
          </div>
        </div>

        <div className="mb-6 mr-auto w-full shrink-0 grow-0 basis-auto px-3 md:mb-0 md:w-9/12 xl:w-7/12 max-h-fit">
          <h5 className="mb-3 text-lg font-bold">{props.title}</h5>
          <Tags tagArr={JSON.parse(props.tags)} />
          <span className="mb-6 text-neutral-500 dark:text-neutral-300">
            <small>
              Published <u>{formattedDate}</u> by
              <p className="font-bold">{props.author}</p>
            </small>
          </span>
          <p
            className="text-neutral-500 dark:text-neutral-300"
            dangerouslySetInnerHTML={{
              __html: props.description?.slice(0, 200)+(props.description.length>200?"...":'')
            }}
          />
        </div>
      </a>
    </>
  );
};
