export const Tags = ({ tagArr }) => {
  const tagData = Array.isArray(tagArr)
    ? tagArr.map((tag) => {
        switch (tag) {
          case "surveillance":
            return (
              <div
                className="mb-3 flex items-center justify-center text-sm font-medium text-danger dark:text-danger-500 md:justify-start"
                key={tag}
              >
                <svg
                  viewBox="0 0 512 512"
                  version="1.1"
                  height={24}
                  width={24}
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns="http://www.w3.org/1999/xlink"
                  fill="#000000"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth="0" />
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <g id="SVGRepo_iconCarrier">
                    <title>surveillance</title>
                    <g
                      id="Page-1"
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <g
                        id="drop"
                        fill="#000000"
                        transform="translate(64.000000, 105.788405)"
                      >
                        <path
                          d="M44.1717837,-7.10542736e-14 L405.011659,98.4449647 L370.689,132.808 L394.489897,139.214538 L372.404005,221.640208 L301.059,202.523 L265.797494,237.829776 L177.65,213.627 L128.051615,299.534806 L128,299.510221 L128,299.544928 L42.6666667,299.531555 L42.6666667,342.211595 L3.62376795e-12,342.211595 L3.62376795e-12,214.211595 L42.6666667,214.211595 L42.6666667,256.864888 L103.402667,256.864888 L135.118,201.949 L3.12638804e-13,164.851341 L44.1717837,-7.10542736e-14 Z M74.286534,52.4390133 L52.1840777,134.926503 L253.136277,190.096636 L322.893293,120.245632 L74.286534,52.4390133 Z"
                          id="Combined-Shape"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
                Surveillance
              </div>
            );
          case "close-protection":
            return (
              <div
                className="mb-3 flex items-center justify-center text-sm font-medium text-danger dark:text-danger-500 md:justify-start"
                key={tag}
              >
                <svg
                  fill="#000000"
                  viewBox="0 0 32 32"
                  height={24}
                  width={24}
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth="0" />
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <g id="SVGRepo_iconCarrier">
                    <title />
                    <g data-name="Layer 12" id="Layer_12">
                      <path d="M25.41,7.09l-9-4a1,1,0,0,0-.82,0l-9,4A1,1,0,0,0,6,8v8.56A8.69,8.69,0,0,0,8.91,23l6.43,5.71a1,1,0,0,0,1.32,0L23.09,23A8.69,8.69,0,0,0,26,16.56V8A1,1,0,0,0,25.41,7.09ZM24,16.56a6.67,6.67,0,0,1-2.24,5L16,26.66l-5.76-5.12a6.67,6.67,0,0,1-2.24-5V8.65l8-3.56,8,3.56Z" />{" "}
                      <path d="M13,14.29a1,1,0,0,0-1.42,1.42l2.5,2.5a1,1,0,0,0,1.42,0l5-5A1,1,0,0,0,19,11.79l-4.29,4.3Z" />
                    </g>
                  </g>
                </svg>
                Close Protection
              </div>
            );
          case "member-accomplishment":
            return (
              <div
                className="mb-3 flex items-center justify-center text-sm font-medium text-danger dark:text-danger-500 md:justify-start"
                key={tag}
              >
                <svg
                  fill="#000000"
                  viewBox="0 0 32 32"
                  height={24}
                  width={30}
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth="0" />
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <g id="SVGRepo_iconCarrier">
                    <title>member-card</title>
                    <path d="M0 26.016q0 0.832 0.576 1.408t1.44 0.576h5.984v-1.984h-0.992q-0.416 0-0.704-0.288t-0.288-0.704 0.288-0.704 0.704-0.32h4q0.384 0 0.704 0.32t0.288 0.704-0.288 0.704-0.704 0.288h-0.992v1.984h12v-1.984h-1.024q-0.384 0-0.704-0.288t-0.288-0.704 0.288-0.704 0.704-0.32h4q0.416 0 0.704 0.32t0.32 0.704-0.32 0.704-0.704 0.288h-0.992v1.984h6.016q0.8 0 1.408-0.576t0.576-1.408v-20q0-0.832-0.576-1.408t-1.408-0.608h-28q-0.832 0-1.44 0.608t-0.576 1.408v20zM4 22.016v-14.016h14.016v14.016h-14.016zM7.040 20h7.936q-1.12-1.472-2.976-1.856v-0.32q0.896-0.352 1.44-1.088t0.576-1.728v-1.984q0-1.248-0.896-2.144t-2.112-0.864-2.144 0.864-0.864 2.144v1.984q0 0.96 0.544 1.728t1.472 1.088v0.32q-1.856 0.384-2.976 1.856zM20 22.016v-2.016h2.016v2.016h-2.016zM20 18.016v-2.016h10.016v2.016h-10.016zM20 14.016v-2.016h4v2.016h-4zM20 10.016v-2.016h6.016v2.016h-6.016zM26.016 14.016v-2.016h4v2.016h-4zM28 10.016v-2.016h2.016v2.016h-2.016z" />{" "}
                  </g>
                </svg>
                Member Accomplishment
              </div>
            );

          default:
            return <></>;
        }
      })
    : "Error";
  return <div className="flex flex-row gap-x-2 flex-wrap">{tagData}</div>;
};
