import React from "react";
import Container from "./container";

const Testimonials  = () => {
  return (
    <Container className={'pt-0'}>
      <div className="grid gap-4 lg:grid-cols-2 xl:grid-cols-3">
        <div className="lg:col-span-2 xl:col-auto bg-gray-100 rounded-[20px]">
          <div className="flex flex-col justify-start w-full h-full p-8 lg:p-8 rounded-[30px] bg-transparent bg-cover bg-opacity-75">
            <svg className="h-12 mx-auto text-gray-400 dark:text-gray-600" viewBox="0 0 24 27" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z"
                  fill="currentColor"/>
            </svg>
            <p className="text-lg lg:text-xl italic leading-normal text-center">
              "W.O.S.C.P. elevates our security business globally. Unmatched networking, strict ethics, and a game-changer for professionalism."
            </p>
          </div>
        </div>
        <div className="bg-gray-100 rounded-[20px]">
          <div className="flex flex-col justify-start w-full h-full p-8 lg:p-8 rounded-[30px] bg-transparent bg-cover bg-opacity-75">
            <svg className="h-12 mx-auto text-gray-400 dark:text-gray-600" viewBox="0 0 24 27" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z"
                  fill="currentColor"/>
            </svg>
            <p className="text-lg lg:text-xl italic leading-normal text-center ">
              "W.O.S.C.P. fosters industry collaboration with top-notch training and a unique platform. A valuable resource for any security company."
            </p>
          </div>
        </div>
        <div className="bg-gray-100 rounded-[20px]">
          <div className="flex flex-col justify-start w-full h-full bg-gray-100 rounded-[30px] p-8 lg:p-8 bg-transparent bg-cover bg-opacity-75">
            <svg className="h-12 mx-auto text-gray-400 dark:text-gray-600" viewBox="0 0 24 27" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z"
                  fill="currentColor"/>
            </svg>
            <p className="text-lg lg:text-xl italic leading-normal text-center">
              "W.O.S.C.P.'s global network is a tremendous asset. The emphasis on covert surveillance aligns perfectly with our operational focus. Excellent guidance and a level playing field."
            </p>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default Testimonials;