import React from "react";
import imageServices1 from "../Assets/img/services1.jpg";
import imageServices2 from "../Assets/img/services2.jpg";

const Services = () => {
  return (
    <div className="2xl:container 2xl:mx-auto lg:py-16 lg:px-20 md:py-12 md:px-6 py-9 px-8">
      <div className="flex flex-col lg:flex-row justify-between gap-8">
        <div className="w-full  flex flex-col justify-center">
          <h1 className="text-3xl lg:text-4xl font-bold leading-9 text-black pb-4">
            W.O.S.C.P. SERVICES
          </h1>
          <div className="space-y-6 h-fit mb-6 flex flex-col-reverse gap-3 lg:flex-row items-center">
            <div className="relative w-full border-l-2 border-dashed">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="absolute -top-0.5 z-10 -ml-3.5 h-7 w-7 rounded-full text-black"
              >
                <path
                  fillRule="evenodd"
                  d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                  clipRule="evenodd"
                />
              </svg>
              <div className="ml-6">
                <h4 className="font-bold text-gray-700">
                  WORLD ORGANIZATION OF SURVEILLANCE AND CLOSE-PROTECTION
                  PROFESSIONALS (WOSCP), the world's first trade association for
                  undercover investigators, is the most comprehensive
                  organisation to provide training and support in the areas of:
                </h4>
                <ul className="list-disc list-inside">
                  <li>
                    Training in covert-surveillance, counter-surveillance,
                    close-protection. Organization members have priority access
                    to organized trainings.
                  </li>
                  <li>
                    Books on covert surveillance and close-protection written by
                    our members can be found in the BOOKS chapter. Their prices
                    are discounted for our members depending on the type of
                    subscription they choose: gold, silver or bronze.
                  </li>
                  <li>
                    Publications, examples of complex cases presented by the
                    organization's members, explanations on different cases
                    presented by the organization's management are made
                    available only to those with a subscription free of charge
                  </li>
                  <li>
                    Online video courses can be found in the Online Course
                    chapter. The prices are reduced for our members depending on
                    the type of subscription they choose: gold, silver or
                    bronze.
                  </li>
                  <li>
                    Consultations concerning the participation of the management
                    in complex actions with the members of the association are
                    only accessible to members with a subscription, with a
                    discount according to the subscription type.
                  </li>
                  <li>
                    Online consultations with the President for the analysis and
                    preparation of high-risk actions are accessible only to
                    members with a subscription, at a discounted rate according
                    to the subscription type.
                  </li>
                  <li>
                    Organisation, training and operation of Covert surveillance
                    and close-protection structures starting with the selection,
                    training and start-up period of the department.
                  </li>
                </ul>
              </div>
            </div>
            <img
              className="h-full max-h-[300px] lg:max-h-full w-full lg:max-w-[40%] object-cover"
              src={imageServices1}
              alt={"services-1"}
            />
          </div>
          <div className="space-y-6 h-fit  flex flex-col-reverse gap-3 lg:flex-row items-center">
            <img
              className="h-full max-h-[300px] lg:max-h-full w-full lg:max-w-[40%] object-cover"
              src={imageServices2}
              alt={"services-2"}
            />
            <div className="relative w-full border-l-2 border-dashed lg:ml-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="absolute -top-0.5 z-10 -ml-3.5 h-7 w-7 rounded-full text-black"
              >
                <path
                  fillRule="evenodd"
                  d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                  clipRule="evenodd"
                />
              </svg>
              <div className="ml-6">
                <h4 className="font-bold text-gray-700">
                  Service fee tables are only available to organization members.
                </h4>
                <ul className="list-disc list-inside">
                  <li>
                    Elevate your skills in covert-surveillance,
                    counter-surveillance, and close-protection through exclusive
                    training opportunities. Unlock priority access to
                    meticulously organized training sessions by becoming a
                    valued member of our organization.
                  </li>
                  <li>
                    Immerse yourself in the world of covert surveillance and
                    close-protection with our curated collection of books,
                    written by our experienced members. As a member, enjoy
                    discounted prices on these insightful reads based on your
                    subscription level—choose between gold, silver, or bronze
                    for a personalized experience.
                  </li>
                  <li>
                    Dive deep into the intricacies of real-world scenarios with
                    our exclusive publications. Our members with subscription
                    enjoy complimentary access to examples of complex cases
                    presented by our accomplished members and management.
                    Subscribe today to stay informed and ahead of the curve.
                  </li>
                  <li>
                    Enhance your expertise with our online video courses,
                    tailored to meet the demands of modern surveillance and
                    protection techniques. Members receive reduced prices based
                    on their subscription level—gold, silver, or bronze. Join us
                    and access cutting-edge knowledge from the comfort of your
                    own space.
                  </li>
                  <li>
                    Delve into consultations with our management team on complex
                    actions, exclusively available to subscribed members. Enjoy
                    discounted rates based on your subscription type—gold,
                    silver, or bronze. Secure your spot and gain insights from
                    seasoned professionals in the field.
                  </li>
                  <li>
                    Take advantage of online consultations with our Chairman for
                    analysis and preparation of high-risk actions. Exclusively
                    accessible to subscribed members, benefit from discounted
                    rates based on your subscription type—gold, silver, or
                    bronze. Elevate your strategic planning with personalized
                    guidance from our esteemed leader.
                  </li>
                  <li>
                    From selection and training to operational deployment,
                    explore the comprehensive world of covert surveillance and
                    close-protection structures. Our organization guides you
                    through every step, ensuring you're well-equipped for
                    success.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <p className="font-normal text-base leading-6 text-black mb-3 mt-6">
            <a href={"/become-a-member"} className={"font-bold underline"}>
              Join us
            </a>{" "}
            today to unlock service fee tables and enjoy a wealth of exclusive
            benefits—available only to our esteemed organization members.
            Elevate your skills, expand your knowledge, and thrive in the world
            of covert operations with us.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Services;
