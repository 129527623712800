import React from "react";

export const SuccessEnrolling = () => {

    return (
        <section>
            <div className="relative py-16 2xl:pb-44 bg-blueGray-100 rounded-b-9xl overflow-hidden">
                <div className="relative container px-4 mx-auto z-10">
                    <div className="mx-auto w-fit">
                        <svg xmlns="http://www.w3.org/2000/svg"  fill={"#012465"} viewBox="0 0 50 50" width="250px" height="250px"><path d="M 25 2 C 12.317 2 2 12.317 2 25 C 2 37.683 12.317 48 25 48 C 37.683 48 48 37.683 48 25 C 48 20.44 46.660281 16.189328 44.363281 12.611328 L 42.994141 14.228516 C 44.889141 17.382516 46 21.06 46 25 C 46 36.579 36.579 46 25 46 C 13.421 46 4 36.579 4 25 C 4 13.421 13.421 4 25 4 C 30.443 4 35.393906 6.0997656 39.128906 9.5097656 L 40.4375 7.9648438 C 36.3525 4.2598437 30.935 2 25 2 z M 43.236328 7.7539062 L 23.914062 30.554688 L 15.78125 22.96875 L 14.417969 24.431641 L 24.083984 33.447266 L 44.763672 9.046875 L 43.236328 7.7539062 z"/></svg>
                    </div>
                    <h2 className="mb-2 xl:mb-3 text-2xl xl:text-2xl text-primary leading-normal font-heading font-medium text-center">Thank you for enrolling !</h2>
                    <p className="mb-5 xl:mb-5 text-lg text-primary max-w-[650px] mx-auto text-darkBlueGray-400 font-heading text-center">Your account is pending confirmation by the administrator. Upon account confirmation, during your initial login, you will be prompted to proceed with the membership payment. Once completed, you will gain access to the platform as a member.</p>
                    <p className="mb-5 xl:mb-5 text-md text-primary text-darkBlueGray-400 font-heading text-center">Please monitor your email for further updates.</p>
                </div>
            </div>
        </section>
    );
};
