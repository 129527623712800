import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import uuid from "react-uuid";

const DropDown = ({ text, children }) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropDown(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [dropdownRef]);

  const location = useLocation();

  return (
    <div
      className="relative inline-block text-center lg:text-left px-2 lg:border-l-[1px] lg:border-black py-2"
      ref={dropdownRef}
    >
      <div>
        <button
          onClick={() => setShowDropDown((prevState) => !prevState)}
          type="button"
          className={`text-black h-[24px] text-[15px] mt-[-2px] inline-flex rounded-md mx-auto items-center w-fit py-2 pr-4 pl-3 lg:border-0 lg:hover:text-white-700 lg:p-0 ${
            children.some((element) => element.link === location.pathname) &&
            "font-extrabold"
          }`}
          id="menu-button"
          aria-expanded="true"
          aria-haspopup="true"
          style={{
            textWrap: "nowrap",
          }}
        >
          {text}
          <svg
            className="-mr-1 h-4 w-4 text-black"
            viewBox="0 0 20 18"
            fill="black"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
      <div
        className={`${
          showDropDown ? "display" : "hidden"
        } absolute right-0 lg:-right-1/2 z-20 mt-2 w-full lg:w-56 origin-top-right lg:origin-top-left rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="menu-button"
        tabIndex="-1"
      >
        <div className="py-1 bg-gray-50" role="none">
          {children?.map((element) => {
            return (
              <Link
                key={uuid()}
                to={element.link}
                className="text-black block text-md px-4 py-2 hover:bg-gray-200"
                role="menuitem"
                tabIndex="-1"
                id="menu-item-0"
              >
                {element.text}
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default DropDown;
