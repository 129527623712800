import { useParams } from "react-router-dom";
import Axios from "axios";
import React from "react";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";

export const NewsDetails = () => {
  const { id } = useParams();

  const [articleData, setArticleData] = React.useState({
    id: "",
    title: "",
    description: "",
    author: "",
    tags: [],
    image: "",
    published_date: "",
  });

  React.useEffect(() => {
    Axios.get("https://server.woscp.org/api/news/get-news-article", {
      params: {
        article_id: id,
      },
    })
      .then((response) => {
        document.title = `News Article - ${response.data.article.title}`;
        setArticleData(response.data.article);
      })
      .catch(() => {
        toast("Oops! Something went wrong.", { type: "error" });
      });
  }, [id]);

  const dateObject = new Date(articleData.published_date);
  const day = dateObject.getDate().toString().padStart(2, "0");
  const month = (dateObject.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
  const year = dateObject.getFullYear();

  const imageSrc = articleData.image
    ? `data:image/jpeg;base64,${btoa(
        new Uint8Array(articleData.image.data).reduce(
          (data, byte) => data + String.fromCharCode(byte),
          "",
        ),
      )}`
    : "";
  const formattedDate = `${day}/${month}/${year}`;
  return (
    <div className="2xl:container 2xl:mx-auto lg:py-16 lg:px-20 md:py-12 md:px-6 py-9 px-8">
      <div className="flex flex-col lg:flex-row justify-between gap-8">
        <div className="w-full  flex flex-col justify-center">
          <h1 className="text-3xl lg:text-4xl font-bold leading-9 text-black pb-4">
            NEWS PAGE W.O.S.C.P.
          </h1>

          <div className="mb-12 flex flex-wrap justify-center">
            <div className="w-full shrink-0 grow-0 basis-auto px-3 lg:w-8/12">
              <div
                className="relative mb-6 overflow-hidden rounded-lg bg-cover bg-no-repeat shadow-lg dark:shadow-black/20"
                data-te-ripple-init=""
                data-te-ripple-color="light"
              >
                {!articleData.image ? (
                  <Skeleton count={1} className="h-48" />
                ) : (
                  <>
                    <img
                      alt={articleData.title}
                      src={imageSrc}
                      className="w-full max-h-[300px] object-contain"
                    />
                    <a href="#!">
                      <div className="absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100 bg-[hsla(0,0%,98.4%,.15)]" />
                    </a>
                  </>
                )}
              </div>
            </div>

            <div className="w-full shrink-0 grow-0 basis-auto px-3 lg:w-8/12">
              {!articleData.title ? (
                <Skeleton count={1} className="h-6 mb-6" />
              ) : (
                <>
                  <h5 className="mb-3 text-xl font-bold">
                    {articleData.title}
                  </h5>
                  <p className="mb-4 text-neutral-500 dark:text-neutral-300">
                    <small>
                      Published <u>{formattedDate}</u> by
                      <strong> {articleData.author}</strong>
                    </small>
                  </p>
                </>
              )}
              <p
                className="mb-6"
                dangerouslySetInnerHTML={{
                  __html: articleData.description || <Skeleton count={10} />,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
