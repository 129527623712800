import React from "react";
import Container from "./container";

const TextSectionHome = () => {
  return (
    <Container className="flex flex-wrap py-10 lg:px-0 ">
      <div className="flex flex-col lg:flex-row justify-between gap-8">
        <div className="w-full  flex flex-col justify-center">
          <h1 className="text-3xl lg:text-4xl font-bold leading-9 text-black pb-4">
            The purpose of our association
          </h1>
          <p className="py-5 text-xl leading-normal text-gray-500 lg:text-xl xl:text-2xl">
            The World Organization of Surveillance and Close-protection
            Professionals is made up of professionals from all over the world
            working, due to their surveillance knowledge, as:
          </p>
          <ul className="pl-5 space-y-3 text-xl text-gray-500 list-disc marker:text-gray-700">
            <li>
              Professional surveillance specialists who serve in covert
              operations
            </li>
            <li>Professional surveillance specialists in close protection</li>
            <li>
              Professional surveillance specialists who serve in special
              operations: extractions
            </li>
            <li>Professional surveillance investigators</li>
            <li> Professional surveillance specialists in security</li>
          </ul>
          <h2 className="text-2xl lg:text-2xl font-bold leading-9 text-black py-4 ">
            To serve professional surveillance operators from all the
            aforementioned fields. of W.O.S.C.P. membership allows them to
            achieve their goals in the following directions:
          </h2>
          <ul className="pl-5 space-y-3 text-xl text-gray-500 list-disc marker:text-gray-700">
            <li>Start a new career straight away</li>
            <li>
              Benefit from the most advanced training offered by the association
            </li>
            <li>
              Professional surveillance specialists who serve in special
              operations: extractions
            </li>
            <li>
              Guarantee that all W.O.S.C.P. members are vetted for membership
              suitability
            </li>
            <li>
              Create a strong network of colleagues to turn to in case of need
            </li>
            <li>
              Being able to serve clients from the private or governmental
              environment
            </li>
            <li>
              Feel secure in knowing that all W.O.S.C.P. members adhere to our
              strict code of ethics
            </li>
            <li>
              Optimize the possibility to serve customers much faster, through
              cooperation with other colleagues
            </li>
            <li>
              Locate a professional surveillance to obtain effective results
            </li>
          </ul>
        </div>
      </div>
    </Container>
  );
};

export default TextSectionHome;
