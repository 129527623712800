import React, { useState } from "react";
import { toast } from "react-toastify";
import uuid from "react-uuid";

export const UploadFile = (props) => {
  const [file, setFile] = useState();

  const isFileTypeValid = (file) => {
    const allowedTypes = [
      "image/jpeg",
      "image/png",
      "image/jpg",
      "application/pdf",
    ];
    return allowedTypes.includes(file.type);
  };

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];

      if (isFileTypeValid(selectedFile)) {
        setFile(selectedFile);

        const reader = new FileReader();

        reader.onload = (event) => {
          const content = event.target.result.split(",")[1];

          const fileInfo = {
            name: selectedFile.name,
            content: content,
          };

          props.onChangeFile(fileInfo);
        };

        reader.readAsDataURL(selectedFile);
      } else {
        // Clear file input and display an error message
        e.target.value = null;
        setFile(null);
        toast("Invalid file type. Please upload an image or PDF.", {
          type: "error",
        });
      }
    } else {
      setFile(null);
    }
  };

  return (
    <div className="mb-3">
      <label htmlFor="file" className="mr-3 font-bold">
        {props.label}
      </label>
      <input
        className={`${file ? "text-green-700" : "text-black"} bg-white font-bold`}
        id={`file-${uuid()}`}
        type="file"
        onChange={handleFileChange}
        accept="image/*,application/pdf"
      />
    </div>
  );
};
