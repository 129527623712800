import React from "react";
import { useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./footer";
import ArrowUp from "../Assets/img/up-arrow.svg"

const Wrapper = ({ children }) => {
  useEffect(() => {
    const progressWrap = document.querySelector(".progress-wrap");
    const progressPath = document.querySelector(".progress-wrap path");
    const pathLength = progressPath.getTotalLength();
    const offset = 50;

    if (progressWrap !== null) {
      progressPath.style.transition = progressPath.style.WebkitTransition =
          "none";
      progressPath.style.strokeDasharray = `${pathLength} ${pathLength}`;
      progressPath.style.strokeDashoffset = pathLength;
      progressPath.getBoundingClientRect();
      progressPath.style.transition = progressPath.style.WebkitTransition =
          "stroke-dashoffset 10ms linear";

      const handleScroll = () => {
        const scroll =
            document.body.scrollTop || document.documentElement.scrollTop;
        const height =
            document.documentElement.scrollHeight -
            document.documentElement.clientHeight;
        const progress = pathLength - (scroll * pathLength) / height;
        progressPath.style.strokeDashoffset = progress;
        const scrollElementPos =
            document.body.scrollTop || document.documentElement.scrollTop;
        if (scrollElementPos >= offset) {
          progressWrap.classList.add("active-progress");
        } else {
          progressWrap.classList.remove("active-progress");
        }
      };

      window.addEventListener("scroll", handleScroll);

      const handleClick = (e) => {
        e.preventDefault();
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      };

      progressWrap.addEventListener("click", handleClick);

      return () => {
        window.removeEventListener("scroll", handleScroll);
        progressWrap.removeEventListener("click", handleClick);
      };
    }
  }, []);

  return (
      <div className={'min-h-[100vh] flex flex-col justify-between'}>
        <Navbar/>
        {children}

        <Footer/>
        <div className="progress-wrap">
          <svg
              className="progress-circle svg-content"
              width="100%"
              height="100%"
              viewBox="-1 -1 102 102"
          >
            <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"/>
          </svg>
          <img
              src={ArrowUp}
              className="w-4 absolute top-[9px] left-[11px]"
              alt={'backToTopArrow'}
          />
        </div>
      </div>
  );
};

export default Wrapper;
