import React from "react";
import Logo from "../Assets/img/woscp.webp";
import { Link } from "react-router-dom";
import { navBarLinks } from "../Data/navBarData";
import uuid from "react-uuid";

export default function Footer() {
  const legal = [
    {
      name: "Terms and conditions",
      url: "/terms-and-conditions",
    },
    {
      name: "Privacy policy",
      url: "/privacy-policy",
    },
    {
      name: "Bylaws",
      url: "/bylaws",
    },
    {
      name: "Ethics",
      url: "/ethics",
    },
  ];

  return (
    <div className="border-gray-200 px-2 sm:px-4 py-2.5 pb-0 bg-white bg-cover bg-cover bg-footerTexture shadow-[rgba(0,_0,_0,_0.4)_0px_0px_24px]">
      <div className={`container py-4 mx-auto xl:px-0`}>
        <div className="flex flex-col w-full items-center px-3  lg:flex-row lg:items-start lg:px-0 justify-between gap-3 ">
          <div className="flex flex-row flex-wrap justify-between gap-x-14 gap-y-3 w-fit">
            <div className="hidden lg:block">
              <Link to="/">
                <img
                  className="mr-3 w-32 object-contain"
                  src={Logo}
                  alt="Logo"
                />
              </Link>
            </div>
            <div className="flex flex-col w-fit -mt-2 -ml-3 lg:ml-0">
              <p className="text-black px-2 py-1 font-bold underline underline-offset-4">
                Useful Links
              </p>
              {navBarLinks.slice(0, 4).map((item, index) =>
                item?.children ? (
                  item.children.map((child) => {
                    return (
                      <Link
                        key={uuid()}
                        to={child.link}
                        className="w-full px-2 py-1 min-w-max text-black rounded-md hover:text-gray-700 footer-links-animation focus:text-indigo-500 focus:bg-indigo-100 focus:outline-none "
                      >
                        {child.text}
                      </Link>
                    );
                  })
                ) : (
                  <Link
                    key={uuid()}
                    to={item.link}
                    className="w-full px-2 py-1 text-black min-w-max rounded-md hover:text-gray-700 footer-links-animation focus:text-indigo-500 focus:bg-indigo-100 focus:outline-none "
                  >
                    {item.text}
                  </Link>
                ),
              )}
            </div>
            <div className="flex flex-col w-fit -mt-2 -ml-3 lg:ml-0">
              <p className="text-black px-2 py-1 font-bold underline underline-offset-4">
                Member Links
              </p>
              {navBarLinks[4].children.map((item, index) =>
                item?.children ? (
                  item.children.map((child) => {
                    return (
                      <Link
                        key={uuid()}
                        to={child.link}
                        className="w-full px-2 py-1 text-black min-w-max rounded-md hover:text-gray-700 footer-links-animation focus:text-indigo-500 focus:bg-indigo-100 focus:outline-none "
                      >
                        {child.text}
                      </Link>
                    );
                  })
                ) : (
                  <Link
                    key={uuid()}
                    to={item.link}
                    className="w-full px-2 py-1 text-black min-w-max rounded-md hover:text-gray-700 footer-links-animation focus:text-indigo-500 focus:bg-indigo-100 focus:outline-none "
                  >
                    {item.text}
                  </Link>
                ),
              )}
            </div>
            <div className="flex flex-col w-fit -mt-2 -ml-3 lg:ml-0">
              <p className="text-black px-2 py-1 font-bold underline underline-offset-4">
                Support
              </p>
              <Link
                key={uuid()}
                to={navBarLinks[navBarLinks.length - 1].link}
                className="w-full px-2 py-1 text-black min-w-max rounded-md hover:text-gray-700 footer-links-animation focus:text-indigo-500 focus:bg-indigo-100 focus:outline-none "
              >
                {navBarLinks[navBarLinks.length - 1].text}
              </Link>
              {legal.map((item, index) => (
                <a
                  key={index}
                  href={item.url}
                  className="w-full px-2 py-1 min-w-max text-black rounded-md  hover:text-gray-700 footer-links-animation focus:text-indigo-500 focus:bg-indigo-100 focus:outline-none "
                >
                  {item.name}
                </a>
              ))}
            </div>
          </div>
          <div className="text-black text-center lg:text-left">
            <p className="text-black font-bold underline underline-offset-4">
              Follow us
            </p>
            <div className="flex mt-5 space-x-5 text-gray-400 ">
              <a
                href="https://twitter.com/WOSCP1"
                target="_blank"
                rel="noopener"
              >
                <span className="sr-only">Twitter</span>
                <Twitter />
              </a>
              <a
                href="https://www.facebook.com/worldorganizationofsurveillanceandc/"
                target="_blank"
                rel="noopener"
              >
                <span className="sr-only">Facebook</span>
                <Facebook />
              </a>
              <a
                href="https://www.linkedin.com/company/world-organization-of-surveillance-and-close-protection-professionals/"
                target="_blank"
                rel="noopener"
              >
                <span className="sr-only">Linkedin</span>
                <Linkedin />
              </a>
            </div>
          </div>
        </div>

        <div className="mt-12 text-sm text-center text-black font-bold">
          WORLD ORGANIZATION OF SURVEILLANCE AND CLOSE-PROTECTION PROFESSIONALS
          LTD
        </div>
        <div className="mt-1 text-sm text-center text-black ">
          Registered office address: 88 Church Road, Stockton-On-Tees, England,
          TS18 1TW
        </div>
        <div className="mt-1 text-sm text-center text-black ">
          <a href="mailto:office@woscp.org">office@woscp.org</a>
          <a href="tel:+40757047733"> +40757047733</a>
        </div>
        <div className="mt-4 text-sm text-center text-black ">
          Copyright © {new Date().getFullYear()}. Made by{" "}
          <a
            className={"font-bold"}
            href="https://redeclipse.ro/"
            target="_blank"
            rel="noopener"
          >
            RedEclipse.
          </a>
        </div>
      </div>
    </div>
  );
}

const Twitter = ({ size = 24 }) => (
  <>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      width={size}
      height={size}
      clipRule="evenodd"
      baseProfile="basic"
      className={"hover:fill-black"}
    >
      <polygon fill="gray" points="41,6 9.929,42 6.215,42 37.287,6" />
      <polygon
        fill="#fff"
        fillRule="evenodd"
        points="31.143,41 7.82,7 16.777,7 40.1,41"
        clipRule="evenodd"
      />
      <path
        fill="gray"
        d="M15.724,9l20.578,30h-4.106L11.618,9H15.724 M17.304,6H5.922l24.694,36h11.382L17.304,6L17.304,6z"
      />
    </svg>
  </>
);

const Facebook = ({ size = 24 }) => (
  <svg
    className={"hover:fill-black"}
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="gray"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 1C21.6569 1 23 2.34315 23 4V20C23 21.6569 21.6569 23 20 23H4C2.34315 23 1 21.6569 1 20V4C1 2.34315 2.34315 1 4 1H20ZM20 3C20.5523 3 21 3.44772 21 4V20C21 20.5523 20.5523 21 20 21H15V13.9999H17.0762C17.5066 13.9999 17.8887 13.7245 18.0249 13.3161L18.4679 11.9871C18.6298 11.5014 18.2683 10.9999 17.7564 10.9999H15V8.99992C15 8.49992 15.5 7.99992 16 7.99992H18C18.5523 7.99992 19 7.5522 19 6.99992V6.31393C19 5.99091 18.7937 5.7013 18.4813 5.61887C17.1705 5.27295 16 5.27295 16 5.27295C13.5 5.27295 12 6.99992 12 8.49992V10.9999H10C9.44772 10.9999 9 11.4476 9 11.9999V12.9999C9 13.5522 9.44771 13.9999 10 13.9999H12V21H4C3.44772 21 3 20.5523 3 20V4C3 3.44772 3.44772 3 4 3H20Z"
      fill="gray"
      className={"hover:fill-black"}
    />
  </svg>
);

const Linkedin = ({ size = 24 }) => (
  <svg
    className={"hover:fill-black"}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="gray"
  >
    <path d="M22.23 0H1.77C.8 0 0 .77 0 1.72v20.56C0 23.23.8 24 1.77 24h20.46c.98 0 1.77-.77 1.77-1.72V1.72C24 .77 23.2 0 22.23 0zM7.27 20.1H3.65V9.24h3.62V20.1zM5.47 7.76h-.03c-1.22 0-2-.83-2-1.87 0-1.06.8-1.87 2.05-1.87 1.24 0 2 .8 2.02 1.87 0 1.04-.78 1.87-2.05 1.87zM20.34 20.1h-3.63v-5.8c0-1.45-.52-2.45-1.83-2.45-1 0-1.6.67-1.87 1.32-.1.23-.11.55-.11.88v6.05H9.28s.05-9.82 0-10.84h3.63v1.54a3.6 3.6 0 0 1 3.26-1.8c2.39 0 4.18 1.56 4.18 4.89v6.21z" />
  </svg>
);
