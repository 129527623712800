import { useEffect } from "react";

import { SideMenu } from "../SideMenu";
import { FindAnInvestigator } from "../../../Components/FindAnInvestigator";
import { UseAdminAuth } from "../../../Components/utils/useAdminAuth";

export const UsersPage = () => {
  const { loggedIn } = UseAdminAuth();

  useEffect(() => {
    const existingMetaTag = document.querySelector('meta[name="robots"]');

    if (!existingMetaTag) {
      const metaTag = document.createElement("meta");
      metaTag.name = "robots";
      metaTag.content = "noindex,nofollow";
      document.head.appendChild(metaTag);
    } else {
      existingMetaTag.content = "noindex,nofollow";
    }
  }, []);

  if (!loggedIn) {
    return <></>;
  }
  return (
    <div className={`relative `}>
      <SideMenu />
      <div className={`w-full md:w-[calc(100%-256px)] absolute right-0 `}>
        <FindAnInvestigator />
      </div>
    </div>
  );
};
