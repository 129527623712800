import React, { useEffect, useRef, useState } from "react";
import uuid from "react-uuid";
import LinkNavBar from "./LinkNavBar";
import { Link } from "react-router-dom";
import Logo from "../Assets/img/woscp.webp";
import { navBarLinks } from "../Data/navBarData";
import DropDown from "./DropDown";
import Cookies from "js-cookie";
import axios from "axios";

const Navbar = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const dropdownRef = useRef(null);
  const hamburgerRef = useRef(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const token = Cookies.get("woscp_user");
    if (token) {
      axios
        .get("https://server.woscp.org/api/users/get-user", {
          params: {
            token: token,
          },
        })
        .then(() => {
          setUserLoggedIn(true);
        })
        .catch((error) => {
          setUserLoggedIn(false);
          Cookies.remove("woscp_user");
        });
    } else {
      setUserLoggedIn(false);
    }
  }, []);

  const toggleMenu = () => {
    setOpen(!open);
    setMobileMenuOpen(!mobileMenuOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !hamburgerRef.current.contains(event.target)
      ) {
        setMobileMenuOpen(false);
        setOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <nav className=" border-gray-200 px-2 sm:px-4 py-2.5  bg-white bg-opacity-90 hover:bg-opacity-100 bg-cover shadow-[rgba(0,_0,_0,_0.4)_0px_0px_24px] sticky top-0 z-[9999]">
      <div className="container flex flex-row gap-0 lg:gap-3 items-center justify-between  mx-auto overflow-hidden md:overflow-visible">
        <Link to="/" className="flex">
          <img className="mr-3 h-16 lg:h-28" src={Logo} alt="Logo" />
        </Link>
        <div className={"inline-flex lg:hidden"} ref={hamburgerRef}>
          <button
            className="text-black w-10 h-10 relative focus:outline-none bg-white scale-[1.5] mt-1"
            onClick={toggleMenu}
          >
            <span className="sr-only">Open main menu</span>
            <div className="block w-5 absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <span
                aria-hidden="true"
                className={`block absolute h-0.5 w-5 bg-current transform transition duration-500 ease-in-out ${
                  open ? "rotate-45 " : "-translate-y-1.5"
                }`}
              />
              <span
                aria-hidden="true"
                className={`block absolute h-0.5 w-5 bg-current transform transition duration-500 ease-in-out ${
                  open ? "opacity-0" : ""
                }`}
              />
              <span
                aria-hidden="true"
                className={`block absolute h-0.5 w-5 bg-current transform transition duration-500 ease-in-out ${
                  open ? "-rotate-45 " : "translate-y-1.5"
                }`}
              />
            </div>
          </button>
        </div>
        <div
          className={`${
            mobileMenuOpen ? "" : "hidden"
          } w-full lg:block lg:w-auto`}
          id="mobile-menu"
          ref={dropdownRef}
        >
          <ul className="flex flex-col mx-4 lg:flex-row lg:mt-0 lg:text-sm lg:font-medium text-textColorMenu">
            {navBarLinks.map((element) => {
              if (element.children) {
                return (
                  <DropDown
                    key={uuid()}
                    text={element.text}
                    children={element.children}
                  />
                );
              }
              return (
                <LinkNavBar
                  key={uuid()}
                  link={element.link}
                  text={element.text}
                />
              );
            })}
            {!!userLoggedIn ? (
              <div
                className={`px-2 lg:border-black flex items-center lg:border-l-[1px]`}
              >
                <button
                  key={uuid()}
                  className="block items-center py-2 pr-4 pl-3 w-fit text-[15px] min-w-max mx-auto text-black lg:w-100 text-nowrap lg:hover:bg-transparent lg:border-0 lg:hover:text-white-700 lg:p-0 border-animation cursor-pointer"
                  onClick={() => {
                    Cookies.remove("woscp_user");
                    window.location.reload();
                  }}
                >
                  Log Out
                </button>
              </div>
            ) : (
              <LinkNavBar key={uuid()} link="/login" text="Log In" />
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
