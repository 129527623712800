import Image from "../Assets/img/popupImage.jpg";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import Axios from "axios";
export const NewsPopup = ({ isEditMode = false }) => {
  const [open, setOpen] = useState(true);
  const [content, setContent] = useState({
    title: "",
    line2: "",
    line3: "",
    line4: "",
  });
  const token = Cookies.get("woscp_popup");
  const modalMockData = {
    title:
      "Unleash Your Potential: Elevate Your Career with Covert Mastery in 21 Days! 🚀",
    line2:
      "Forge Your Path: Transform Your Career with Elite Covert Training! 🕵‍♂✨",
    line3:
      "Empower Your Future: Elevate Your Skills with Exclusive Covert Mastery! 🌟",
    line4: "Unlock Success: Master Covert Operations in Just 21 Days! 🌐✨",
  };
  useEffect(() => {
    Axios.get(
      "https://server.woscp.org/api/content/engagement-modal-data",
    ).then((res) => {
      res.data.content[0].data !== "{}" &&
        setContent(JSON.parse(res.data.content[0].data));
    });
  }, []);

  if (isEditMode) {
    return (
      <div
        className={`flex items-end justify-center pt-4 px-4 pb-20 text-center sm:block sm:p-0 mb-12`}
      >
        <div
          className="inline-block w-[90%] sm:w-[60%] lg:w-[50%]  max-w-[800px] align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transition-all sm:align-middle relative "
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="block absolute top-0 right-0 pt-4 pr-4">
            <button
              type="button"
              data-behavior="cancel"
              onClick={() => {
                Cookies.set("woscp_popup", "shown", { expires: 0.0417 });
                setOpen(false);
              }}
              className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <span className="sr-only">Close</span>
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div className="flex flex-col w-full text-center">
            <img
              src={Image}
              alt="popup-image"
              className="max-h-[150px] sm:max-h-[200px] lg:max-h-[300px] object-cover w-full"
            />
            <div className="flex flex-col py-4 px-6 lg:p-4 gap-3">
              <h1 className="font-bold text-sm lg:text-2xl text-center my-0 lg:my-2">
                {content.title?.substring(0, 158)}
              </h1>
              <p
                className={
                  "font-bold text-sm lg:text-xl text-center my-0 lg:my-2"
                }
              >
                {content.line2?.substring(0, 158)}
              </p>
              <p
                className={
                  "text-center text-green-800 font-bold text-sm lg:text-xl"
                }
              >
                {content.line3?.substring(0, 158)}
              </p>
              <p
                className={
                  "font-bold text-sm lg:text-xl text-center my-0 lg:my-2"
                }
              >
                {content.line4?.substring(0, 158)}
              </p>
              <span className="flex flex-row w-full gap-3">
                <span
                  className="bg-green-800 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-1/2 flex justify-center items-center"
                  onClick={() => null}
                >
                  Become a member
                </span>
                <span
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-1/2 flex justify-center items-center"
                  onClick={() => null}
                >
                  Enroll now
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if ((!open || token) && !isEditMode) {
    return null;
  }

  return (
    <div id="news-popup" className="fixed z-[10000] inset-0 overflow-y-auto ">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75" />
        </div>
        <div
          className="inline-block w-[90%] sm:w-[60%] lg:w-[50%]  max-w-[800px] align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transition-all sm:align-middle absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="block absolute top-0 right-0 pt-4 pr-4">
            <button
              type="button"
              data-behavior="cancel"
              onClick={() => {
                Cookies.set("woscp_popup", "shown", { expires: 0.0417 });
                setOpen(false);
              }}
              className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <span className="sr-only">Close</span>
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div className="flex flex-col w-full text-center">
            <img
              src={Image}
              alt="popup-image"
              className="max-h-[150px] sm:max-h-[200px] lg:max-h-[300px] object-cover w-full"
            />
            <div className="flex flex-col py-4 px-6 lg:p-4 gap-3">
              <h1 className="font-bold text-sm lg:text-2xl text-center my-0 lg:my-2">
                {content.title.substring(0, 158)}
              </h1>
              <p
                className={
                  "font-bold text-sm lg:text-xl text-center my-0 lg:my-2"
                }
              >
                {content.line2.substring(0, 158)}
              </p>
              <p
                className={
                  "text-center text-green-800 font-bold text-sm lg:text-xl"
                }
              >
                {content.line3.substring(0, 158)}
              </p>
              <p
                className={
                  "font-bold text-sm lg:text-xl text-center my-0 lg:my-2"
                }
              >
                {content.line4.substring(0, 158)}
              </p>
              <span className="flex flex-row w-full gap-3">
                <a
                  className="bg-green-800 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-1/2 flex justify-center items-center"
                  href="/become-a-member"
                  onClick={() =>
                    Cookies.set("woscp_popup", "shown", { expires: 0.0417 })
                  }
                >
                  Become a member
                </a>
                <a
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-1/2 flex justify-center items-center"
                  href="/academy"
                  onClick={() =>
                    Cookies.set("woscp_popup", "shown", { expires: 0.0417 })
                  }
                >
                  Enroll now
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
