import React from "react";
import Axios from "axios";
import Cookies from "js-cookie";
import {
  passwordConfirmationValidation,
  passwordValidation,
} from "../utils/inputValidations";
import { useParams } from "react-router-dom";
import Logo from "../../Assets/img/woscp.webp";
import { toast } from "react-toastify";

export const PasswordRecovery = () => {
  const { token } = useParams();

  const [credentials, setCredentials] = React.useState({
    newPassword: "",
    confirmNewPassword: "",
  });
  React.useEffect(() => {
    if (Cookies.get("woscp_user")) {
      window.location.href = `/`;
    }
  }, []);

  const [errorState, setErrorState] = React.useState(false);
  const handleRecovery = () => {
    if (errorState) {
      return;
    }
    Axios.post(`https://server.woscp.org/api/users/reset-password/${token}`, {
      newPassword: credentials.newPassword,
    })
      .then((response) => {
        window.location.href = `/login`;
      })
      .catch((error) => {
        toast("Oops! Something went wrong.", { type: "error" });
      });
  };
  const [passwordError, setPasswordError] = React.useState("");
  const [passwordConfirmationError, setPasswordConfirmationError] =
    React.useState("");
  const onChangeHandle = (e) => {
    if (e.target.id === "newPassword") {
      setPasswordError(passwordValidation(e.target.value));
      setPasswordConfirmationError(
        passwordConfirmationValidation(
          e.target.value,
          credentials.confirmNewPassword,
        ),
      );
      credentials.newPassword = e.target.value;
    }
    if (e.target.id === "password-confirmation") {
      setPasswordConfirmationError(
        passwordConfirmationValidation(e.target.value, credentials.newPassword),
      );
      credentials.confirmNewPassword = e.target.value;
    }
  };

  React.useEffect(() => {
    if (passwordError === "" && passwordConfirmationError === "") {
      if (
        credentials.newPassword !== "" &&
        credentials.confirmNewPassword !== ""
      ) {
        setErrorState(false);
      } else {
        setErrorState(true);
      }
    } else {
      setErrorState(true);
    }
  }, [passwordError, passwordConfirmationError]);
  return (
    <section>
      <div className="container mx-auto py-8 ">
        <div className="flex flex-col lg:flex-row justify-center gap-6 items-center h-full lg:mt-[100px]">
          <div className="md:w-96 lg:w-72 xl:w-80">
            <img
              src={Logo}
              className="object-cover w-48 lg:w-96 mx-auto"
              alt="Logo"
            />
          </div>
          <div className="w-full xl:w-2/3 px-6 lg:px-0   mx-auto">
            <h1 className="text-xl lg:text-3xl font-bold text-center mb-2">
              Change or reset your password
            </h1>
            <div>
              <div className="mb-6 relative">
                <label className="block text-primary" htmlFor="form3Example4">
                  Your New Password
                </label>
                <input
                  type="password"
                  id="newPassword"
                  name="newPassword"
                  className="w-full px-4 py-2 text-lg text-primary border rounded-md focus:outline-none focus:ring focus:border-primary"
                  placeholder="Enter password"
                  onChange={onChangeHandle}
                  onBlur={onChangeHandle}
                />
                {passwordError && (
                  <div className="absolute bg-[#bd1d1d82] pt-2 text-white px-2 bottom-[-23px] z-[-1] w-full transition-all duration-400 animate-errorAnimation rounded">
                    {passwordError}
                  </div>
                )}
              </div>
              <div className="mb-6 relative">
                <label className="block text-primary" htmlFor="form3Example4">
                  Confirm Your New Password
                </label>
                <input
                  type="password"
                  id="password-confirmation"
                  name="newPassword"
                  className="w-full px-4 py-2 text-lg text-primary border rounded-md focus:outline-none focus:ring focus:border-primary"
                  placeholder="Enter password"
                  onChange={onChangeHandle}
                  onBlur={onChangeHandle}
                />
                {passwordConfirmationError && (
                  <div className="absolute bg-[#bd1d1d82] pt-2 text-white px-2 bottom-[-23px] z-[-1] w-full transition-all duration-400 animate-errorAnimation rounded">
                    {passwordConfirmationError}
                  </div>
                )}
              </div>
              <div className="text-center lg:text-lg mt-4 lg:mt-0 pt-2 relative">
                <button
                  type="button"
                  className="bg-gray-800 hover:bg-gray-500 disabled:hover:bg-gray-300 disabled:bg-gray-300 text-white px-8 py-3 text-lg rounded my-5"
                  disabled={errorState}
                  style={{ paddingLeft: "2.5rem", paddingRight: "2.5rem" }}
                  onClick={handleRecovery}
                >
                  Change Password
                </button>
                <p className="text-sm mt-2 pt-1 mb-0">
                  Don't have an account?{" "}
                  <a
                    href="/become-a-member"
                    className="link-danger font-bold underline"
                  >
                    Become a member
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row items-center md:items-start justify-between sticky top-100 py-4 px-4 md:px-5 bg-primary text-white">
        <div className="mb-3 md:mb-0">
          Copyright © 2023. All rights reserved.
        </div>
      </div>
    </section>
  );
};
