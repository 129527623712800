import { useParams } from "react-router-dom";
import { useEffect } from "react";
import Axios from "axios";
import { toast } from "react-toastify";

export const RejectedMember = () => {
  const { token } = useParams();

  useEffect(() => {
    Axios.post(
      `https://server.woscp.org/api/membership/reject-new-member/${token}`,
    )
      .then((response) => {
        toast(response.data.message);
      })
      .catch(() => {
        toast("Oops! Something went wrong.", { type: "error" });
      });
  }, []);

  return <div>You have successfully rejected the following member</div>;
};
