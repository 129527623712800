import { Route, Routes, useLocation } from "react-router-dom";
import Wrapper from "./Components/Wrapper";
import { navBarLinks } from "./Data/navBarData";
import uuid from "react-uuid";
import { AcceptedMember } from "./Components/auth/AcceptedMember";
import { RejectedMember } from "./Components/auth/RejectedMember";
import { SuccessEnrolling } from "./Pages/SuccessEnrolling";
import { Login } from "./Components/auth/Login";
import { MembershipConfirmation } from "./Pages/MembershipConfirmation";
import { useEffect } from "react";
import { TermsAndConditions } from "./Pages/Legal/TermsAndConditions";
import { PrivacyPolicy } from "./Pages/Legal/PrivacyPolicy";
import CookieConsent from "react-cookie-consent";
import { Bylaws } from "./Pages/Legal/Bylaws";
import { IndividualSignUp } from "./Components/auth/IndividualSignUp";
import { MemberSignUp } from "./Components/auth/MemberSignUp";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Ethics from "./Pages/Ethics";
import { NewsPopup } from "./Components/NewsPopup";
import { NewsDetails } from "./Pages/NewsDetails";
import { PageNotFound } from "./Pages/PageNotFound";
import "react-loading-skeleton/dist/skeleton.css";
import { Dashboard } from "./Pages/Admin/Dashboard";
import { IndividualSignUpDEV } from "./Components/auth/IndividualSignUpDEV";
import { FirmSignUpDEV } from "./Components/auth/FirmSignUpDEV";
import { ForgotPassword } from "./Components/auth/ForgotPassword";
import { PasswordRecovery } from "./Components/auth/PasswordRecovery";
import { EditEngagementModalPage } from "./Pages/Admin/DashboardPages/EditEngagementModalPage";
import { EditAcademyFormPage } from "./Pages/Admin/DashboardPages/EditAcademyFormPage";
import { EditNewsPage } from "./Pages/Admin/DashboardPages/EditNewsPage";
import { UsersPage } from "./Pages/Admin/DashboardPages/UsersPage";
import { AdminLogin } from "./Pages/Admin/AdminLogin";

export const membershipPlans = [
  {
    id: "plan-NKCQJDNUII9ZI",
    name: "Individual membership - GOLD Plan",
    quantity: 1,
    unit_amount: {
      currency_code: "GBP",
      value: 175,
    },
  },
  {
    id: "plan-Y6G3F68QIGCPN",
    name: "Individual membership - SILVER Plan",
    quantity: 1,
    unit_amount: {
      currency_code: "GBP",
      value: 150,
    },
  },
  {
    id: "plan-3IZ308ALZ07EA",
    name: "Individual membership - BRONZE Plan",
    quantity: 1,
    unit_amount: {
      currency_code: "GBP",
      value: 125,
    },
  },
  {
    id: "plan-V5IWA6YKRRQTJ",
    name: "Individual membership - BASE Plan",
    quantity: 1,
    unit_amount: {
      currency_code: "GBP",
      value: 100,
    },
  },
  {
    id: "plan-V5IWA6YKRRXSA",
    name: "Individual membership - Fellow of the Association Plan",
    quantity: 1,
    unit_amount: {
      currency_code: "GBP",
      value: 125,
    },
  },
  {
    id: "plan-EJ4DDOQSM4E9Y",
    name: "Corporate membership - GOLD Plan",
    quantity: 1,
    unit_amount: {
      currency_code: "GBP",
      value: 250,
    },
  },
  {
    id: "plan-MN1LOY5KTKBT9",
    name: "Corporate membership - SILVER Plan",
    quantity: 1,
    unit_amount: {
      currency_code: "GBP",
      value: 225,
    },
  },
  {
    id: "plan-8SFRHK2KSO0YE",
    name: "Corporate membership - BRONZE Plan",
    quantity: 1,
    unit_amount: {
      currency_code: "GBP",
      value: 200,
    },
  },
  {
    id: "plan-TA52AMWB29PM6",
    name: "Corporate membership - BASE Plan",
    quantity: 1,
    unit_amount: {
      currency_code: "GBP",
      value: 175,
    },
  },
  {
    id: "plan-7U8CHAW2215LP",
    name: "Student membership - Base Plan",
    quantity: 1,
    unit_amount: {
      currency_code: "GBP",
      value: 70,
    },
  },
  {
    id: "plan-7U8CHAW5515LP",
    name: "Student membership - Bronze Plan",
    quantity: 1,
    unit_amount: {
      currency_code: "GBP",
      value: 75,
    },
  },
  {
    id: "plan-7U8CHA44215LP",
    name: "Student membership - Silver Plan",
    quantity: 1,
    unit_amount: {
      currency_code: "GBP",
      value: 80,
    },
  },
  {
    id: "plan-7U8CHA34215LP",
    name: "Student membership - Gold Plan",
    quantity: 1,
    unit_amount: {
      currency_code: "GBP",
      value: 85,
    },
  },
];

function App() {
  let location = useLocation();
  let pageTitle = "World Organisation of Surveillance and Close Protection";

  const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  };

  useEffect(() => {
    switch (location.pathname) {
      case "/login":
        pageTitle = "Login - W.O.S.C.P.";
        break;
      case "/forgot-password":
        pageTitle = "Forgot Password - W.O.S.C.P.";
        break;
      case "/become-a-member":
        pageTitle = "Become a member - W.O.S.C.P.";
        break;
      case "/become-a-member/individual-enroll":
        pageTitle = "Become a member - W.O.S.C.P.";
        break;
      case "/become-a-member/firm-enroll":
        pageTitle = "Become a member - W.O.S.C.P.";
        break;
      case "/ethics":
        pageTitle = "Code of ethics - W.O.S.C.P.";
        break;
      case "/bylaws":
        pageTitle = "Bylaws - W.O.S.C.P.";
        break;
      case "/referral":
        pageTitle = "Referral - W.O.S.C.P.";
        break;
      case "/academy":
        pageTitle = "Academy - W.O.S.C.P.";
        break;
      case "/services":
        pageTitle = "Services - W.O.S.C.P.";
        break;
      case "/news":
        pageTitle = "News - W.O.S.C.P.";
        break;
      case "/about":
        pageTitle = "About - W.O.S.C.P.";
        break;
      case "/member-area":
        pageTitle = "Member Area - W.O.S.C.P.";
        break;
      case "/membership-card":
        pageTitle = "Membership Cards - W.O.S.C.P.";
        break;
      case "/share-your-accomplishments":
        pageTitle = "Share your accomplishments - W.O.S.C.P.";
        break;
      case "/consultant":
        pageTitle = "Consultant - W.O.S.C.P.";
        break;
      case "/contact":
        pageTitle = "Contact - W.O.S.C.P.";
        break;
      default:
        pageTitle =
          "World Organization of Surveillance and Close-protection Professionals\n";
        break;
    }
    document.title = pageTitle;
  }, [location]);
  return (
    <div>
      <NewsPopup />
      <ToastContainer style={{ zIndex: "99999" }} limit={2} />
      <CookieConsent
        location="bottom"
        buttonText="I understand"
        cookieName="consent"
        style={{ background: "#545a5d" }}
        buttonStyle={{
          color: "white",
          background: "#1F2937",
          fontSize: "15px",
        }}
        expires={150}
      >
        This website uses cookies to enhance the user experience.
        <span style={{ fontSize: "12px", marginLeft: "5px" }}>
          Further use of this website implies your consent.
        </span>
      </CookieConsent>
      <ScrollToTop />
      <Routes>
        {navBarLinks.map(({ link, component, children }) =>
          children ? (
            children?.map((el) => {
              return (
                <Route
                  key={uuid()}
                  path={el.link}
                  element={<Wrapper children={el.component} />}
                />
              );
            })
          ) : (
            <Route
              key={uuid()}
              path={link}
              element={<Wrapper children={component} />}
            />
          )
        )}
        <Route
          key={uuid()}
          path="/become-a-member/individual-enroll"
          element={<Wrapper children={<IndividualSignUp />} />}
        />
        <Route
          key={uuid()}
          path="/login"
          element={<Wrapper children={<Login />} />}
        />
        <Route
          key={uuid()}
          path="/forgot-password"
          element={<Wrapper children={<ForgotPassword />} />}
        />
        <Route
          key={uuid()}
          path="/reset/:token"
          element={<Wrapper children={<PasswordRecovery />} />}
        />
        <Route
          key={uuid()}
          path="/news/:id"
          element={<Wrapper children={<NewsDetails />} />}
        />
        <Route
          key={uuid()}
          path="/become-a-member/firm-enroll"
          element={<Wrapper children={<MemberSignUp />} />}
        />
        <Route
          key={uuid()}
          path="/accept-new-member/:token"
          element={<Wrapper children={<AcceptedMember />} />}
        />
        <Route
          key={uuid()}
          path="/reject-new-member/:token"
          element={<Wrapper children={<RejectedMember />} />}
        />
        <Route
          key={uuid()}
          path="/success-enrolling"
          element={<Wrapper children={<SuccessEnrolling />} />}
        />
        <Route
          key={uuid()}
          path="/membership-confirmation/:token"
          element={<Wrapper children={<MembershipConfirmation />} />}
        />
        <Route
          key={uuid()}
          path="terms-and-conditions"
          element={<Wrapper children={<TermsAndConditions />} />}
        />
        <Route
          key={uuid()}
          path="privacy-policy"
          element={<Wrapper children={<PrivacyPolicy />} />}
        />
        <Route
          key={uuid()}
          path="bylaws"
          element={<Wrapper children={<Bylaws />} />}
        />
        <Route
          key={uuid()}
          path="ethics"
          element={<Wrapper children={<Ethics />} />}
        />
        <Route key={uuid()} path="admin/dashboard" element={<Dashboard />} />
        <Route
          key={uuid()}
          path="admin/dashboard/login"
          element={<AdminLogin />}
        />
        <Route
          key={uuid()}
          path="development/individual"
          element={<IndividualSignUpDEV />}
        />
        <Route
          key={uuid()}
          path="development/firm"
          element={<FirmSignUpDEV />}
        />
        <Route
          key={uuid()}
          path="admin/dashboard/edit-engagement-modal"
          element={<EditEngagementModalPage />}
        />
        <Route
          key={uuid()}
          path="admin/dashboard/edit-academy-form"
          element={<EditAcademyFormPage />}
        />
        <Route
          key={uuid()}
          path="admin/dashboard/edit-news-section"
          element={<EditNewsPage />}
        />
        <Route
          key={uuid()}
          path="admin/dashboard/users"
          element={<UsersPage />}
        />
        <Route
          key={uuid()}
          path="/*"
          element={<Wrapper children={<PageNotFound />} />}
        />
      </Routes>
    </div>
  );
}

export default App;
