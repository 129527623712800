import { useParams } from "react-router-dom";
import Axios from "axios";
import { useEffect } from "react";
import { toast } from "react-toastify";

export const AcceptedMember = () => {
  const { token } = useParams();

  useEffect(() => {
    Axios.post(`https://server.woscp.org/api/accept-new-member/${token}`)
      .then((response) => {
        toast("You have successfully accepted the member", { type: "success" });
      })
      .catch(() => {
        toast("Oops! Something went wrong.", { type: "error" });
      });
  }, []);

  return <div>You have successfully accepted the member</div>;
};
