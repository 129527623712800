import {
  FUNDING,
  PayPalButtons,
  PayPalScriptProvider,
} from "@paypal/react-paypal-js";
import Axios from "axios";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { membershipPlans } from "../App";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

export const MembershipConfirmation = () => {
  const [currentUserPlan, setCurrentUserPlan] = useState({});

  const { token } = useParams();

  const FUNDING_SOURCES = [FUNDING.PAYPAL, FUNDING.CARD];

  const initialOptions = {
    "client-id":
      "AQCAkp50iqckhe4_uce55IZdS1g34lBz9LHufWre-3Ac1etxPTy1Eh4m-KY1G6w-7suIVDI-uz94PHsx",
    "enable-funding": "paylater,venmo",
    currency: "GBP",
  };

  useEffect(() => {
    Axios.get("https://server.woscp.org/api/membership/get-user-membership", {
      params: {
        token: token,
      },
    })
      .then((response) => {
        membershipPlans.forEach((plan) => {
          if (plan.id === response.data.membershipType) {
            setCurrentUserPlan(plan);
          }
        });
      })
      .catch(() => {
        window.location.href = "/";
        toast("Oops! Something went wrong.", { type: "error" });
      });
  }, [token]);

  let shippingCost = 0;

  const handleMembershipConfirmation = () => {
    Axios.post(
      "https://server.woscp.org/api/membership/membership-confirmation",
      {
        token: token,
      },
    )
      .then((response) => {
        Cookies.set("woscp_user", token, { expires: 0.3 });
        window.location.href = `/`;
      })
      .catch(() => {
        toast("Oops! Something went wrong.", { type: "error" });
      });
  };

  return (
    <div className="relative py-16 2xl:pb-44 bg-blueGray-100 max-w-6xl mx-auto rounded-b-9xl overflow-hidden">
      <div className="relative container px-4 mx-auto z-10">
        <h2 className="mb-2 xl:mb-3 text-2xl xl:text-2xl text-primary leading-normal font-heading font-medium text-center">
          Thank you for choosing to become a member of our community. To unlock
          the full potential of our platform and access exclusive features,
          let's get your membership sorted.
        </h2>
        <h2 className="mb-4 text-2xl text-black font-bold text-center">
          {currentUserPlan.name} - £{currentUserPlan.unit_amount.value}
        </h2>
        <div className="flex flex-col gap-5 items-center payment-container">
          {Object.keys(currentUserPlan).length === 0 ? (
            <p className="mb-2 xl:mb-3 text-xl xl:text-xl text-gray-400 leading-normal font-heading font-medium text-center">
              Something went wrong. Please try again later.
            </p>
          ) : (
            <PayPalScriptProvider options={initialOptions}>
              {FUNDING_SOURCES.map((fundingSource) => {
                return (
                  <PayPalButtons
                    fundingSource={fundingSource}
                    key={fundingSource}
                    style={{
                      layout: "vertical",
                      shape: "rect",
                      color: fundingSource === FUNDING.PAYLATER ? "gold" : "",
                    }}
                    createOrder={(data, actions) => {
                      return actions.order.create({
                        purchase_units: [
                          {
                            amount: {
                              currency_code: "GBP",
                              value:
                                currentUserPlan.unit_amount.value +
                                shippingCost,
                              breakdown: {
                                item_total: {
                                  currency_code: "GBP",
                                  value: currentUserPlan.unit_amount.value,
                                },
                                shipping: {
                                  currency_code: "GBP",
                                  value: shippingCost,
                                },
                              },
                            },
                            items: [
                              {
                                name: `${currentUserPlan.name}`,
                                quantity: currentUserPlan.quantity,
                                unit_amount: {
                                  currency_code:
                                    currentUserPlan.unit_amount.currency_code,
                                  value: currentUserPlan.unit_amount.value,
                                },
                              },
                            ],
                          },
                        ],
                      });
                    }}
                    onApprove={(data, actions) => {
                      return actions.order.capture().then(function (details) {
                        handleMembershipConfirmation();
                      });
                    }}
                  />
                );
              })}
            </PayPalScriptProvider>
          )}
        </div>
      </div>
    </div>
  );
};
