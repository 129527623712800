import React from "react";
import Axios from "axios";
import { toast } from "react-toastify";

import { dataAcademyForm } from "../Components/data";
import CustomForm from "../Components/FormReferral";
import { useForm } from "react-hook-form";
import ISO1 from "../Assets/img/iso/ISO9001.png";
import ISO2 from "../Assets/img/iso/ISO27001.png";
import { useEffect, useState } from "react";

const Academy = () => {
  const [content, setContent] = useState({ course: [], date: [] });
  const academyForm = useForm();

  useEffect(() => {
    Axios.get("https://server.woscp.org/api/content/engagement-academy-data").then(
      (res) => {
        if (res.data.content[0].data !== "{}") {
          const rawData = JSON.parse(res.data.content[0].data);

          // Transform the data structure
          const transformedData = {};
          Object.keys(rawData).forEach((key) => {
            transformedData[key] = rawData[key].map((item) => item.value);
          });

          setContent(transformedData);
        }
      }
    );
  }, []);

  const onSubmit = (d) => {
    Axios.post("https://server.woscp.org/api/forms/academy-form-send", {
      mailData: d,
    })
      .then(() => {
        toast("Your form has been successfully submitted!", {
          type: "success",
        });
        academyForm.reset();
      })
      .catch(() => {
        toast("Oops! Something went wrong.", { type: "error" });
        academyForm.reset();
      });
  };
  return (
    <>
      <div className="2xl:container 2xl:mx-auto lg:py-16 lg:px-20 md:py-12 md:px-6 py-9 px-8">
        <div className="flex flex-col lg:flex-row justify-between gap-8">
          <div className="w-full  flex flex-col justify-center">
            <h1 className="text-3xl lg:text-4xl font-bold leading-9 text-black pb-4">
              ACADEMY W.O.S.C.P.
            </h1>
            <p className="font-normal text-base leading-6 text-black mb-3">
              The <strong>W.O.S.C.P. Academy has</strong> been established to
              provide training at the highest level in the fields of covert
              surveillance, investigations, forensics and intelligence analysis,
              all combined in a comprehensive covert surveillance master course.
            </p>
            <p className="font-normal text-base leading-6 text-black mb-3">
              As regards close-protection, the course will be composed of the
              above-mentioned subjects applied to close-protection work in an
              innovative way, a method that will be presented in a specially
              written and copyrighted course for our organization.
            </p>
            <p className="font-normal text-base leading-6 text-black mb-3">
              <strong>W.O.S.C.P. Academy</strong> will organize two
              internationally recognized courses according to{" "}
              <strong>ISO 9001</strong> and <strong>ISO 27001</strong>. These
              courses are aimed at investigators, corporate surveillance and
              close-protection operators worldwide and are not accredited for
              each country, but for international use by all those who opt for
              our courses.
            </p>
            <div className="grid grid-cols-2 gap-2 w-fit mx-auto mb-3">
              <div>
                <img
                  className="h-auto w-[200px] max-w-full rounded-lg"
                  src={ISO1}
                  alt="ISO Badge 1"
                />
              </div>
              <div>
                <img
                  className="h-auto w-[200px] max-w-full rounded-lg"
                  src={ISO2}
                  alt="ISO Badge 2"
                />
              </div>
            </div>
            <p className="font-normal text-base leading-6 text-black mb-3">
              After the first series of courses, we will notify corporations
              worldwide who have vacancies to request data for our
              recommendation of our trainees in the area requested by the
              companies. Our diploma will be your business card in your
              relations with large employers.
            </p>
            <p className="font-normal text-base leading-6 text-black mb-3">
              The courses will be organized and taught by the chairman, Colonel
              (retired) Doru Geru and his own board in two locations established
              in the United Kingdom - and Europe in the Romanian base located in
              Galati and will comprise:
              <ul className="pl-5 space-y-3 text-black list-disc marker:text-black my-5">
                <li>Covert surveillance master course </li>
                <li>Executive close-protection integrated master course </li>
              </ul>
            </p>
            <p className="font-normal text-base leading-6 text-black mb-3">
              <strong> Variant 1</strong>: On site - 21 consecutive calendar
              days (includes practice )
            </p>
            <p className="font-normal text-base leading-6 text-black mb-3">
              <strong> Variant 2</strong>: Online - 16 consecutive calendar days
              and 5 days of on-site practice.
            </p>
            <p className="font-normal text-base leading-6 text-black mb-3">
              Applicants do not have to be specialised in any of the mentioned
              fields, but must fulfil the following conditions for which they
              will sign the application form:
              <ul className="pl-5 space-y-3 text-black list-disc marker:text-black my-5">
                <li> To have no criminal record,</li>
                <li> To be in good physical condition </li>
                <li>
                  To have good eyesight or have corrective glasses that allow
                  normal distance vision{" "}
                </li>
                <li>
                  Not to be in evidence of mental or transmissible diseases.
                </li>
              </ul>
            </p>
            <p className="font-normal text-base leading-6 text-black ">
              Course materials, additional reading and periodic tests will be
              provided in the course fee. The entire logistical basis will be
              provided by W.O.S.C.P.
            </p>
          </div>
        </div>
      </div>
      <CustomForm
        dataForm={{
          ...dataAcademyForm,
          form: [
            ...dataAcademyForm.form,
            {
              label: "Course",
              type: "dropdown",
              options: content.course,
              name: "course",
            },
            {
              label: "Date",
              type: "dropdown",
              options: content.date,
              name: "date",
            },
          ],
        }}
        form={academyForm}
        onSubmit={onSubmit}
      />
    </>
  );
};

export default Academy;
