import { useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";

export const UseAuth = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const token = Cookies.get("woscp_user");
  if (token) {
    axios
      .get("https://server.woscp.org/api/users/get-user", {
        params: {
          token: token,
        },
      })
      .then(() => {
        setLoggedIn(true);
      })
      .catch((error) => {
        window.location.href = "/login";
        Cookies.remove("woscp_user");
      });
  } else {
    window.location.href = "/login";
  }
  return { loggedIn };
};
