export const PageNotFound = () => {
  return (
    <div className="flex flex-col justify-center items-center px-6 min-h-[500px]">
      <img
        src="https://www.svgrepo.com/show/426192/cogs-settings.svg"
        alt="Logo"
        className="mb-8 h-40"
      />
      <h1 className="text-2xl md:text-3xl lg:text-3xl font-bold text-center text-gray-700 mb-4">
          We regret to inform you that the page you are attempting to access cannot be found.
      </h1>
      <p className="text-center text-gray-500 text-lg md:text-xl lg:text-2xl mb-8">
          This may be due to an outdated link, a mistyped URL, or the content has been moved.
      </p>
    </div>
  );
};
