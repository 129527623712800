const TermsSection = ({ title, content }) => (
  <div className="mb-4">
    <h2 className="text-xl font-semibold mb-2">{title}</h2>
    <p>{content}</p>
  </div>
);

export const TermsAndConditions = () => {
  return (
    <div className="2xl:container 2xl:mx-auto lg:py-16 lg:px-20 md:py-12 md:px-6 py-9 px-8">
      <div className="flex flex-col lg:flex-row justify-between gap-8">
        <div className="w-full  flex flex-col justify-center">
          <div className="container mx-auto my-8 p-8 bg-white shadow-md rounded-md">
            <h1 className="text-3xl font-semibold mb-6">
              Terms And Conditions
            </h1>

            <p className="pb-5">
              Welcome to the website of the World Organization of Surveillance
              and Close-Protection Professionals. By continuing to browse and
              use this website, you agree to comply with and be bound by the
              following terms and conditions of use. These terms, along with our
              privacy policy, govern the relationship between you and the World
              Organization of Surveillance and Close-Protection Professionals.
              If you do not agree with any part of these terms and conditions,
              please refrain from using our website. The term 'World
              Organization of Surveillance and Close-Protection Professionals,'
              'us,' or 'we' refers to the owner of the website, whose registered
              office address is 88 Church Road, Stockton-On-Tees, England, TS18
              1TW. Our company registration number is 15342737, England. The
              term 'you' refers to the user or viewer of our website.
            </p>

            <TermsSection
              title="General Use"
              content="The content on this website is for general information and use only. It is subject to change without notice."
            />

            <TermsSection
              title="Cookies"
              content="This website uses cookies to monitor browsing preferences. If you allow cookies, the following personal information may be stored by us or our trusted third-party payment processor, PayPal, for processing payments."
            />

            <ul className="list-disc ml-6 mb-4">
              <li>
                Billing Information: This may include your name, billing
                address, and email address, as provided during the checkout
                process.
              </li>
              <li>
                Payment Information: This may include your credit card or PayPal
                account details, which are securely processed by PayPal to
                complete the transaction.
              </li>
              <li>
                Transaction Data: Information related to your purchases, such as
                order details and payment history.
              </li>
            </ul>

            <p className="mb-4">
              Please note that while we may collect certain information to
              facilitate transactions, the handling of this data by PayPal is
              subject to their own privacy policy and terms of service. We
              recommend reviewing PayPal's privacy policy for more information
              on how they handle and protect your data.
            </p>

            <TermsSection
              title="Accuracy and Liability"
              content="We and any third parties make no warranty or guarantee regarding the accuracy, timeliness, performance, completeness, or suitability of the information and materials found on this website. We exclude liability for any inaccuracies or errors to the fullest extent permitted by law. Your use of information or materials on this website is at your own risk. It is your responsibility to ensure that any products, services, or information available through this website meet your specific requirements."
            />

            <TermsSection
              title="Intellectual Property"
              content="This website's material is owned by or licensed to us. Reproduction is prohibited except in accordance with the copyright notice, which forms part of these terms and conditions. All trademarks not owned by or licensed to the operator are acknowledged on the website."
            />

            <TermsSection
              title="Unauthorized Use"
              content="Unauthorized use of this website may result in a claim for damages and/or be a criminal offense."
            />

            <TermsSection
              title="External Links"
              content="From time to time, this website may include links to other websites for your convenience. These links do not signify our endorsement of the website(s), and we have no responsibility for the content of linked website(s)."
            />
            <TermsSection
              title="Enhanced Program in Covert Surveillance Administration - Designed for Professionals"
              content="Advanced Course in Covert Surveillance Management - Tailored for Managers, Directors, and Team Leaders Specialized Course in Covert Surveillance - Focusing on Organized Crime, Police, and Close Protection Integration at the Government Level"
            />

            <div className="mt-8 text-sm text-gray-600">
              <p>
                Your use of this website and any dispute arising out of such use
                are subject to the laws of England, Northern Ireland, Scotland,
                and Wales.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
