import { useForm } from "react-hook-form";
import Axios from "axios";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";

import { SideMenu } from "../SideMenu";
import { dataAddNewsForm } from "../../../Components/data";
import CustomForm from "../../../Components/FormReferral";
import { NewsList } from "../../NewsList";
import CustomEditForm from "../../../Components/CustomEditForm";
import { UseAdminAuth } from "../../../Components/utils/useAdminAuth";

const convertToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};
export const EditNewsPage = () => {
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const newsForm = useForm();
  const { loggedIn } = UseAdminAuth();

  const newsEditForm = useForm();
  const [editData, setEditData] = useState({
    id: "",
    title: "",
    description: "",
    image: "",
    tags: "",
  });
  useEffect(() => {
    const existingMetaTag = document.querySelector('meta[name="robots"]');

    if (!existingMetaTag) {
      const metaTag = document.createElement("meta");
      metaTag.name = "robots";
      metaTag.content = "noindex,nofollow";
      document.head.appendChild(metaTag);
    } else {
      existingMetaTag.content = "noindex,nofollow";
    }
  }, []);

  const [openEditModal, setOpenEditModal] = useState(false);

  const checkDifferentTags = (dTags, editDataTags) => {
    switch (typeof dTags) {
      case "boolean": {
        return editDataTags !== "[]";
      }
      case "string": {
        return !editDataTags.includes(dTags);
      }
      case "object": {
        return editDataTags !== JSON.stringify(dTags);
      }
      default:
        return false;
    }
  };
  const onNewsDataSubmit = (d) => {
    let base64image;
    if (d.image[0].size > 900000) {
      toast("Image is too big", { type: "error" });
    } else {
      const convertedImage = async (d) => {
        const file = d.image[0];
        const base64 = await convertToBase64(file);
        base64image = base64;
      };

      convertedImage(d).then(() => {
        Axios.post("https://server.woscp.org/api/news/add-news-articles", {
          article: {
            ...d,
            image: base64image,
            tags: typeof d.tags === "string" ? [d.tags] : d.tags || [],
          },
          token: Cookies.get("woscp_admin"),
        })
          .then(() => {
            toast("Your form has been successfully submitted!", {
              type: "success",
            });
            newsForm.reset();
            setSubmitSuccess((prevState) => !prevState);
          })
          .catch(() => {
            toast("Oops! Something went wrong.", { type: "error" });
            newsForm.reset();
          });
      });
    }
  };
  const onNewsEditSubmit = (d) => {
    if (
      d.title !== editData.title ||
      d.description !== editData.description ||
      checkDifferentTags(d.tags, editData.tags) ||
      d.image.length > 0
    ) {
      if (d.image.length > 0) {
        let base64image;
        if (d.image[0].size > 900000) {
          toast("Image is too big", { type: "error" });
        } else {
          const convertedImage = async (d) => {
            const file = d.image[0];
            const base64 = await convertToBase64(file);
            base64image = base64;
          };

          convertedImage(d).then(() => {
            Axios.put(`https://server.woscp.org/api/news/edit-news-article`, {
              article: {
                ...d,
                tags: typeof d.tags === "string" ? [d.tags] : d.tags || [],
                image: base64image,
                id: editData.id,
              },
              token: Cookies.get("woscp_admin"),
            })
              .then(() => {
                toast("Your article has been successfully updated!", {
                  type: "success",
                });
                setEditData({
                  id: "",
                  title: "",
                  description: "",
                  image: "",
                  tags: "",
                });
                setOpenEditModal(false);
                setSubmitSuccess((prevState) => !prevState);
                newsEditForm.reset();
              })
              .catch(() => {
                toast("Oops! Something went wrong.", { type: "error" });
                newsForm.reset();
              });
          });
        }
      } else {
        Axios.put(`https://server.woscp.org/api/news/edit-news-article`, {
          article: {
            ...d,
            tags: typeof d.tags === "string" ? [d.tags] : d.tags || [],
            id: editData.id,
            image: null,
          },
          token: Cookies.get("woscp_admin"),
        })
          .then(() => {
            toast("Your article has been successfully updated!", {
              type: "success",
            });
            setEditData({
              id: "",
              title: "",
              description: "",
              image: "",
              tags: "",
            });
            setOpenEditModal(false);
            setSubmitSuccess((prevState) => !prevState);
            newsEditForm.reset();
          })
          .catch(() => {
            toast("Oops! Something went wrong.", { type: "error" });
            newsForm.reset();
          });
      }
    } else {
      toast("Please update the content until submit", { type: "error" });
    }
  };
  const onNewsArticleDelete = (d) => {
    Axios.delete("https://server.woscp.org/api/news/remove-news-article", {
      params: {
        article_id: d,
        token: Cookies.get("woscp_admin"),
      },
    })
      .then(() => {
        toast("Your form has been successfully submitted!", {
          type: "success",
        });
        newsForm.reset();
        setSubmitSuccess((prevState) => !prevState);
      })
      .catch(() => {
        toast("Oops! Something went wrong.", { type: "error" });
        newsForm.reset();
      });
  };

  useEffect(() => {
    if (openEditModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [openEditModal]);

  if (!loggedIn) {
    return <></>;
  }
  return (
    <div className={`relative `}>
      <SideMenu />
      <div
        className={`w-full md:w-[calc(100%-256px)] absolute right-0 ${
          openEditModal ? "overflow-hidden" : "overflow-auto"
        }`}
      >
        <div
          className={`flex flex-col gap-12 ${
            openEditModal ? "overflow-hidden" : "overflow-auto"
          }`}
        >
          <CustomForm
            dataForm={dataAddNewsForm}
            form={newsForm}
            onSubmit={onNewsDataSubmit}
          />
          <NewsList
            key={submitSuccess}
            isEditMode={true}
            onEdit={(data) => {
              setEditData(data);
              setOpenEditModal(true);
            }}
            onDelete={onNewsArticleDelete}
          />
        </div>
        {openEditModal && (
          <div
            tabIndex="1"
            aria-hidden="false"
            className="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-full max-h-full bg-gray-500 bg-opacity-75"
          >
            <div className="relative p-4 w-full max-w-5xl max-h-full">
              <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                  <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                    Edit Article - {editData.title}
                  </h3>
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    onClick={() => {
                      setOpenEditModal(false);
                      setEditData({
                        id: "",
                        title: "",
                        description: "",
                        image: "",
                        tags: "",
                      });
                      newsEditForm.reset();
                    }}
                  >
                    <svg
                      className="w-3 h-3"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 14 14"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                      />
                    </svg>
                    <span className="sr-only">Close modal</span>
                  </button>
                </div>
                <CustomEditForm
                  dataForm={dataAddNewsForm}
                  defaultValues={editData}
                  form={newsEditForm}
                  onSubmit={onNewsEditSubmit}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
