import { NewsCard } from "../Components/NewsCard";
import React from "react";
import Axios from "axios";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";

export const NewsList = ({
  isEditMode = false,
  onEdit = () => null,
  onDelete = () => null,
}) => {
  const [newsData, setNewsData] = React.useState([
    {
      id: "",
      title: "",
      description: "",
      author: "",
      tags: [],
      image: "",
      published_date: "",
    },
  ]);

  React.useEffect(() => {
    Axios.get("https://server.woscp.org/api/news/get-all-articles", {})
      .then((response) => {
        setNewsData([
          ...response.data.articles.sort(
            (a, b) => new Date(b.published_date) - new Date(a.published_date),
          ),
        ]);
      })
      .catch(() => {
        toast("Oops! Something went wrong.", { type: "error" });
      });
  }, []);

  return (
    <div className="2xl:container 2xl:mx-auto lg:py-16 lg:px-20 md:py-12 md:px-6 py-9 px-8">
      <div className="flex flex-col lg:flex-row justify-between gap-8">
        <div className="w-full  flex flex-col justify-center">
          <h1 className="text-3xl lg:text-4xl font-bold leading-9 text-black pb-4">
            NEWS W.O.S.C.P.
          </h1>
          {!newsData[0].id ? (
            <>
              <Skeleton count={1} className="h-48 mb-6" />
              <Skeleton count={1} className="h-48 mb-6" />
              <Skeleton count={1} className="h-48 mb-6" />
              <Skeleton count={1} className="h-48 mb-6" />
            </>
          ) : (
            newsData.map((news, index) => (
              <NewsCard
                key={`${news.title}-${index}`}
                id={news.id}
                title={news.title}
                description={news.description}
                image={news.image}
                link={`news/${news.id}`}
                published_date={news.published_date}
                author={news.author}
                tags={news.tags}
                isEditMode={isEditMode}
                onEdit={onEdit}
                onDelete={onDelete}
              />
            ))
          )}
        </div>
      </div>
    </div>
  );
};
