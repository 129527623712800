import React from "react";
import Hero from "../Components/hero";
import SectionTitle from "../Components/sectionTitle";
import Benefits from "../Components/benefits";
import { benefitOne, benefitTwo } from "../Components/data";
import Testimonials from "../Components/testimonials";
import TextSectionHome from "../Components/TextSectionHome";
import { ShowCase } from "../Components/ShowCase";

const Home = () => {
  return (
    <>
      <Hero />
      <ShowCase />
      <TextSectionHome />
      <SectionTitle
        pretitle="We are here to serve"
        title=" Why should you use us"
      >
        W.O.S.C.P. , the WORLD ORGANIZATION OF SURVEILLANCE AND CLOSE-PROTECTION
        PROFESSIONALS, is a professional investigator organization created by
        professionals, for professionals. We have a significant impact on
        governments, organizations, and public opinion. completely open-source.
      </SectionTitle>
      <Benefits data={benefitOne} />
      <Benefits imgPos="right" data={benefitTwo} />
      <SectionTitle
        pretitle="Testimonials"
        title="Here's what our customers said"
      />
      <Testimonials />
    </>
  );
};

export default Home;
