import Container from "./container";
import React from "react";
import HeroImage from "../Assets/img/planet-photo.webp";
const Hero = () => {
  return (
    <Container className="flex flex-wrap py-10 lg:px-0 justify-between">
      <div className="flex items-center w-full lg:w-[47%] ">
        <div className="max-w-2xl">
          <h1 className="text-4xl font-bold leading-snug tracking-tight text-black lg:text-4xl lg:leading-tight xl:text-5xl xl:leading-tight ">
            WORLD ORGANIZATION OF SURVEILLANCE AND CLOSE-PROTECTION
            PROFESSIONALS
          </h1>
          <p className="py-5 text-xl leading-normal text-gray-500 lg:text-xl xl:text-2xl">
            As an influential and inclusive organization, W.O.S.C.P. plays a
            crucial role in shaping the landscape of surveillance,
            investigation, and close protection on a worldwide scale. Our sector
            contributes significantly to global security and safety, employing
            over 350,000 highly skilled professionals in lifelong careers.
            Notably, two-thirds of our workforce operate beyond major urban
            centers, demonstrating our commitment to a widespread impact.
          </p>
        </div>
      </div>
      <div className="hidden lg:flex items-center justify-center w-full lg:w-[47%]">
        <div className="h-full">
          <img
            src={HeroImage}
            className={"object-cover  rounded-lg h-full"}
            alt="Hero Illustration"
            loading="eager"
            placeholder="blur"
          />
        </div>
      </div>
    </Container>
  );
};

export default Hero;
