import React from "react";

const PrivacySection = ({ title, content }) => (
  <div className="mb-4">
    <h2 className="text-xl font-semibold mb-2">{title}</h2>
    <p>{content}</p>
  </div>
);

export const PrivacyPolicy = () => {
  return (
    <div className="container mx-auto my-8 p-8 bg-white shadow-md rounded-md">
      <h1 className="text-3xl font-semibold mb-6">Privacy Policy</h1>

      <p className="mb-4">
        At WORLD ORGANIZATION OF SURVEILLANCE AND CLOSE-PROTECTION
        PROFESSIONALS, your privacy is of utmost importance to us. This Privacy
        Notice outlines why privacy matters to us, what information we collect,
        how we use it, and the rights you have regarding your personal data.
        This notice applies to all our services, including our website,
        software, purchases, subscriptions, and any other interactions with our
        company. We encourage you to carefully read this policy and reach out to
        us with any questions or concerns you may have about our privacy
        practices.
      </p>

      <PrivacySection
        title="Who We Are"
        content="Data Controller: WORLD ORGANIZATION OF SURVEILLANCE AND CLOSE-PROTECTION PROFESSIONALS, 88 Church Road, Stockton-On-Tees, TS18 1TW, +40757047733, office@woscp.org"
      />

      <PrivacySection
        title="What Information Do We Collect"
        content="We collect various types of personal information, including:"
      />

      <ul className="list-disc ml-6 mb-4">
        <li>
          Contact Information: Such as names, addresses, email addresses, and
          phone numbers.
        </li>
        <li>
          Identification Information: Government-issued ID, passport, or other
          identification documents.
        </li>
        <li>
          Training and Certification Information: Data related to training
          courses, certifications, and professional qualifications.
        </li>
        <li>
          Payment Information: Credit card details, billing information, and
          payment history.
        </li>
        <li>
          Usage Information: Details about how you use our services, including
          website usage, communication history, and interactions with our
          platform.
        </li>
        <li>
          Technical Information: IP addresses, browser types, device types, and
          other technical data.
        </li>
      </ul>

      <p className="mb-4">
        We collect data through various methods, including when you register,
        make purchases, use our services, complete contact forms, or subscribe
        to newsletters. In some cases, we may collect data from third parties,
        and we will specify the categories of data and their sources. We may
        also process sensitive personal data or financial information, ensuring
        its secure handling.
      </p>
      <PrivacySection
        title="How to Contact Us"
        content="If you have questions, concerns, or wish to file a complaint about our privacy practices or your personal data, please contact us through the following means:"
      />

      <ul className="list-disc ml-6 mb-4">
        <li>
          Online:{" "}
          <a
            href="https://geruinvestigation.co.uk/contact"
            className="text-blue-500 underline"
          >
            https://woscp.org/contact
          </a>
        </li>
        <li>
          Email:{" "}
          <a href="mailto:office@woscp.org" className="text-blue-500 underline">
            office@woscp.org
          </a>
        </li>
        <li>Postal Mail: 88 Church Road, Stockton-On-Tees, TS18 1TW</li>
      </ul>

      <PrivacySection
        title="Use of Cookies and Other Technologies"
        content="We use cookies and similar technologies for various purposes, such as improving user experiences and analyzing usage data. You can control and manage these technologies"
      />

      <PrivacySection
        title="Linking to Other Websites / Third-Party Content"
        content="Our website may link to external sites and resources. We do not necessarily endorse these sites, and we do not take responsibility for their content or information. Please review the privacy policies of these external sites when visiting them."
      />
    </div>
  );
};
