import Axios from "axios";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";

import CustomForm from "../../../Components/FormReferral";
import { dataEditEngagementModalForm } from "../../../Components/data";
import { NewsPopup } from "../../../Components/NewsPopup";
import { SideMenu } from "../SideMenu";

import { UseAdminAuth } from "../../../Components/utils/useAdminAuth";

export const EditEngagementModalPage = () => {
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const { loggedIn } = UseAdminAuth();
  const [content, setContent] = useState({
    title: "",
    line2: "",
    line3: "",
    line4: "",
  });
  useEffect(() => {
    Axios.get(
      "https://server.woscp.org/api/content/engagement-modal-data",
    ).then((res) => {
      res.data.content[0].data !== "{}" &&
        setContent(JSON.parse(res.data.content[0].data));
    });
  }, []);
  console.log("content", content);
  const editEngagementModalForm = useForm();

  useEffect(() => {
    editEngagementModalForm.reset(content);
  }, [content, editEngagementModalForm]);

  useEffect(() => {
    const existingMetaTag = document.querySelector('meta[name="robots"]');

    if (!existingMetaTag) {
      const metaTag = document.createElement("meta");
      metaTag.name = "robots";
      metaTag.content = "noindex,nofollow";
      document.head.appendChild(metaTag);
    } else {
      existingMetaTag.content = "noindex,nofollow";
    }
  }, []);
  if (!loggedIn) {
    return <></>;
  }
  const onModalDataSubmit = (d) => {
    if (
      d.title !== content.title ||
      d.line2 !== content.line2 ||
      d.line3 !== content.line3 ||
      d.line4 !== content.line4
    ) {
      Axios.post("https://server.woscp.org/api/admin/edit-content", {
        type: "modal",
        content: d,
        token: Cookies.get("woscp_admin"),
      })
        .then(() => {
          toast("Your form has been successfully submitted!", {
            type: "success",
          });
          editEngagementModalForm.reset();
          setSubmitSuccess(true);
        })
        .catch(() => {
          toast("Oops! Something went wrong.", { type: "error" });
          editEngagementModalForm.reset();
        });
    } else {
      toast("Update data before submitting changes.", { type: "error" });
    }
  };
  return (
    <div>
      <SideMenu />
      <div className="w-full md:w-[calc(100%-256px)] absolute right-0 overflow-auto">
        <div className="flex flex-col gap-12">
          <CustomForm
            dataForm={dataEditEngagementModalForm}
            form={editEngagementModalForm}
            onSubmit={onModalDataSubmit}
          />
          <NewsPopup isEditMode={true} key={submitSuccess} />
        </div>
      </div>
    </div>
  );
};
