import React from "react";
import { dataContactForm } from "../Components/data";
import CustomForm from "../Components/FormReferral";
import Axios from "axios";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";

const Contact = () => {
  const contactForm = useForm();

  const onSubmit = (d) => {
    Axios.post("https://server.woscp.org/api/forms/contact-form-send", {
      mailData: d,
    })
      .then(() => {
        toast("Your form has been successfully submitted!", {
          type: "success",
        });
        contactForm.reset();
      })
      .catch(() => {
        toast("Oops! Something went wrong.", { type: "error" });
        contactForm.reset();
      });
  };

  return (
    <CustomForm
      dataForm={dataContactForm}
      form={contactForm}
      onSubmit={onSubmit}
    />
  );
};

export default Contact;
